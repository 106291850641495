<div class="modal-template default-modal">
  <form [formGroup]="taxFrom">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          <span>{{ true ? ('tax.editTax' | translate) : ('common.required' | translate) }}</span>
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="content-container">
          <div class="input-container" [ngClass]="{ 'invalid-validation': f.get('name').errors && submittedTaxForm }">
            <label>{{ 'tax.nameTax' | translate }}<span class="required-validation">*</span></label>
            <input type="text" placeholder="Назва податку" formControlName="name" />
          </div>
          <div class="input-container" [ngClass]="{ 'invalid-validation': f.get('rate').errors && submittedTaxForm }">
            <label>{{ 'table.rate' | translate }} (%)<span class="required-validation">*</span></label>
            <div class="text-select-field">
              <input class="left-item" placeholder="0" (keypress)="onlyNumber($event)" formControlName="rate" />
              <div class="right-item right-text">%</div>
            </div>
          </div>
          <div class="input-container checkbox-container">
            <input type="checkbox" id="checkbox-tax-create" formControlName="default" />
            <label class="custom-tooltip-activator" for="checkbox-tax-create">
              <div class="checkbox-item" [ngClass]="{ disabled: disableDefault }"></div>
              <span [ngClass]="{ disabled: disableDefault }"> {{ 'tax.defaultTax' | translate }}</span>
              <span class="j2-icon-info"></span>
              <div class="custom-tooltip-container custom-tooltip-left-top custom-tooltip-small">
                <div class="tooltip-wrapper">
                  <p class="tooltip-text">{{ 'text-tooltip-tax-modal' | translate }}</p>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <button class="btn-template btn-blue dropdown-button"  (click)="saveTax()">
            {{ 'button.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
