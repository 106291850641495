{
  "main": {
    "pl": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "pl"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "sty",
                  "2": "lut",
                  "3": "mar",
                  "4": "kwi",
                  "5": "maj",
                  "6": "cze",
                  "7": "lip",
                  "8": "sie",
                  "9": "wrz",
                  "10": "paź",
                  "11": "lis",
                  "12": "gru"
                },
                "narrow": {
                  "1": "s",
                  "2": "l",
                  "3": "m",
                  "4": "k",
                  "5": "m",
                  "6": "c",
                  "7": "l",
                  "8": "s",
                  "9": "w",
                  "10": "p",
                  "11": "l",
                  "12": "g"
                },
                "wide": {
                  "1": "stycznia",
                  "2": "lutego",
                  "3": "marca",
                  "4": "kwietnia",
                  "5": "maja",
                  "6": "czerwca",
                  "7": "lipca",
                  "8": "sierpnia",
                  "9": "września",
                  "10": "października",
                  "11": "listopada",
                  "12": "grudnia"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "sty",
                  "2": "lut",
                  "3": "mar",
                  "4": "kwi",
                  "5": "maj",
                  "6": "cze",
                  "7": "lip",
                  "8": "sie",
                  "9": "wrz",
                  "10": "paź",
                  "11": "lis",
                  "12": "gru"
                },
                "narrow": {
                  "1": "S",
                  "2": "L",
                  "3": "M",
                  "4": "K",
                  "5": "M",
                  "6": "C",
                  "7": "L",
                  "8": "S",
                  "9": "W",
                  "10": "P",
                  "11": "L",
                  "12": "G"
                },
                "wide": {
                  "1": "styczeń",
                  "2": "luty",
                  "3": "marzec",
                  "4": "kwiecień",
                  "5": "maj",
                  "6": "czerwiec",
                  "7": "lipiec",
                  "8": "sierpień",
                  "9": "wrzesień",
                  "10": "październik",
                  "11": "listopad",
                  "12": "grudzień"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "niedz.",
                  "mon": "pon.",
                  "tue": "wt.",
                  "wed": "śr.",
                  "thu": "czw.",
                  "fri": "pt.",
                  "sat": "sob."
                },
                "narrow": {
                  "sun": "n",
                  "mon": "p",
                  "tue": "w",
                  "wed": "ś",
                  "thu": "c",
                  "fri": "p",
                  "sat": "s"
                },
                "short": {
                  "sun": "nie",
                  "mon": "pon",
                  "tue": "wto",
                  "wed": "śro",
                  "thu": "czw",
                  "fri": "pią",
                  "sat": "sob"
                },
                "wide": {
                  "sun": "niedziela",
                  "mon": "poniedziałek",
                  "tue": "wtorek",
                  "wed": "środa",
                  "thu": "czwartek",
                  "fri": "piątek",
                  "sat": "sobota"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "niedz.",
                  "mon": "pon.",
                  "tue": "wt.",
                  "wed": "śr.",
                  "thu": "czw.",
                  "fri": "pt.",
                  "sat": "sob."
                },
                "narrow": {
                  "sun": "N",
                  "mon": "P",
                  "tue": "W",
                  "wed": "Ś",
                  "thu": "C",
                  "fri": "P",
                  "sat": "S"
                },
                "short": {
                  "sun": "nie",
                  "mon": "pon",
                  "tue": "wto",
                  "wed": "śro",
                  "thu": "czw",
                  "fri": "pią",
                  "sat": "sob"
                },
                "wide": {
                  "sun": "niedziela",
                  "mon": "poniedziałek",
                  "tue": "wtorek",
                  "wed": "środa",
                  "thu": "czwartek",
                  "fri": "piątek",
                  "sat": "sobota"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "I kw.",
                  "2": "II kw.",
                  "3": "III kw.",
                  "4": "IV kw."
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "I kwartał",
                  "2": "II kwartał",
                  "3": "III kwartał",
                  "4": "IV kwartał"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "I kw.",
                  "2": "II kw.",
                  "3": "III kw.",
                  "4": "IV kw."
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "I kwartał",
                  "2": "II kwartał",
                  "3": "III kwartał",
                  "4": "IV kwartał"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "o północy",
                  "am": "AM",
                  "noon": "w południe",
                  "pm": "PM",
                  "morning1": "rano",
                  "morning2": "przed południem",
                  "afternoon1": "po południu",
                  "evening1": "wieczorem",
                  "night1": "w nocy"
                },
                "narrow": {
                  "midnight": "o półn.",
                  "am": "a",
                  "noon": "w poł.",
                  "pm": "p",
                  "morning1": "rano",
                  "morning2": "przed poł.",
                  "afternoon1": "po poł.",
                  "evening1": "wiecz.",
                  "night1": "w nocy"
                },
                "wide": {
                  "midnight": "o północy",
                  "am": "AM",
                  "noon": "w południe",
                  "pm": "PM",
                  "morning1": "rano",
                  "morning2": "przed południem",
                  "afternoon1": "po południu",
                  "evening1": "wieczorem",
                  "night1": "w nocy"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "północ",
                  "am": "AM",
                  "noon": "południe",
                  "pm": "PM",
                  "morning1": "rano",
                  "morning2": "przedpołudnie",
                  "afternoon1": "popołudnie",
                  "evening1": "wieczór",
                  "night1": "noc"
                },
                "narrow": {
                  "midnight": "półn.",
                  "am": "a",
                  "noon": "poł.",
                  "pm": "p",
                  "morning1": "rano",
                  "morning2": "przedpoł.",
                  "afternoon1": "popoł.",
                  "evening1": "wiecz.",
                  "night1": "noc"
                },
                "wide": {
                  "midnight": "północ",
                  "am": "AM",
                  "noon": "południe",
                  "pm": "PM",
                  "morning1": "rano",
                  "morning2": "przedpołudnie",
                  "afternoon1": "popołudnie",
                  "evening1": "wieczór",
                  "night1": "noc"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "przed naszą erą",
                "0-alt-variant": "p.n.e.",
                "1": "naszej ery",
                "1-alt-variant": "n.e."
              },
              "eraAbbr": {
                "0": "p.n.e.",
                "0-alt-variant": "BCE",
                "1": "n.e.",
                "1-alt-variant": "CE"
              },
              "eraNarrow": {
                "0": "p.n.e.",
                "0-alt-variant": "BCE",
                "1": "n.e.",
                "1-alt-variant": "CE"
              }
            },
            "dateFormats": {
              "full": "EEEE, d MMMM y",
              "long": "d MMMM y",
              "medium": "d MMM y",
              "short": "dd.MM.y"
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} {0}",
              "long": "{1} {0}",
              "medium": "{1}, {0}",
              "short": "{1}, {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "h:mm B",
                "Bhms": "h:mm:ss B",
                "d": "d",
                "E": "ccc",
                "EBhm": "E h:mm B",
                "EBhms": "E h:mm:ss B",
                "Ed": "E, d",
                "Ehm": "E, h:mm a",
                "EHm": "E, HH:mm",
                "Ehms": "E, h:mm:ss a",
                "EHms": "E, HH:mm:ss",
                "Gy": "y G",
                "GyMMM": "MMM y G",
                "GyMMMd": "d MMM y G",
                "GyMMMEd": "E, d MMM y G",
                "GyMMMM": "LLLL y G",
                "GyMMMMd": "d MMMM y G",
                "GyMMMMEd": "E, d MMMM y G",
                "h": "h a",
                "H": "HH",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "HH:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "HH:mm v",
                "M": "L",
                "Md": "d.MM",
                "MEd": "E, d.MM",
                "MMM": "LLL",
                "MMMd": "d MMM",
                "MMMEd": "E, d MMM",
                "MMMMd": "d MMMM",
                "MMMMEd": "E, d MMMM",
                "MMMMW-count-one": "LLLL, 'tydz'. W",
                "MMMMW-count-few": "LLLL, 'tydz'. W",
                "MMMMW-count-many": "LLLL, 'tydz'. W",
                "MMMMW-count-other": "LLLL, 'tydz'. W",
                "ms": "mm:ss",
                "y": "y",
                "yM": "MM.y",
                "yMd": "d.MM.y",
                "yMEd": "E, d.MM.y",
                "yMMM": "LLL y",
                "yMMMd": "d MMM y",
                "yMMMEd": "E, d MMM y",
                "yMMMM": "LLLL y",
                "yMMMMd": "d MMMM y",
                "yMMMMEd": "E, d MMMM y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y",
                "yw-count-one": "Y, 'tydz'. w",
                "yw-count-few": "Y, 'tydz'. w",
                "yw-count-many": "Y, 'tydz'. w",
                "yw-count-other": "Y, 'tydz'. w"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0}–{1}",
                "Bh": {
                  "B": "h B – h B",
                  "h": "h–h B"
                },
                "Bhm": {
                  "B": "h:mm B – h:mm B",
                  "h": "h:mm–h:mm B",
                  "m": "h:mm–h:mm B"
                },
                "d": {
                  "d": "d–d"
                },
                "Gy": {
                  "G": "y G – y G",
                  "y": "y–y G"
                },
                "GyM": {
                  "G": "M.y GGGGG – M.y GGGGG",
                  "M": "M.y – M.y GGGGG",
                  "y": "M.y – M.y GGGGG"
                },
                "GyMd": {
                  "d": "d.M.y – d.M.y GGGGG",
                  "G": "d.M.y GGGGG – d.M.y GGGGG",
                  "M": "d.M.y – d.M.y GGGGG",
                  "y": "d.M.y – d.M.y GGGGG"
                },
                "GyMEd": {
                  "d": "E, d.M.y – E, d.M.y GGGGG",
                  "G": "E, d.M.y GGGGG – E, d.M.y GGGGG",
                  "M": "E, d.M.y – E, d.M.y GGGGG",
                  "y": "E, d.M.y – E, d.M.y GGGGG"
                },
                "GyMMM": {
                  "G": "MMM y G – MMM y G",
                  "M": "MMM – MMM y G",
                  "y": "MMM y – MMM y G"
                },
                "GyMMMd": {
                  "d": "d–d MMM y G",
                  "G": "d MMM y G – d MMM y G",
                  "M": "d MMM – d MMM y G",
                  "y": "d MMM y – d MMM y G"
                },
                "GyMMMEd": {
                  "d": "E, d MMM – E, d MMM y G",
                  "G": "E, d MMM y G – E, d MMM y G",
                  "M": "E, d MMM – E, d MMM y G",
                  "y": "E, d MMM y – E, d MMM y G"
                },
                "h": {
                  "a": "h a–h a",
                  "h": "h–h a"
                },
                "H": {
                  "H": "HH–HH"
                },
                "hm": {
                  "a": "h:mm a–h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm",
                  "m": "HH:mm–HH:mm"
                },
                "hmv": {
                  "a": "h:mm a–h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm–HH:mm v",
                  "m": "HH:mm–HH:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "HH–HH v"
                },
                "M": {
                  "M": "MM–MM"
                },
                "Md": {
                  "d": "dd.MM–dd.MM",
                  "M": "dd.MM–dd.MM"
                },
                "MEd": {
                  "d": "E, dd.MM–E, dd.MM",
                  "M": "E, dd.MM–E, dd.MM"
                },
                "MMM": {
                  "M": "LLL–LLL"
                },
                "MMMd": {
                  "d": "d–d MMM",
                  "M": "d MMM–d MMM"
                },
                "MMMEd": {
                  "d": "E, d MMM–E, d MMM",
                  "M": "E, d MMM–E, d MMM"
                },
                "MMMMd": {
                  "d": "d–d MMMM",
                  "M": "d MMMM – d MMMM"
                },
                "MMMMEd": {
                  "d": "E, d MMMM – E, d MMMM",
                  "M": "E, d MMMM – E, d MMMM"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "MM.y–MM.y",
                  "y": "MM.y–MM.y"
                },
                "yMd": {
                  "d": "dd–dd.MM.y",
                  "M": "dd.MM–dd.MM.y",
                  "y": "dd.MM.y–dd.MM.y"
                },
                "yMEd": {
                  "d": "E, dd.MM.y–E, dd.MM.y",
                  "M": "E, dd.MM.y–E, dd.MM.y",
                  "y": "E, dd.MM.y–E, dd.MM.y"
                },
                "yMMM": {
                  "M": "LLL–LLL y",
                  "y": "LLL y–LLL y"
                },
                "yMMMd": {
                  "d": "d–d MMM y",
                  "M": "d MMM–d MMM y",
                  "y": "d MMM y–d MMM y"
                },
                "yMMMEd": {
                  "d": "E, d–E, d MMM y",
                  "M": "E, d MMM y–E, d MMM y",
                  "y": "E, d MMM y–E, d MMM y"
                },
                "yMMMM": {
                  "M": "LLLL–LLLL y",
                  "y": "LLLL y–LLLL y"
                },
                "yMMMMd": {
                  "d": "d–d MMMM y",
                  "M": "d MMMM – d MMMM y",
                  "y": "d MMMM y – d MMMM y"
                },
                "yMMMMEd": {
                  "d": "E, d – E, d MMMM y",
                  "M": "E, d MMMM – E, d MMMM y",
                  "y": "E, d MMMM y – E, d MMMM y"
                }
              }
            }
          }
        }
      }
    }
  }
}
