<div class="modal-template large-modal">
  <form [formGroup]="dataForm">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          {{ 'matter.card.matterEstimates' | translate }}
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">
        <perfect-scrollbar>
          <div class="content-container">
            <div class="table-field-container">
              <table>
                <colgroup>
                  <col class="left-col" />
                  <col class="right-col" />
                </colgroup>
                <tr>
                  <td class="left-container">{{ 'matter' | translate }}</td>
                  <td class="right-container">
                    <p>{{ matter.name }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="left-container">{{ 'common.client' | translate }}</td>
                  <td class="right-container">
                    <a>{{ matter?.contact?.fullName }} </a>
                  </td>
                </tr>
                <tr *ngIf="matter.contract">
                  <td class="left-container">{{ 'contract' | translate }}</td>
                  <td class="right-container">
                    <a routerLink="/create-contract/card/{{ matter?.contract?.id }}">{{
                      'contract.titleShort'
                        | translate: { contractNumber: matter?.contract?.number, contractFrom: matter?.contract?.date | date: dateFormat }
                      }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td class="left-container">{{ 'matter.card.matterEstimatesPay' | translate }}</td>
                  <td class="right-container">
                    <p>{{ matter.typeFee | translate }}</p>
                  </td>
                </tr>
                <tr>
                  <td class="left-container">{{ 'table.description' | translate }}</td>
                  <td class="right-container">
                    <p>
                      {{ matter.description }}
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            <hr />
            <div class="modal-checkbox-container">
              <div class="remind-container toggle-logic">
                <input type="checkbox" id="services-checkbox" (change)="servicesSwitch($event)" formControlName="withoutTimeEntry" />
                <label for="services-checkbox"></label>
                <span>{{ 'matter.card.matterEstimatesPlanServices' | translate }}</span>
              </div>
              <div class="remind-container toggle-logic">
                <input type="checkbox" id="expenses-checkbox" (change)="expensesSwitch($event)" formControlName="withoutExpense" />
                <label for="expenses-checkbox"></label>
                <span>{{ 'matter.card.matterEstimatesPlanExpenses' | translate }}</span>
              </div>
            </div>
            <div class="table-container" *ngIf="servicesValue" [@disappearanceAnimation]>
              <div class="table-header">
                <h3>{{ 'matter.card.matterEstimatesPlanServicesTitle' | translate }}</h3>
              </div>
              <table
                class="table-section-template table-services"
                *ngIf="matterEstimateTimeEntries.controls.length"
                formArrayName="matterEstimateTimeEntries"
              >
                <colgroup>
                  <col class="table-col-description" />
                  <col class="table-col-duration" />
                  <col class="table-col-rate" />
                  <col class="table-col-tasks" />
                  <col class="table-col-delete" />
                </colgroup>
                <tr class="table-title">
                  <th class="table-title-description">{{ 'table.description' | translate }}</th>
                  <th class="table-title-duration">{{ 'matter.card.matterEstimatesDuration' | translate }}</th>
                  <th class="table-title-rate">{{ 'matter.card.matterEstimatesRate' | translate }}</th>
                  <th class="table-title-tasks"></th>
                  <th class="table-title-delete"></th>
                </tr>
                <tr class="table-row" *ngFor="let item of matterEstimateTimeEntries.controls; let i = index" [formGroupName]="i">
                  <td class="table-row-description">
                    <div class="input-container" *ngIf="editableWorkIndex === i">
                      <input type="text" placeholder="{{ 'common.typeHere' | translate }}" formControlName="description" />
                    </div>
                    <div *ngIf="editableWorkIndex !== i">
                      <span>{{ getArrayDataWork()[i].description }}</span>
                    </div>
                  </td>
                  <td class="table-row-duration">
                    <div
                      class="input-container"
                      *ngIf="editableWorkIndex === i"
                      [ngClass]="{ 'invalid-validation': item.get(['duration']).errors && submittedForm }"
                    >
                      <input id="{{ i }}" formControlName="duration" (keypress)="fixDuration($event)" placeholder="00:00" />
                    </div>
                    <div *ngIf="editableWorkIndex !== i">
                      <span>{{ getArrayDataWork()[i].duration }}</span>
                    </div>
                  </td>
                  <td class="table-row-rate">
                    <div
                      class="input-container no-margin-combobox"
                      *ngIf="editableWorkIndex === i"
                      [ngClass]="{ 'invalid-validation': item.get(['rate']).errors && submittedForm }"
                    >
                      <ejs-numerictextbox
                        formControlName="rate"
                        min="0"
                        [showSpinButton]="false"
                        [showClearButton]="false"
                        placeholder="0.00"
                      ></ejs-numerictextbox>
                    </div>
                    <div *ngIf="editableWorkIndex !== i">
                      <span>{{ getArrayDataWork()[i].rate }}</span>
                    </div>
                  </td>
                  <td class="table-row-tasks">
                    <div class="modal-tasks-container" *ngIf="!getArrayDataWork()[i].task">
                      <div class="tasks-modal-item" (click)="createNewTask(i)">
                        <span class="j-icon-task"></span>
                        {{ 'matter.card.matterEstimatesAddTask' | translate }}
                      </div>
                      <div class="tasks-modal-item" (click)="createNewEvent(i)">
                        <span class="j-icon-event"></span>
                        {{ 'matter.card.matterEstimatesAddEvent' | translate }}
                      </div>
                    </div>
                    <div class="item-task" *ngIf="getArrayDataWork()[i].task">
                      <div class="status-task-block">
                        <span class="status-task" [ngStyle]="{ 'border-color': getColorTask(getArrayDataWork()[i].task) }"></span>
                      </div>
                      <div class="name-task-block">
                        <span class="name-task" (click)="goToTask(getArrayDataWork()[i].task)">{{
                          getArrayDataWork()[i].task ? getArrayDataWork()[i].task.name : null
                        }}</span>
                      </div>
                      <div class="icon-edit-task">
                        <span class="j-icon-edit" (click)="editTask(i)"></span>
                      </div>
                    </div>
                  </td>
                  <td class="table-row-delete">
                    <div class="button-container">
                      <div class="table-button" (click)="removeField(i, 'work')">
                        <span class="j2-icon-remove"></span>
                      </div>
                      <div class="table-button" (click)="editWorkField(i)">
                        <span class="j-icon-edit"></span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <button type="button" class="add-more-field" (click)="addWorkField()">
                <i class="j2-icon-small-bold-plus"></i>
                <p>{{ 'button.create' | translate }}</p>
              </button>
              <div class="table-total-container">
                {{ 'matter.card.matterEstimatesTotal' | translate }}: {{ totalWorkCalculation() | number: '1.2-2' }}
              </div>
            </div>

            <div class="table-container" *ngIf="expensesValue" [@disappearanceAnimation]>
              <div class="table-header">
                <h3>{{ 'matter.card.matterEstimatesPlanExpensesTitle' | translate }}</h3>
              </div>
              <table
                class="table-section-template table-expenses"
                *ngIf="matterEstimateExpenses.controls.length"
                formArrayName="matterEstimateExpenses"
              >
                <colgroup>
                  <col class="table-col-type" />
                  <col class="table-col-description" />
                  <col class="table-col-amount" />
                  <col class="table-col-blank" />
                  <col class="table-col-delete" />
                </colgroup>
                <tr class="table-title">
                  <th class="table-title-type">{{ 'table.category' | translate }}</th>
                  <th class="table-title-description">{{ 'table.description' | translate }}</th>
                  <th class="table-title-amount">{{ 'matter.card.matterEstimatesAmount' | translate }}</th>
                  <th class="table-title-blank"></th>
                  <th class="table-title-delete"></th>
                </tr>
                <tr class="table-row" *ngFor="let item of matterEstimateExpenses.controls; let i = index" [formGroupName]="i">
                  <td class="table-row-type">
                    <div class="input-container no-margin-combobox" *ngIf="editableExpenseIndex === i">
                      <ejs-combobox
                        [allowFiltering]="true"
                        (filtering)="filterExpenseTypes($event)"
                        [allowCustom]="allowCurrent"
                        [dataSource]="expenseTypes"
                        formControlName="expenseType"
                        [fields]="{ text: 'name', value: 'id' }"
                        [placeholder]="'common.select' | translate"
                        [noRecordsTemplate]="'noRecord' | translate"
                      >
                      </ejs-combobox>
                    </div>
                    <div *ngIf="editableExpenseIndex !== i" class="expense-value">
                      <span>{{ getExpenseTypeName(i) }}</span>
                    </div>
                  </td>
                  <td class="table-row-description">
                    <div class="input-container" *ngIf="editableExpenseIndex === i">
                      <input type="text" placeholder="{{ 'common.typeHere' | translate }}" formControlName="description" />
                    </div>
                    <div *ngIf="editableExpenseIndex !== i">
                      <span>{{ getArrayDataExpense()[i].description }}</span>
                    </div>
                  </td>
                  <td class="table-row-amount">
                    <div
                      class="input-container no-margin-combobox"
                      *ngIf="editableExpenseIndex === i"
                      [ngClass]="{ 'invalid-validation': item.get(['amount']).errors && submittedForm }"
                    >
                      <ejs-numerictextbox
                        formControlName="amount"
                        min="0"
                        [showSpinButton]="false"
                        [showClearButton]="false"
                        placeholder="0.00"
                      ></ejs-numerictextbox>
                    </div>
                    <div *ngIf="editableExpenseIndex !== i">
                      <span>{{ getArrayDataExpense()[i].amount }}</span>
                    </div>
                  </td>
                  <td class="table-row-blank"></td>
                  <td class="table-row-delete">
                    <div class="button-container">
                      <div class="table-button">
                        <span class="j2-icon-remove" (click)="removeField(i, 'expense')"></span>
                      </div>
                      <div class="table-button" (click)="editExpenseField(i)">
                        <span class="j-icon-edit"></span>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>

              <button type="button" class="add-more-field" (click)="addExpenseField()">
                <i class="j2-icon-small-bold-plus"></i>
                <p>{{ 'button.create' | translate }}</p>
              </button>
              <div class="table-total-container">
                {{ 'matter.card.matterEstimatesTotal' | translate }}: {{ totalExpenseCalculation() | number: '1.2-2' }}
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <button class="btn-template red-border"  (click)="deleteAll()">{{ 'button.delete' | translate }}</button>
          <div class="right-container">
            <button class="btn-template"  (click)="onSubmit(true)">
              {{ 'matter.card.matterEstimatesTemplate' | translate }}
            </button>
            <button class="btn-template btn-blue dropdown-button"  (click)="onSubmit()">
              {{ 'button.save' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- <a id="fileDownload" [href]="fileUrl" [download]="fileName">DownloadFile</a> -->
<a id="fileDownload" [href]="fileUrl" [download]="fileName">DownloadFile</a>
<app-loader *ngIf="showLoader"></app-loader>
