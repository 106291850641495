{
  "main": {
    "cs": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "cs"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tisíc",
              "1000-count-few": "0 tisíce",
              "1000-count-many": "0 tisíce",
              "1000-count-other": "0 tisíc",
              "10000-count-one": "00 tisíc",
              "10000-count-few": "00 tisíc",
              "10000-count-many": "00 tisíce",
              "10000-count-other": "00 tisíc",
              "100000-count-one": "000 tisíc",
              "100000-count-few": "000 tisíc",
              "100000-count-many": "000 tisíce",
              "100000-count-other": "000 tisíc",
              "1000000-count-one": "0 milion",
              "1000000-count-few": "0 miliony",
              "1000000-count-many": "0 milionu",
              "1000000-count-other": "0 milionů",
              "10000000-count-one": "00 milionů",
              "10000000-count-few": "00 milionů",
              "10000000-count-many": "00 milionu",
              "10000000-count-other": "00 milionů",
              "100000000-count-one": "000 milionů",
              "100000000-count-few": "000 milionů",
              "100000000-count-many": "000 milionu",
              "100000000-count-other": "000 milionů",
              "1000000000-count-one": "0 miliarda",
              "1000000000-count-few": "0 miliardy",
              "1000000000-count-many": "0 miliardy",
              "1000000000-count-other": "0 miliard",
              "10000000000-count-one": "00 miliard",
              "10000000000-count-few": "00 miliard",
              "10000000000-count-many": "00 miliardy",
              "10000000000-count-other": "00 miliard",
              "100000000000-count-one": "000 miliard",
              "100000000000-count-few": "000 miliard",
              "100000000000-count-many": "000 miliardy",
              "100000000000-count-other": "000 miliard",
              "1000000000000-count-one": "0 bilion",
              "1000000000000-count-few": "0 biliony",
              "1000000000000-count-many": "0 bilionu",
              "1000000000000-count-other": "0 bilionů",
              "10000000000000-count-one": "00 bilionů",
              "10000000000000-count-few": "00 bilionů",
              "10000000000000-count-many": "00 bilionu",
              "10000000000000-count-other": "00 bilionů",
              "100000000000000-count-one": "000 bilionů",
              "100000000000000-count-few": "000 bilionů",
              "100000000000000-count-many": "000 bilionu",
              "100000000000000-count-other": "000 bilionů"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tis'.'",
              "1000-count-few": "0 tis'.'",
              "1000-count-many": "0 tis'.'",
              "1000-count-other": "0 tis'.'",
              "10000-count-one": "00 tis'.'",
              "10000-count-few": "00 tis'.'",
              "10000-count-many": "00 tis'.'",
              "10000-count-other": "00 tis'.'",
              "100000-count-one": "000 tis'.'",
              "100000-count-few": "000 tis'.'",
              "100000-count-many": "000 tis'.'",
              "100000-count-other": "000 tis'.'",
              "1000000-count-one": "0 mil'.'",
              "1000000-count-few": "0 mil'.'",
              "1000000-count-many": "0 mil'.'",
              "1000000-count-other": "0 mil'.'",
              "10000000-count-one": "00 mil'.'",
              "10000000-count-few": "00 mil'.'",
              "10000000-count-many": "00 mil'.'",
              "10000000-count-other": "00 mil'.'",
              "100000000-count-one": "000 mil'.'",
              "100000000-count-few": "000 mil'.'",
              "100000000-count-many": "000 mil'.'",
              "100000000-count-other": "000 mil'.'",
              "1000000000-count-one": "0 mld'.'",
              "1000000000-count-few": "0 mld'.'",
              "1000000000-count-many": "0 mld'.'",
              "1000000000-count-other": "0 mld'.'",
              "10000000000-count-one": "00 mld'.'",
              "10000000000-count-few": "00 mld'.'",
              "10000000000-count-many": "00 mld'.'",
              "10000000000-count-other": "00 mld'.'",
              "100000000000-count-one": "000 mld'.'",
              "100000000000-count-few": "000 mld'.'",
              "100000000000-count-many": "000 mld'.'",
              "100000000000-count-other": "000 mld'.'",
              "1000000000000-count-one": "0 bil'.'",
              "1000000000000-count-few": "0 bil'.'",
              "1000000000000-count-many": "0 bil'.'",
              "1000000000000-count-other": "0 bil'.'",
              "10000000000000-count-one": "00 bil'.'",
              "10000000000000-count-few": "00 bil'.'",
              "10000000000000-count-many": "00 bil'.'",
              "10000000000000-count-other": "00 bil'.'",
              "100000000000000-count-one": "000 bil'.'",
              "100000000000000-count-few": "000 bil'.'",
              "100000000000000-count-many": "000 bil'.'",
              "100000000000000-count-other": "000 bil'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 tis'.' ¤",
              "1000-count-few": "0 tis'.' ¤",
              "1000-count-many": "0 tis'.' ¤",
              "1000-count-other": "0 tis'.' ¤",
              "10000-count-one": "00 tis'.' ¤",
              "10000-count-few": "00 tis'.' ¤",
              "10000-count-many": "00 tis'.' ¤",
              "10000-count-other": "00 tis'.' ¤",
              "100000-count-one": "000 tis'.' ¤",
              "100000-count-few": "000 tis'.' ¤",
              "100000-count-many": "000 tis'.' ¤",
              "100000-count-other": "000 tis'.' ¤",
              "1000000-count-one": "0 mil'.' ¤",
              "1000000-count-few": "0 mil'.' ¤",
              "1000000-count-many": "0 mil'.' ¤",
              "1000000-count-other": "0 mil'.' ¤",
              "10000000-count-one": "00 mil'.' ¤",
              "10000000-count-few": "00 mil'.' ¤",
              "10000000-count-many": "00 mil'.' ¤",
              "10000000-count-other": "00 mil'.' ¤",
              "100000000-count-one": "000 mil'.' ¤",
              "100000000-count-few": "000 mil'.' ¤",
              "100000000-count-many": "000 mil'.' ¤",
              "100000000-count-other": "000 mil'.' ¤",
              "1000000000-count-one": "0 mld'.' ¤",
              "1000000000-count-few": "0 mld'.' ¤",
              "1000000000-count-many": "0 mld'.' ¤",
              "1000000000-count-other": "0 mld'.' ¤",
              "10000000000-count-one": "00 mld'.' ¤",
              "10000000000-count-few": "00 mld'.' ¤",
              "10000000000-count-many": "00 mld'.' ¤",
              "10000000000-count-other": "00 mld'.' ¤",
              "100000000000-count-one": "000 mld'.' ¤",
              "100000000000-count-few": "000 mld'.' ¤",
              "100000000000-count-many": "000 mld'.' ¤",
              "100000000000-count-other": "000 mld'.' ¤",
              "1000000000000-count-one": "0 bil'.' ¤",
              "1000000000000-count-few": "0 bil'.' ¤",
              "1000000000000-count-many": "0 bil'.' ¤",
              "1000000000000-count-other": "0 bil'.' ¤",
              "10000000000000-count-one": "00 bil'.' ¤",
              "10000000000000-count-few": "00 bil'.' ¤",
              "10000000000000-count-many": "00 bil'.' ¤",
              "10000000000000-count-other": "00 bil'.' ¤",
              "100000000000000-count-one": "000 bil'.' ¤",
              "100000000000000-count-few": "000 bil'.' ¤",
              "100000000000000-count-many": "000 bil'.' ¤",
              "100000000000000-count-other": "000 bil'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} den",
          "pluralMinimalPairs-count-few": "{0} dny",
          "pluralMinimalPairs-count-many": "{0} dne",
          "pluralMinimalPairs-count-other": "{0} dní",
          "other": "Na {0}. křižovatce odbočte vpravo."
        }
      }
    }
  }
}
