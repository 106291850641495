/* src/app/commonComponents/modals/default-modals/team-invite-modal/team-invite-modal.component.scss */
.modal-template .link-container-text {
  font-size: 13px;
  color: #637283;
  margin-bottom: 12px;
}
.modal-template .link-container-text a {
  color: #1252af;
  font-weight: 600;
  text-decoration: underline;
}
.modal-template .input-container.checkbox-container {
  display: flex;
}
.modal-template .input-container.checkbox-container.checkbox-bold label {
  font-weight: 400;
}
.modal-template .input-container.checkbox-container .j2-icon-info {
  margin-left: 3px;
}
.modal-template textarea {
  height: 85px;
}
.modal-template .modal-footer {
  background-color: #ffffff;
}
.modal-template .modal-footer {
  overflow: auto;
}
/*# sourceMappingURL=team-invite-modal.component.css.map */
