/* src/app/commonComponents/modals/system-modals/not-available-modal/not-available-modal.component.scss */
.modal-template .modal-container {
  width: 540px;
  height: 100%;
}
.modal-template .modal-header-not-available {
  position: relative;
  height: 311px;
  background-image: url(/assets/images/modal-not-available.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.modal-template .modal-body-not-available {
  padding: 45px 38px 17px;
}
.modal-template .modal-body-not-available h2 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 13px;
}
.modal-template .modal-body-not-available p {
  color: #637283;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 42px;
  max-width: 410px;
}
.modal-template .modal-body-not-available p a {
  color: #1252af;
  text-decoration: underline;
}
.modal-template .modal-body-not-available p.learn-text {
  margin: 0 auto;
}
.modal-template .modal-body-not-available button.btn-template.dropdown-button.btn-blue {
  min-width: 260px;
  margin: 0 auto 13px;
}
/*# sourceMappingURL=not-available-modal.component.css.map */
