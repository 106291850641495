<ng-container [formGroup]="form">
  <div class="input-wrapper">
    <div class="input-container" [ngClass]="{ 'invalid-validation': form.get(['startDate']).errors && submittedForm }">
      <label>{{ 'taskAndEvents.modal.startDate' | translate }}<span class="required-validation">*</span></label>
      <div class="datepicker-container">
        <ejs-datetimepicker
          id="startDate"
          [format]="dateTimeFormat"
          [locale]="locale"
          #startDate
          (change)="updateDateEnd.emit($event)"
          formControlName="startDate"
        ></ejs-datetimepicker>
      </div>
    </div>
    <div class="input-container" [ngClass]="{ 'invalid-validation': form.get(['endDate']).errors && submittedForm }">
      <label>{{ 'taskAndEvents.modal.endDate' | translate }}<span class="required-validation">*</span></label>
      <div class="datepicker-container">
        <ejs-datetimepicker
          id="endDate"
          #endDate
          [locale]="locale"
          [format]="dateTimeFormat"
          formControlName="endDate"
          [min]="minEndDate"
        ></ejs-datetimepicker>
      </div>
    </div>
  </div>
</ng-container>
