/* src/app/commonComponents/modals/other-modals/tracker-error-pay-modal/tracker-error-pay-modal.component.scss */
.modal-template .modal-container {
  width: 540px;
  height: 311px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px;
}
.modal-template .modal-container header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.modal-template .modal-container header .title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
}
.modal-template .modal-container header .title-container .cross {
  width: 18px;
  height: 18px;
  border: 1px solid #e03b4b;
  border-radius: 50%;
  text-align: center;
  margin: 0px 5px;
}
.modal-template .modal-container header .title-container .cross span {
  display: inline-block;
  font-size: 6px;
  margin-top: 5px;
  color: #e03b4b;
}
.modal-template .modal-container header .title-container .title {
  color: #e03b4b;
  font-size: 18px;
  font-weight: 600;
}
.modal-template .modal-container header .text {
  color: #222222;
  margin-top: 12px;
  text-align: center;
}
.modal-template .modal-container main {
  display: flex;
  flex-direction: row;
  border-radius: 3px;
  border: 1px solid #ededed;
  background-color: #ffffff;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.modal-template .modal-container main .img-container {
  padding: 0px 12px;
}
.modal-template .modal-container main .img-container img {
  width: 51px;
  height: 50px;
}
.modal-template .modal-container main .img-container .trouble-circle {
  position: relative;
  top: -67px;
  left: -12px;
}
.modal-template .modal-container main .img-container .trouble-circle .symbol-circle {
  position: absolute;
  color: #ffffff;
  top: 9px;
  left: 15px;
  z-index: 1;
  font-size: 12px;
}
.modal-template .modal-container main .img-container .trouble-circle .big-circle {
  position: absolute;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  transform: scale(0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-image:
    linear-gradient(
      218deg,
      #e03b4b 0%,
      #e02d3e 100%);
  opacity: 0.5;
  animation: puls 4s infinite;
}
@keyframes puls {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.modal-template .modal-container main .img-container .trouble-circle .small-circle {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 8px;
  left: 8px;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-image:
    linear-gradient(
      218deg,
      #e03b4b 0%,
      #e02d3e 100%);
}
.modal-template .modal-container main .name {
  color: #222222;
  font-size: 15px;
  font-weight: 600;
}
.modal-template .modal-container footer {
  margin-top: 20px;
  width: 100%;
}
.modal-template .modal-container footer .button-retry {
  width: 100%;
  margin: 0;
}
/*# sourceMappingURL=tracker-error-pay-modal.component.css.map */
