<div class="right-block_days" *ngIf="shouldNotify">
  <p *ngIf="daysLeft.days > 0 && !expired; else alreadyExpired">
    {{ 'subscriptionLeft.days' | translate: daysLeft }}
    <a (click)="buySubscription()">{{ 'subscriptionLeft.buy' | translate }}</a>
  </p>
  <ng-template #alreadyExpired>
    <p>
      {{ 'subscriptionLeft.alreadyExpired' | translate }}
      <a (click)="buySubscription()">{{ 'subscriptionLeft.buy' | translate }}</a>
    </p>
  </ng-template>
</div>
