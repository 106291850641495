/* src/app/commonComponents/modals/large-modals/matter-plan-of-action-modal/matter-plan-of-action-modal.component.scss */
.modal-template .modal-body .table-field-container {
  max-width: 600px;
}
.modal-template .modal-body .table-field-container table {
  border-collapse: separate;
  border-spacing: 0 7px;
  table-layout: fixed;
  width: 100%;
}
.modal-template .modal-body .table-field-container .left-col {
  width: 135px;
}
.modal-template .modal-body .table-field-container .left-container {
  color: #7b8994;
  font-size: 14px;
  vertical-align: initial;
}
.modal-template .modal-body .table-field-container .right-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.modal-template .modal-body .table-field-container .right-container a {
  color: #1252af;
  font-size: 14px;
  width: 100%;
}
.modal-template .modal-body .table-field-container .right-container > p {
  color: #222222;
  font-size: 14px;
  margin: 0;
  width: 100%;
}
.modal-template .modal-body hr {
  margin: 10px 0 16px;
}
.modal-template .modal-body .modal-checkbox-container {
  display: flex;
}
.modal-template .modal-body .modal-checkbox-container .remind-container {
  margin-right: 20px;
}
.modal-template .modal-body .modal-checkbox-container .remind-container label {
  margin: 0 5px 0 0;
}
.modal-template .modal-body .modal-checkbox-container .remind-container span {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
}
.modal-template .modal-body .table-header {
  margin: 21px 0 18px;
}
.modal-template .modal-body .table-header h3 {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.modal-template .modal-body .add-more-field {
  margin-top: 10px;
}
.modal-template .modal-body .table-total-container {
  color: #222222;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
}
.modal-template .modal-body .table-section-template .table-col-number {
  width: 64px;
}
.modal-template .modal-body .table-section-template .table-col-delete {
  width: 40px;
}
.modal-template .modal-body .table-section-template .table-row td {
  padding-left: 5px;
}
.modal-template .modal-body .table-section-template .table-row td:last-child {
  padding-right: 10px;
}
.modal-template .modal-body .table-section-template .table-row td .expense-value {
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-template .modal-body .table-section-template .table-row .input-container {
  padding-right: 10px;
}
.modal-template .modal-body .table-section-template .table-row .input-container > input {
  margin-bottom: 0;
}
.modal-template .modal-body .table-section-template .table-row-delete .button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.modal-template .modal-body .table-section-template .table-row-delete .button-container .table-button {
  margin-left: 9px;
  cursor: pointer;
}
.modal-template .modal-body .table-section-template .table-row-delete .button-container .table-button:hover span {
  color: #1252af;
}
.modal-template .modal-body .table-section-template .table-row-delete .button-container span {
  font-size: 15px;
  color: #7b8994;
  transition: 0.3s ease;
}
.modal-template .modal-body .table-services .table-col-duration {
  width: 163px;
}
.modal-template .modal-body .table-services .table-col-rate {
  width: 206px;
}
.modal-template .modal-body .table-services .table-col-tasks {
  width: 270px;
}
.modal-template .modal-body .table-services .table-row-description {
  color: #000000;
}
.modal-template .modal-body .table-services .table-row .modal-tasks-container {
  display: flex;
  align-items: center;
}
.modal-template .modal-body .table-services .table-row .modal-tasks-container .tasks-modal-item {
  display: flex;
  align-items: center;
  margin-right: 12px;
  color: #1252af;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.modal-template .modal-body .table-services .table-row .modal-tasks-container span {
  margin-right: 7px;
  color: #1252af;
}
.modal-template .modal-body .table-services .table-row .modal-tasks-container span.j-icon-task {
  font-size: 19px;
}
.modal-template .modal-body .table-services .table-row .modal-tasks-container span.j-icon-event {
  font-size: 15px;
}
.modal-template .modal-body .table-expenses .table-col-type {
  width: 192px;
}
.modal-template .modal-body .table-expenses .table-col-amount {
  width: 104px;
}
.modal-template .modal-body .table-expenses .table-col-blank {
  width: 290px;
}
.modal-template .modal-footer .button-container {
  justify-content: space-between;
}
.modal-template .modal-footer .button-container .btn-template {
  margin-left: 0;
}
.modal-template .modal-footer .button-container .right-container {
  display: flex;
}
.modal-template .modal-footer .button-container .right-container .btn-template {
  margin-left: 12px;
}
#fileDownload {
  display: none;
  position: absolute;
}
.item-task {
  color: #1252af;
  font-size: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.item-task .status-task {
  position: relative;
  bottom: 6px;
  display: inline-block;
  border: 3px solid black;
  border-radius: 90px;
  margin-right: 5px;
}
.item-task .name-task {
  cursor: pointer;
  font-size: 14px;
}
.item-task .j-icon-edit {
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}
.item-task .j-icon-edit:hover {
  font-weight: 500;
}
.item-task:hover .icon-edit-task {
  display: contents;
  margin-left: 3px;
}
.name-task:hover {
  text-decoration: underline;
}
.icon-edit-task {
  display: none;
  margin-left: 3px;
}
.name-task-block {
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 80%;
  overflow: hidden;
}
.status-task-block {
  display: contents;
}
/*# sourceMappingURL=matter-plan-of-action-modal.component.css.map */
