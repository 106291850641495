<div class="modal-template default-modal">
  <form [formGroup]="inviteForm" (ngSubmit)="onSubmit()">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          {{ 'team.addUser' | translate }}
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">
        <perfect-scrollbar>
          <div class="content-container">
            <div class="link-container-text" [innerHTML]="'team.addUserToolTip' | translate"></div>
            <div class="input-container" [ngClass]="{ 'invalid-validation': f.get('email').errors && submittedInviteForm }">
              <label>Email<span class="required-validation">*</span></label>
              <input type="text" formControlName="email" placeholder="{{ 'typeUserEmail' | translate }}" />
            </div>
            <div class="input-container" [ngClass]="{ 'invalid-validation': f.get('firstName').errors && submittedInviteForm }">
              <label>{{ 'contact.firstName' | translate }}<span class="required-validation">*</span></label>
              <input type="text" formControlName="firstName" placeholder="{{ 'exampleName' | translate }}" />
            </div>
            <!--              <div class="input-container">-->
            <!--                <label>Middle Name</label>-->
            <!--                <input type="text" formControlName="middleName" placeholder="Example: Sergeevich" />-->
            <!--              </div>-->

            <div class="input-container" [ngClass]="{ 'invalid-validation': f.get('lastName').errors && submittedInviteForm }">
              <label>{{ 'contact.lastName' | translate }}<span class="required-validation">*</span></label>
              <input type="text" formControlName="lastName" placeholder="{{ 'exampleSurname' | translate }}" />
            </div>

            <div class="input-container">
              <label>{{ 'team.permissions' | translate }}</label>
              <div class="input-container checkbox-container checkbox-bold">
                <input type="checkbox" id="checkbox-administrator" [checked]="templateRoles[0].checked" />
                <label for="checkbox-administrator" class="custom-tooltip-activator">
                  <div class="checkbox-item" (click)="toggleAdminRole($event)"></div>
                  <span (click)="toggleAdminRole($event)">{{ templateRoles[0].label }}</span>
                  <span class="j2-icon-info"></span>
                  <div class="custom-tooltip-container custom-tooltip-right">
                    <div class="tooltip-wrapper">
                      <p class="tooltip-text" [innerHTML]="'toolTip.administrator' | translate"></p>
                    </div>
                  </div>
                </label>
              </div>
              <div class="input-container checkbox-container checkbox-bold" >
                <input type="checkbox" id="checkbox-accounts" [checked]="templateRoles[1].checked" />
                <label for="checkbox-accounts" class="custom-tooltip-activator">
                  <div class="checkbox-item" (click)="toggleRole($event, 1)"></div>
                  <span (click)="toggleRole($event, 1)">{{ templateRoles[1].label }}</span>
                  <span class="j2-icon-info"></span>
                  <div class="custom-tooltip-container custom-tooltip-right">
                    <div class="tooltip-wrapper">
                      <p class="tooltip-text" [innerHTML]="'toolTip.account' | translate"></p>
                    </div>
                  </div>
                </label>
              </div>
              <div class="input-container checkbox-container checkbox-bold" >
                <input type="checkbox" id="checkbox-reports" [checked]="templateRoles[2].checked" />
                <label for="checkbox-reports" class="custom-tooltip-activator">
                  <div class="checkbox-item" (click)="toggleRole($event, 2)"></div>
                  <span (click)="toggleRole($event, 2)">{{ templateRoles[2].label }}</span>
                  <span class="j2-icon-info"></span>
                  <div class="custom-tooltip-container custom-tooltip-right">
                    <div class="tooltip-wrapper">
                      <p class="tooltip-text" [innerHTML]="'toolTip.report' | translate"></p>
                    </div>
                  </div>
                </label>
              </div>
              <div class="input-container checkbox-container checkbox-bold">
                <input type="checkbox" id="checkbox-billing" [checked]="templateRoles[3].checked" />
                <label for="checkbox-billing" class="custom-tooltip-activator">
                  <div class="checkbox-item" (click)="toggleRole($event, 3)"></div>
                  <span (click)="toggleRole($event, 3)">{{ templateRoles[3].label }}</span>
                  <span class="j2-icon-info"></span>
                  <div class="custom-tooltip-container custom-tooltip-right">
                    <div class="tooltip-wrapper">
                      <p class="tooltip-text" [innerHTML]="'toolTip.billing' | translate"></p>
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <div class="input-container" [ngClass]="{ 'invalid-validation': f.get('groups').errors && submittedInviteForm }">
              <label class="custom-tooltip-activator">
                {{ 'team.groups' | translate }}
                <span class="j2-icon-info"></span>
                <div class="custom-tooltip-container custom-tooltip-right">
                  <div class="tooltip-wrapper">
                    <p class="tooltip-text" [innerHTML]="'toolTip.group' | translate"></p>
                  </div>
                </div>
              </label>
              <ejs-multiselect
                formControlName="groups"
                [dataSource]="groups"
                #usersComboBox
                [noRecordsTemplate]="'noRecord' | translate"
                mode="Box"
                [fields]="{ text: 'name', value: 'id' }"
                placeholder="{{ 'common.select' | translate }}"
                (valueChange)="selectUser(); fixHeightList('usersComboBox')"
                (blur)="selectUser()"
                (created)="selectUser()"
                (close)="selectUser()"
                (open)="fixPopUp($event, 'usersComboBox')"
                (mousedown)="fixPopUp($event, 'usersComboBox')"
                (select)="fixHeightList('usersComboBox')"
                [ngStyle]="{ 'margin-bottom': f.get(['groups']).value['length'] * 33 + 15 + 'px' }"
              >
              </ejs-multiselect>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <button class="btn-template btn-blue dropdown-button" >{{ 'settings.team.invite' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>
