<div class="modal-template">
  <div class="modal-container">
    <header>
      <div class="bg-img" [ngStyle]="{'background-image': 'url(../../../../../assets/images/' + bgImg + ')'}"></div>
      <div class="close-btn" (click)="dialogRef.close()">
        <span class="j-icon-cross"></span>
      </div>
    </header>
    <main>
      <div class="main-text">
        <div class="title">
          {{titleTxt | translate: { tariff: data.tariff.name } }}
        </div>
        <div class="description">
          {{descriptionTxt | translate: { tariff: data.tariff.name } }}
        </div>
      </div>
      <div class="buy-subs">
        <a routerLink="/settings/subscription-pay" (click)="dialogRef.close(true)">
          <ng-container *ngIf="checkRole; else elBlock">
            {{'reports.modal.btnText' | translate: { tariff: data.tariff.name } }}
          </ng-container>
          <ng-template #elBlock>
            {{'cannotDeleteButton' | translate}}
          </ng-template>
        </a>
      </div>
    </main>

  </div>
</div>
