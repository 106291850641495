<div class="modal-template default-modal" *ngIf="accountForm">
  <form (ngSubmit)="onSubmit()" [formGroup]="accountForm">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          {{'settings.correspondence.email.add' | translate }}
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body {{proffFields ? 'proff' : 'deff'}}">
          <div class="content-container document-container">
            <div class="default-fileds" *ngIf="!proffFields" [@disappearanceAnimation]>
              <div
              class="input-container"
              [ngClass]="{ 'invalid-validation': f.get(['correspondenceEmailName']).errors && submittedForm }"
              >
                <label>Email<span class="required-validation">*</span></label>
                <div class="inner-input input-container">
                  <input type="text" placeholder="Email" formControlName="correspondenceEmailName" />
                  <div class="inbox-container">
                    <span>inbox+</span>
                  </div>
                  <div class="abs-name">
                    <span>
                      &#64;jusnote.com
                    </span>
                  </div>
                </div>
                <div class="preview">
                  <span>
                    <span class="txt">{{'settings.correspondence.email.preview' | translate }}:</span> inbox+{{f.get(['correspondenceEmailName'])?.value}}&#64;jusnote.com</span>
                </div>
              </div>
            </div>

            <div class="proff-fileds" *ngIf="proffFields"  [@disappearanceAnimation]>
              <div
                class="input-container"
                [ngClass]="{ 'invalid-validation': f.get(['username']).errors && submittedForm || errorProff }"
              >
                <label>{{ 'Email' | translate }}<span class="required-validation">*</span></label>
                <input type="text" placeholder="example@office.com" formControlName="username" />
              </div>

              <div
                class="input-container"
                [ngClass]="{ 'invalid-validation': f.get(['password']).errors && submittedForm || errorProff }"
              >
                <label>{{ 'login.password' | translate }}<span class="required-validation">*</span></label>
                <input
                  type="password"
                  placeholder="{{ 'login.password' | translate }}"
                  formControlName="password" />
              </div>

              <div class="sub-title">
                <span>
                  Server Name:
                </span>
              </div>
              <div class="input-wrapper">
                <div
                  class="input-container"
                  [ngClass]="{ 'invalid-validation': f.get(['host']).errors && submittedForm || errorProff }"
                >
                  <label>{{ 'IMAP Server' | translate }}<span class="required-validation">*</span></label>
                  <input type="text" placeholder="{{'common.typeHere' | translate }}" formControlName="host" />
                </div>
                <div
                  class="input-container"
                  [ngClass]="{ 'invalid-validation': f.get(['port']).errors && submittedForm || errorProff }"
                >
                  <label>{{ 'IMAP Port' | translate }}<span class="required-validation">*</span></label>
                  <input type="text" placeholder="993" formControlName="port" />
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <a (click)="switchType()">
            {{ proffFields ? ('settings.correspondence.email.default' | translate) : ('settings.correspondence.email.yourEmailAdd' | translate) }}
          </a>
          <button class="btn-template btn-blue dropdown-button account-save" >
            <span>{{ checking ? 'Checking' : 'button.save' | translate }}</span>
            <app-loader *ngIf="checking && showLoader" [size]="'small'"></app-loader>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
