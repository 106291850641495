import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-not-available-modal',
    templateUrl: './not-available-modal.component.html',
    styleUrls: ['./not-available-modal.component.scss'],
    standalone: false
})
export class NotAvailableModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<NotAvailableModalComponent>) {}

  ngOnInit() {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
