<div class="modal-template">
  <form class="modal-container">
    <div class="modal-header">
      <div class="title-text">
        <span>{{'automation.createAutomation' | translate}}</span>
      </div>
      <div class="close-modal" (click)="closeModal()">
        <span class="j-icon-cross"></span>
      </div>
    </div>
    <div class="modal-body" [formGroup]="automationForm">

      <div
      class="input-container"
      [ngClass]="{
        'invalid-validation':
        automationForm.get('title') && automationForm.get('title').errors && submittedForm
      }"
      >
        <label>{{ 'table.name' | translate }}<span class="required-validation">*</span></label>
        <input
          type="text"
          placeholder="{{ 'common.typeHere' | translate }}"
          formControlName="title"
        />
      </div>
    </div>
    <div class="modal-footer">
      <div class="button-container">
        <button
          class="btn-template btn-blue"
          (click)="submit()"
        >
        {{ 'button.save' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
