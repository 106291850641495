/* src/app/commonComponents/modals/default-modals/settings-library-modal/library-checkbox-create-modal/library-checkbox-create-modal.component.scss */
.input-container.checkbox-container label {
  display: inline-flex;
}
.input-container.checkbox-container label .j2-icon-info {
  margin-left: 5px;
}
.modal-template .modal-body {
  padding: 0;
}
.modal-template .btn-template:disabled {
  transition: none;
}
.modal-template .btn-template:disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.checkbox-item__invalid {
  border-color: rgba(224, 59, 75, 0) !important;
  box-shadow:
    rgba(0, 0, 0, 0) 0 0 0 0,
    #FABCC7 0 0 0 2px,
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(64, 68, 82, 0) 0 0 0 1px,
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0) 0 0 0 0 !important;
}
/*# sourceMappingURL=library-checkbox-create-modal.component.css.map */
