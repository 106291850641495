<div class="modal-template default-modal">
  <form [formGroup]="libraryForm">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          {{ 'settings.library.expensesCreate' | translate }}
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="content-container">
          <div class="input-container" [ngClass]="{ 'invalid-validation': f.get(['name']).errors && submittedForm }">
            <label class="dot-label">{{ 'settings.library.expensesType' | translate }}</label>
            <input type="text" placeholder="{{ 'common.typeHere' | translate }}" formControlName="name" />
          </div>
          <div class="input-container checkbox-container invalid-validation">
            <input type="checkbox" id="checkbox-expenses" formControlName="clientExpense" (change)="setErrorsCheckbox()" />
            <label class="custom-tooltip-activator" for="checkbox-expenses">
              <div class="checkbox-item"
                   [ngClass]="{ 'checkbox-item__invalid': f.get(['clientExpense']).errors && submittedForm }"></div>
              {{ 'settings.library.client-expenses' | translate }}
              <span class="j2-icon-info"></span>
              <div class="custom-tooltip-container custom-tooltip-right-bottom custom-tooltip-small">
                <div class="tooltip-wrapper">
                  <p class="tooltip-text">{{ 'settings.library.client-expensesToolTip' | translate }}</p>
                </div>
              </div>
            </label>
          </div>
          <div class="input-container checkbox-container">
            <input type="checkbox" id="checkbox-costs" formControlName="internalExpense" (change)="setErrorsCheckbox()" />
            <label class="custom-tooltip-activator" for="checkbox-costs">
              <div class="checkbox-item"
                   [ngClass]="{ 'checkbox-item__invalid': f.get(['internalExpense']).errors && submittedForm }"></div>
              {{ 'settings.library.internal-expenses' | translate }}
              <span class="j2-icon-info"></span>
              <div class="custom-tooltip-container custom-tooltip-right-bottom custom-tooltip-small">
                <div class="tooltip-wrapper">
                  <p class="tooltip-text">{{ 'settings.library.internal-expensesToolTip' | translate }}</p>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <button class="btn-template btn-blue dropdown-button" (click)="onLibraryCreate()">
            {{ 'button.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
