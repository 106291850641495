{
  "main": {
    "uk": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "uk"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "час: {0}",
          "regionFormat-type-daylight": "час: {0}, літній",
          "regionFormat-type-standard": "час: {0}, стандартний",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Адак"
              },
              "Anchorage": {
                "exemplarCity": "Анкоридж"
              },
              "Anguilla": {
                "exemplarCity": "Анґілья"
              },
              "Antigua": {
                "exemplarCity": "Антиґуа"
              },
              "Araguaina": {
                "exemplarCity": "Араґуаіна"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Ріо-Ґальєґос"
                },
                "San_Juan": {
                  "exemplarCity": "Сан-Хуан"
                },
                "Ushuaia": {
                  "exemplarCity": "Ушуая"
                },
                "La_Rioja": {
                  "exemplarCity": "Ла-Ріоха"
                },
                "San_Luis": {
                  "exemplarCity": "Сан-Луїс"
                },
                "Salta": {
                  "exemplarCity": "Сальта"
                },
                "Tucuman": {
                  "exemplarCity": "Тукуман"
                }
              },
              "Aruba": {
                "exemplarCity": "Аруба"
              },
              "Asuncion": {
                "exemplarCity": "Асунсьйон"
              },
              "Bahia": {
                "exemplarCity": "Байя"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Баїя Бандерас"
              },
              "Barbados": {
                "exemplarCity": "Барбадос"
              },
              "Belem": {
                "exemplarCity": "Белен"
              },
              "Belize": {
                "exemplarCity": "Беліз"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Блан-Саблон"
              },
              "Boa_Vista": {
                "exemplarCity": "Боа-Віста"
              },
              "Bogota": {
                "exemplarCity": "Боґота"
              },
              "Boise": {
                "exemplarCity": "Бойсе"
              },
              "Buenos_Aires": {
                "exemplarCity": "Буенос-Айрес"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Кеймбрідж-Бей"
              },
              "Campo_Grande": {
                "exemplarCity": "Кампу-Ґранді"
              },
              "Cancun": {
                "exemplarCity": "Канкун"
              },
              "Caracas": {
                "exemplarCity": "Каракас"
              },
              "Catamarca": {
                "exemplarCity": "Катамарка"
              },
              "Cayenne": {
                "exemplarCity": "Каєнна"
              },
              "Cayman": {
                "exemplarCity": "Кайманові Острови"
              },
              "Chicago": {
                "exemplarCity": "Чікаґо"
              },
              "Chihuahua": {
                "exemplarCity": "Чіуауа"
              },
              "Coral_Harbour": {
                "exemplarCity": "Атікокан"
              },
              "Cordoba": {
                "exemplarCity": "Кордова"
              },
              "Costa_Rica": {
                "exemplarCity": "Коста-Ріка"
              },
              "Creston": {
                "exemplarCity": "Крестон"
              },
              "Cuiaba": {
                "exemplarCity": "Куяба"
              },
              "Curacao": {
                "exemplarCity": "Кюрасао"
              },
              "Danmarkshavn": {
                "exemplarCity": "Денмарксхавн"
              },
              "Dawson": {
                "exemplarCity": "Доусон"
              },
              "Dawson_Creek": {
                "exemplarCity": "Доусон-Крік"
              },
              "Denver": {
                "exemplarCity": "Денвер"
              },
              "Detroit": {
                "exemplarCity": "Детройт"
              },
              "Dominica": {
                "exemplarCity": "Домініка"
              },
              "Edmonton": {
                "exemplarCity": "Едмонтон"
              },
              "Eirunepe": {
                "exemplarCity": "Ейрунепе"
              },
              "El_Salvador": {
                "exemplarCity": "Сальвадор"
              },
              "Fort_Nelson": {
                "exemplarCity": "Форт Нельсон"
              },
              "Fortaleza": {
                "exemplarCity": "Форталеза"
              },
              "Glace_Bay": {
                "exemplarCity": "Ґлейс-Бей"
              },
              "Godthab": {
                "exemplarCity": "Нуук"
              },
              "Goose_Bay": {
                "exemplarCity": "Ґус-Бей"
              },
              "Grand_Turk": {
                "exemplarCity": "Ґранд-Терк"
              },
              "Grenada": {
                "exemplarCity": "Ґренада"
              },
              "Guadeloupe": {
                "exemplarCity": "Ґваделупа"
              },
              "Guatemala": {
                "exemplarCity": "Ґватемала"
              },
              "Guayaquil": {
                "exemplarCity": "Ґуаякіль"
              },
              "Guyana": {
                "exemplarCity": "Ґайана"
              },
              "Halifax": {
                "exemplarCity": "Галіфакс"
              },
              "Havana": {
                "exemplarCity": "Гавана"
              },
              "Hermosillo": {
                "exemplarCity": "Ермосільйо"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Вінсенс, Індіана"
                },
                "Petersburg": {
                  "exemplarCity": "Пітерсберг, Індіана"
                },
                "Tell_City": {
                  "exemplarCity": "Телл-Сіті, Індіана"
                },
                "Knox": {
                  "exemplarCity": "Нокс, Індіана"
                },
                "Winamac": {
                  "exemplarCity": "Вінамак, Індіана"
                },
                "Marengo": {
                  "exemplarCity": "Маренго, Індіана"
                },
                "Vevay": {
                  "exemplarCity": "Вівей, Індіана"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Індіанаполіс"
              },
              "Inuvik": {
                "exemplarCity": "Інувік"
              },
              "Iqaluit": {
                "exemplarCity": "Ікалуїт"
              },
              "Jamaica": {
                "exemplarCity": "Ямайка"
              },
              "Jujuy": {
                "exemplarCity": "Жужуй"
              },
              "Juneau": {
                "exemplarCity": "Джуно"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Монтіселло, Кентуккі"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Кралендейк"
              },
              "La_Paz": {
                "exemplarCity": "Ла-Пас"
              },
              "Lima": {
                "exemplarCity": "Ліма"
              },
              "Los_Angeles": {
                "exemplarCity": "Лос-Анджелес"
              },
              "Louisville": {
                "exemplarCity": "Луїсвілл"
              },
              "Lower_Princes": {
                "exemplarCity": "Лоуер-Принсес-Квотер"
              },
              "Maceio": {
                "exemplarCity": "Масейо"
              },
              "Managua": {
                "exemplarCity": "Манаґуа"
              },
              "Manaus": {
                "exemplarCity": "Манаус"
              },
              "Marigot": {
                "exemplarCity": "Маріґо"
              },
              "Martinique": {
                "exemplarCity": "Мартініка"
              },
              "Matamoros": {
                "exemplarCity": "Матаморос"
              },
              "Mazatlan": {
                "exemplarCity": "Масатлан"
              },
              "Mendoza": {
                "exemplarCity": "Мендоса"
              },
              "Menominee": {
                "exemplarCity": "Меноміні"
              },
              "Merida": {
                "exemplarCity": "Меріда"
              },
              "Metlakatla": {
                "exemplarCity": "Метлакатла"
              },
              "Mexico_City": {
                "exemplarCity": "Мехіко"
              },
              "Miquelon": {
                "exemplarCity": "Мікелон"
              },
              "Moncton": {
                "exemplarCity": "Монктон"
              },
              "Monterrey": {
                "exemplarCity": "Монтерей"
              },
              "Montevideo": {
                "exemplarCity": "Монтевідео"
              },
              "Montserrat": {
                "exemplarCity": "Монтсеррат"
              },
              "Nassau": {
                "exemplarCity": "Насау"
              },
              "New_York": {
                "exemplarCity": "Нью-Йорк"
              },
              "Nipigon": {
                "exemplarCity": "Ніпігон"
              },
              "Nome": {
                "exemplarCity": "Ном"
              },
              "Noronha": {
                "exemplarCity": "Норонья"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Бʼюла, Північна Дакота"
                },
                "New_Salem": {
                  "exemplarCity": "Нью-Салем, Північна Дакота"
                },
                "Center": {
                  "exemplarCity": "Сентр, Північна Дакота"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Охінаґа"
              },
              "Panama": {
                "exemplarCity": "Панама"
              },
              "Pangnirtung": {
                "exemplarCity": "Панґніртанґ"
              },
              "Paramaribo": {
                "exemplarCity": "Парамарибо"
              },
              "Phoenix": {
                "exemplarCity": "Фінікс"
              },
              "Port-au-Prince": {
                "exemplarCity": "Порт-о-Пренс"
              },
              "Port_of_Spain": {
                "exemplarCity": "Порт-оф-Спейн"
              },
              "Porto_Velho": {
                "exemplarCity": "Порту-Велью"
              },
              "Puerto_Rico": {
                "exemplarCity": "Пуерто-Ріко"
              },
              "Punta_Arenas": {
                "exemplarCity": "Пунта-Аренас"
              },
              "Rainy_River": {
                "exemplarCity": "Рейні-Рівер"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Ренкін-Інлет"
              },
              "Recife": {
                "exemplarCity": "Ресіфі"
              },
              "Regina": {
                "exemplarCity": "Реджайна"
              },
              "Resolute": {
                "exemplarCity": "Резольют"
              },
              "Rio_Branco": {
                "exemplarCity": "Ріо-Бранко"
              },
              "Santa_Isabel": {
                "exemplarCity": "Санта-Ісабель"
              },
              "Santarem": {
                "exemplarCity": "Сантарен"
              },
              "Santiago": {
                "exemplarCity": "Сантьяґо"
              },
              "Santo_Domingo": {
                "exemplarCity": "Санто-Домінґо"
              },
              "Sao_Paulo": {
                "exemplarCity": "Сан-Паулу"
              },
              "Scoresbysund": {
                "exemplarCity": "Іттоккортоорміут"
              },
              "Sitka": {
                "exemplarCity": "Сітка"
              },
              "St_Barthelemy": {
                "exemplarCity": "Сен-Бартелемі"
              },
              "St_Johns": {
                "exemplarCity": "Сент-Джонс"
              },
              "St_Kitts": {
                "exemplarCity": "Сент-Кіттс"
              },
              "St_Lucia": {
                "exemplarCity": "Сент-Люсія"
              },
              "St_Thomas": {
                "exemplarCity": "Сент-Томас"
              },
              "St_Vincent": {
                "exemplarCity": "Сент-Вінсент"
              },
              "Swift_Current": {
                "exemplarCity": "Свіфт-Каррент"
              },
              "Tegucigalpa": {
                "exemplarCity": "Теґусіґальпа"
              },
              "Thule": {
                "exemplarCity": "Туле"
              },
              "Thunder_Bay": {
                "exemplarCity": "Тандер-Бей"
              },
              "Tijuana": {
                "exemplarCity": "Тіхуана"
              },
              "Toronto": {
                "exemplarCity": "Торонто"
              },
              "Tortola": {
                "exemplarCity": "Тортола"
              },
              "Vancouver": {
                "exemplarCity": "Ванкувер"
              },
              "Whitehorse": {
                "exemplarCity": "Вайтгорс"
              },
              "Winnipeg": {
                "exemplarCity": "Вінніпеґ"
              },
              "Yakutat": {
                "exemplarCity": "Якутат"
              },
              "Yellowknife": {
                "exemplarCity": "Єллоунайф"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Азорські Острови"
              },
              "Bermuda": {
                "exemplarCity": "Бермуди"
              },
              "Canary": {
                "exemplarCity": "Канарські Острови"
              },
              "Cape_Verde": {
                "exemplarCity": "Кабо-Верде"
              },
              "Faeroe": {
                "exemplarCity": "Фарерські Острови"
              },
              "Madeira": {
                "exemplarCity": "Мадейра"
              },
              "Reykjavik": {
                "exemplarCity": "Рейкʼявік"
              },
              "South_Georgia": {
                "exemplarCity": "Південна Джорджія"
              },
              "St_Helena": {
                "exemplarCity": "Острів Святої Єлени"
              },
              "Stanley": {
                "exemplarCity": "Стенлі"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Амстердам"
              },
              "Andorra": {
                "exemplarCity": "Андорра"
              },
              "Astrakhan": {
                "exemplarCity": "Астрахань"
              },
              "Athens": {
                "exemplarCity": "Афіни"
              },
              "Belgrade": {
                "exemplarCity": "Белґрад"
              },
              "Berlin": {
                "exemplarCity": "Берлін"
              },
              "Bratislava": {
                "exemplarCity": "Братислава"
              },
              "Brussels": {
                "exemplarCity": "Брюссель"
              },
              "Bucharest": {
                "exemplarCity": "Бухарест"
              },
              "Budapest": {
                "exemplarCity": "Будапешт"
              },
              "Busingen": {
                "exemplarCity": "Бюзінген"
              },
              "Chisinau": {
                "exemplarCity": "Кишинів"
              },
              "Copenhagen": {
                "exemplarCity": "Копенгаґен"
              },
              "Dublin": {
                "long": {
                  "daylight": "за літнім часом в Ірландії"
                },
                "exemplarCity": "Дублін"
              },
              "Gibraltar": {
                "exemplarCity": "Ґібралтар"
              },
              "Guernsey": {
                "exemplarCity": "Ґернсі"
              },
              "Helsinki": {
                "exemplarCity": "Гельсінкі"
              },
              "Isle_of_Man": {
                "exemplarCity": "Острів Мен"
              },
              "Istanbul": {
                "exemplarCity": "Стамбул"
              },
              "Jersey": {
                "exemplarCity": "Джерсі"
              },
              "Kaliningrad": {
                "exemplarCity": "Калінінград"
              },
              "Kiev": {
                "exemplarCity": "Київ"
              },
              "Kirov": {
                "exemplarCity": "Кіров"
              },
              "Lisbon": {
                "exemplarCity": "Лісабон"
              },
              "Ljubljana": {
                "exemplarCity": "Любляна"
              },
              "London": {
                "long": {
                  "daylight": "за літнім часом у Великій Британії"
                },
                "exemplarCity": "Лондон"
              },
              "Luxembourg": {
                "exemplarCity": "Люксембурґ"
              },
              "Madrid": {
                "exemplarCity": "Мадрид"
              },
              "Malta": {
                "exemplarCity": "Мальта"
              },
              "Mariehamn": {
                "exemplarCity": "Марієгамн"
              },
              "Minsk": {
                "exemplarCity": "Мінськ"
              },
              "Monaco": {
                "exemplarCity": "Монако"
              },
              "Moscow": {
                "exemplarCity": "Москва"
              },
              "Oslo": {
                "exemplarCity": "Осло"
              },
              "Paris": {
                "exemplarCity": "Париж"
              },
              "Podgorica": {
                "exemplarCity": "Подгориця"
              },
              "Prague": {
                "exemplarCity": "Прага"
              },
              "Riga": {
                "exemplarCity": "Рига"
              },
              "Rome": {
                "exemplarCity": "Рим"
              },
              "Samara": {
                "exemplarCity": "Самара"
              },
              "San_Marino": {
                "exemplarCity": "Сан-Маріно"
              },
              "Sarajevo": {
                "exemplarCity": "Сараєво"
              },
              "Saratov": {
                "exemplarCity": "Саратов"
              },
              "Simferopol": {
                "exemplarCity": "Сімферополь"
              },
              "Skopje": {
                "exemplarCity": "Скопʼє"
              },
              "Sofia": {
                "exemplarCity": "Софія"
              },
              "Stockholm": {
                "exemplarCity": "Стокгольм"
              },
              "Tallinn": {
                "exemplarCity": "Таллінн"
              },
              "Tirane": {
                "exemplarCity": "Тирана"
              },
              "Ulyanovsk": {
                "exemplarCity": "Ульяновськ"
              },
              "Uzhgorod": {
                "exemplarCity": "Ужгород"
              },
              "Vaduz": {
                "exemplarCity": "Вадуц"
              },
              "Vatican": {
                "exemplarCity": "Ватикан"
              },
              "Vienna": {
                "exemplarCity": "Відень"
              },
              "Vilnius": {
                "exemplarCity": "Вільнюс"
              },
              "Volgograd": {
                "exemplarCity": "Волгоград"
              },
              "Warsaw": {
                "exemplarCity": "Варшава"
              },
              "Zagreb": {
                "exemplarCity": "Заґреб"
              },
              "Zaporozhye": {
                "exemplarCity": "Запоріжжя"
              },
              "Zurich": {
                "exemplarCity": "Цюріх"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Абіджан"
              },
              "Accra": {
                "exemplarCity": "Аккра"
              },
              "Addis_Ababa": {
                "exemplarCity": "Аддис-Абеба"
              },
              "Algiers": {
                "exemplarCity": "Алжир"
              },
              "Asmera": {
                "exemplarCity": "Асмера"
              },
              "Bamako": {
                "exemplarCity": "Бамако"
              },
              "Bangui": {
                "exemplarCity": "Банґі"
              },
              "Banjul": {
                "exemplarCity": "Банжул"
              },
              "Bissau": {
                "exemplarCity": "Бісау"
              },
              "Blantyre": {
                "exemplarCity": "Блантайр"
              },
              "Brazzaville": {
                "exemplarCity": "Браззавіль"
              },
              "Bujumbura": {
                "exemplarCity": "Бужумбура"
              },
              "Cairo": {
                "exemplarCity": "Каїр"
              },
              "Casablanca": {
                "exemplarCity": "Касабланка"
              },
              "Ceuta": {
                "exemplarCity": "Сеута"
              },
              "Conakry": {
                "exemplarCity": "Конакрі"
              },
              "Dakar": {
                "exemplarCity": "Дакар"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Дар-ес-Салам"
              },
              "Djibouti": {
                "exemplarCity": "Джібуті"
              },
              "Douala": {
                "exemplarCity": "Дуала"
              },
              "El_Aaiun": {
                "exemplarCity": "Ель-Аюн"
              },
              "Freetown": {
                "exemplarCity": "Фрітаун"
              },
              "Gaborone": {
                "exemplarCity": "Ґабороне"
              },
              "Harare": {
                "exemplarCity": "Хараре"
              },
              "Johannesburg": {
                "exemplarCity": "Йоганнесбурґ"
              },
              "Juba": {
                "exemplarCity": "Джуба"
              },
              "Kampala": {
                "exemplarCity": "Кампала"
              },
              "Khartoum": {
                "exemplarCity": "Хартум"
              },
              "Kigali": {
                "exemplarCity": "Кігалі"
              },
              "Kinshasa": {
                "exemplarCity": "Кіншаса"
              },
              "Lagos": {
                "exemplarCity": "Лаґос"
              },
              "Libreville": {
                "exemplarCity": "Лібревіль"
              },
              "Lome": {
                "exemplarCity": "Ломе"
              },
              "Luanda": {
                "exemplarCity": "Луанда"
              },
              "Lubumbashi": {
                "exemplarCity": "Лубумбаші"
              },
              "Lusaka": {
                "exemplarCity": "Лусака"
              },
              "Malabo": {
                "exemplarCity": "Малабо"
              },
              "Maputo": {
                "exemplarCity": "Мапуту"
              },
              "Maseru": {
                "exemplarCity": "Масеру"
              },
              "Mbabane": {
                "exemplarCity": "Мбабане"
              },
              "Mogadishu": {
                "exemplarCity": "Моґадішо"
              },
              "Monrovia": {
                "exemplarCity": "Монровія"
              },
              "Nairobi": {
                "exemplarCity": "Найробі"
              },
              "Ndjamena": {
                "exemplarCity": "Нджамена"
              },
              "Niamey": {
                "exemplarCity": "Ніамей"
              },
              "Nouakchott": {
                "exemplarCity": "Нуакшотт"
              },
              "Ouagadougou": {
                "exemplarCity": "Уаґадуґу"
              },
              "Porto-Novo": {
                "exemplarCity": "Порто-Ново"
              },
              "Sao_Tome": {
                "exemplarCity": "Сан-Томе"
              },
              "Tripoli": {
                "exemplarCity": "Тріполі"
              },
              "Tunis": {
                "exemplarCity": "Туніс"
              },
              "Windhoek": {
                "exemplarCity": "Віндгук"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Аден"
              },
              "Almaty": {
                "exemplarCity": "Алмати"
              },
              "Amman": {
                "exemplarCity": "Амман"
              },
              "Anadyr": {
                "exemplarCity": "Анадир"
              },
              "Aqtau": {
                "exemplarCity": "Актау"
              },
              "Aqtobe": {
                "exemplarCity": "Актобе"
              },
              "Ashgabat": {
                "exemplarCity": "Ашгабат"
              },
              "Atyrau": {
                "exemplarCity": "Атирау"
              },
              "Baghdad": {
                "exemplarCity": "Багдад"
              },
              "Bahrain": {
                "exemplarCity": "Бахрейн"
              },
              "Baku": {
                "exemplarCity": "Баку"
              },
              "Bangkok": {
                "exemplarCity": "Банґкок"
              },
              "Barnaul": {
                "exemplarCity": "Барнаул"
              },
              "Beirut": {
                "exemplarCity": "Бейрут"
              },
              "Bishkek": {
                "exemplarCity": "Бішкек"
              },
              "Brunei": {
                "exemplarCity": "Бруней"
              },
              "Calcutta": {
                "exemplarCity": "Колката"
              },
              "Chita": {
                "exemplarCity": "Чита"
              },
              "Choibalsan": {
                "exemplarCity": "Чойбалсан"
              },
              "Colombo": {
                "exemplarCity": "Коломбо"
              },
              "Damascus": {
                "exemplarCity": "Дамаск"
              },
              "Dhaka": {
                "exemplarCity": "Дакка"
              },
              "Dili": {
                "exemplarCity": "Ділі"
              },
              "Dubai": {
                "exemplarCity": "Дубай"
              },
              "Dushanbe": {
                "exemplarCity": "Душанбе"
              },
              "Famagusta": {
                "exemplarCity": "Фамагуста"
              },
              "Gaza": {
                "exemplarCity": "Газа"
              },
              "Hebron": {
                "exemplarCity": "Хеврон"
              },
              "Hong_Kong": {
                "exemplarCity": "Гонконг"
              },
              "Hovd": {
                "exemplarCity": "Ховд"
              },
              "Irkutsk": {
                "exemplarCity": "Іркутськ"
              },
              "Jakarta": {
                "exemplarCity": "Джакарта"
              },
              "Jayapura": {
                "exemplarCity": "Джайпур"
              },
              "Jerusalem": {
                "exemplarCity": "Єрусалим"
              },
              "Kabul": {
                "exemplarCity": "Кабул"
              },
              "Kamchatka": {
                "exemplarCity": "Камчатка"
              },
              "Karachi": {
                "exemplarCity": "Карачі"
              },
              "Katmandu": {
                "exemplarCity": "Катманду"
              },
              "Khandyga": {
                "exemplarCity": "Хандига"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Красноярськ"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Куала-Лумпур"
              },
              "Kuching": {
                "exemplarCity": "Кучінґ"
              },
              "Kuwait": {
                "exemplarCity": "Кувейт"
              },
              "Macau": {
                "exemplarCity": "Макао"
              },
              "Magadan": {
                "exemplarCity": "Магадан"
              },
              "Makassar": {
                "exemplarCity": "Макассар"
              },
              "Manila": {
                "exemplarCity": "Маніла"
              },
              "Muscat": {
                "exemplarCity": "Маскат"
              },
              "Nicosia": {
                "exemplarCity": "Нікосія"
              },
              "Novokuznetsk": {
                "exemplarCity": "Новокузнецьк"
              },
              "Novosibirsk": {
                "exemplarCity": "Новосибірськ"
              },
              "Omsk": {
                "exemplarCity": "Омськ"
              },
              "Oral": {
                "exemplarCity": "Орал"
              },
              "Phnom_Penh": {
                "exemplarCity": "Пномпень"
              },
              "Pontianak": {
                "exemplarCity": "Понтіанак"
              },
              "Pyongyang": {
                "exemplarCity": "Пхеньян"
              },
              "Qatar": {
                "exemplarCity": "Катар"
              },
              "Qostanay": {
                "exemplarCity": "Костанай"
              },
              "Qyzylorda": {
                "exemplarCity": "Кизилорда"
              },
              "Rangoon": {
                "exemplarCity": "Янґон"
              },
              "Riyadh": {
                "exemplarCity": "Ер-Ріяд"
              },
              "Saigon": {
                "exemplarCity": "Хошимін"
              },
              "Sakhalin": {
                "exemplarCity": "Сахалін"
              },
              "Samarkand": {
                "exemplarCity": "Самарканд"
              },
              "Seoul": {
                "exemplarCity": "Сеул"
              },
              "Shanghai": {
                "exemplarCity": "Шанхай"
              },
              "Singapore": {
                "exemplarCity": "Сінгапур"
              },
              "Srednekolymsk": {
                "exemplarCity": "Середньоколимськ"
              },
              "Taipei": {
                "exemplarCity": "Тайбей"
              },
              "Tashkent": {
                "exemplarCity": "Ташкент"
              },
              "Tbilisi": {
                "exemplarCity": "Тбілісі"
              },
              "Tehran": {
                "exemplarCity": "Тегеран"
              },
              "Thimphu": {
                "exemplarCity": "Тхімпху"
              },
              "Tokyo": {
                "exemplarCity": "Токіо"
              },
              "Tomsk": {
                "exemplarCity": "Томськ"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Улан-Батор"
              },
              "Urumqi": {
                "exemplarCity": "Урумчі"
              },
              "Ust-Nera": {
                "exemplarCity": "Усть-Нера"
              },
              "Vientiane": {
                "exemplarCity": "Вʼєнтьян"
              },
              "Vladivostok": {
                "exemplarCity": "Владивосток"
              },
              "Yakutsk": {
                "exemplarCity": "Якутськ"
              },
              "Yekaterinburg": {
                "exemplarCity": "Єкатеринбург"
              },
              "Yerevan": {
                "exemplarCity": "Єреван"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Антананаріву"
              },
              "Chagos": {
                "exemplarCity": "Чаґос"
              },
              "Christmas": {
                "exemplarCity": "Острів Різдва"
              },
              "Cocos": {
                "exemplarCity": "Кокосові Острови"
              },
              "Comoro": {
                "exemplarCity": "Комори"
              },
              "Kerguelen": {
                "exemplarCity": "Керґелен"
              },
              "Mahe": {
                "exemplarCity": "Махе"
              },
              "Maldives": {
                "exemplarCity": "Мальдіви"
              },
              "Mauritius": {
                "exemplarCity": "Маврікій"
              },
              "Mayotte": {
                "exemplarCity": "Майотта"
              },
              "Reunion": {
                "exemplarCity": "Реюньйон"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Аделаїда"
              },
              "Brisbane": {
                "exemplarCity": "Брісбен"
              },
              "Broken_Hill": {
                "exemplarCity": "Брокен-Хілл"
              },
              "Currie": {
                "exemplarCity": "Каррі"
              },
              "Darwin": {
                "exemplarCity": "Дарвін"
              },
              "Eucla": {
                "exemplarCity": "Евкла"
              },
              "Hobart": {
                "exemplarCity": "Гобарт"
              },
              "Lindeman": {
                "exemplarCity": "Ліндеман"
              },
              "Lord_Howe": {
                "exemplarCity": "Лорд-Хау"
              },
              "Melbourne": {
                "exemplarCity": "Мельбурн"
              },
              "Perth": {
                "exemplarCity": "Перт"
              },
              "Sydney": {
                "exemplarCity": "Сідней"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Апіа"
              },
              "Auckland": {
                "exemplarCity": "Окленд"
              },
              "Bougainville": {
                "exemplarCity": "Буґенвіль"
              },
              "Chatham": {
                "exemplarCity": "Чатем"
              },
              "Easter": {
                "exemplarCity": "Острів Пасхи"
              },
              "Efate": {
                "exemplarCity": "Ефате"
              },
              "Enderbury": {
                "exemplarCity": "Ендербері"
              },
              "Fakaofo": {
                "exemplarCity": "Факаофо"
              },
              "Fiji": {
                "exemplarCity": "Фіджі"
              },
              "Funafuti": {
                "exemplarCity": "Фунафуті"
              },
              "Galapagos": {
                "exemplarCity": "Ґалапаґос"
              },
              "Gambier": {
                "exemplarCity": "Ґамбʼєр"
              },
              "Guadalcanal": {
                "exemplarCity": "Ґуадалканал"
              },
              "Guam": {
                "exemplarCity": "Ґуам"
              },
              "Honolulu": {
                "exemplarCity": "Гонолулу"
              },
              "Johnston": {
                "exemplarCity": "Джонстон"
              },
              "Kiritimati": {
                "exemplarCity": "Кірітіматі"
              },
              "Kosrae": {
                "exemplarCity": "Косрае"
              },
              "Kwajalein": {
                "exemplarCity": "Кваджалейн"
              },
              "Majuro": {
                "exemplarCity": "Маджуро"
              },
              "Marquesas": {
                "exemplarCity": "Маркізькі острови"
              },
              "Midway": {
                "exemplarCity": "Мідвей"
              },
              "Nauru": {
                "exemplarCity": "Науру"
              },
              "Niue": {
                "exemplarCity": "Ніуе"
              },
              "Norfolk": {
                "exemplarCity": "Норфолк"
              },
              "Noumea": {
                "exemplarCity": "Нумеа"
              },
              "Pago_Pago": {
                "exemplarCity": "Паго-Паго"
              },
              "Palau": {
                "exemplarCity": "Палау"
              },
              "Pitcairn": {
                "exemplarCity": "Піткерн"
              },
              "Ponape": {
                "exemplarCity": "Понапе"
              },
              "Port_Moresby": {
                "exemplarCity": "Порт-Морсбі"
              },
              "Rarotonga": {
                "exemplarCity": "Раротонґа"
              },
              "Saipan": {
                "exemplarCity": "Сайпан"
              },
              "Tahiti": {
                "exemplarCity": "Таїті"
              },
              "Tarawa": {
                "exemplarCity": "Тарава"
              },
              "Tongatapu": {
                "exemplarCity": "Тонґатапу"
              },
              "Truk": {
                "exemplarCity": "Чуук"
              },
              "Wake": {
                "exemplarCity": "Вейк"
              },
              "Wallis": {
                "exemplarCity": "Уолліс"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Лонґйїр"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Кейсі"
              },
              "Davis": {
                "exemplarCity": "Девіс"
              },
              "DumontDUrville": {
                "exemplarCity": "Дюмон-дʼЮрвіль"
              },
              "Macquarie": {
                "exemplarCity": "Маккуорі"
              },
              "Mawson": {
                "exemplarCity": "Моусон"
              },
              "McMurdo": {
                "exemplarCity": "Мак-Мердо"
              },
              "Palmer": {
                "exemplarCity": "Палмер"
              },
              "Rothera": {
                "exemplarCity": "Ротера"
              },
              "Syowa": {
                "exemplarCity": "Сьова"
              },
              "Troll": {
                "exemplarCity": "Тролл"
              },
              "Vostok": {
                "exemplarCity": "Восток"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "за всесвітнім координованим часом"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "Невідоме місто"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "час: Акрі",
                "standard": "час: Акрі, стандартний",
                "daylight": "час: Акрі, літній"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "за часом в Афганістані"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "за центральноафриканським часом"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "за східноафриканським часом"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "за південноафриканським часом"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "за західноафриканським часом",
                "standard": "за західноафриканським стандартним часом",
                "daylight": "за західноафриканським літнім часом"
              }
            },
            "Alaska": {
              "long": {
                "generic": "за часом на Алясці",
                "standard": "за стандартним часом на Алясці",
                "daylight": "за літнім часом на Алясці"
              }
            },
            "Amazon": {
              "long": {
                "generic": "за часом на Амазонці",
                "standard": "за стандартним часом на Амазонці",
                "daylight": "за літнім часом на Амазонці"
              }
            },
            "America_Central": {
              "long": {
                "generic": "за північноамериканським центральним часом",
                "standard": "за північноамериканським центральним стандартним часом",
                "daylight": "за північноамериканським центральним літнім часом"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "за північноамериканським східним часом",
                "standard": "за північноамериканським східним стандартним часом",
                "daylight": "за північноамериканським східним літнім часом"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "за північноамериканським гірським часом",
                "standard": "за північноамериканським гірським стандартним часом",
                "daylight": "за північноамериканським гірським літнім часом"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "за північноамериканським тихоокеанським часом",
                "standard": "за північноамериканським тихоокеанським стандартним часом",
                "daylight": "за північноамериканським тихоокеанським літнім часом"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "час: Анадир",
                "standard": "час: Анадир, стандартний",
                "daylight": "час: Анадир, літній"
              }
            },
            "Apia": {
              "long": {
                "generic": "за часом в Апіа",
                "standard": "за стандартним часом в Апіа",
                "daylight": "за літнім часом в Апіа"
              }
            },
            "Arabian": {
              "long": {
                "generic": "за арабським часом",
                "standard": "за арабським стандартним часом",
                "daylight": "за арабським літнім часом"
              }
            },
            "Argentina": {
              "long": {
                "generic": "за аргентинським часом",
                "standard": "за стандартним аргентинським часом",
                "daylight": "за літнім аргентинським часом"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "за західноаргентинським часом",
                "standard": "за стандартним західноаргентинським часом",
                "daylight": "за літнім за західноаргентинським часом"
              }
            },
            "Armenia": {
              "long": {
                "generic": "за вірменським часом",
                "standard": "за вірменським стандартним часом",
                "daylight": "за вірменським літнім часом"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "за атлантичним часом",
                "standard": "за атлантичним стандартним часом",
                "daylight": "за атлантичним літнім часом"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "за центральноавстралійським часом",
                "standard": "за стандартним центральноавстралійським часом",
                "daylight": "за літнім центральноавстралійським часом"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "за центральнозахідним австралійським часом",
                "standard": "за стандартним центральнозахідним австралійським часом",
                "daylight": "за літнім центральнозахідним австралійським часом"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "за східноавстралійським часом",
                "standard": "за стандартним східноавстралійським часом",
                "daylight": "за літнім східноавстралійським часом"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "за західноавстралійським часом",
                "standard": "за стандартним західноавстралійським часом",
                "daylight": "за літнім західноавстралійським часом"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "за часом в Азербайджані",
                "standard": "за стандартним часом в Азербайджані",
                "daylight": "за літнім часом в Азербайджані"
              }
            },
            "Azores": {
              "long": {
                "generic": "за часом на Азорських Островах",
                "standard": "за стандартним часом на Азорських Островах",
                "daylight": "за літнім часом на Азорських Островах"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "за часом у Бангладеш",
                "standard": "за стандартним часом у Бангладеш",
                "daylight": "за літнім часом у Бангладеш"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "за часом у Бутані"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "за болівійським часом"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "за бразильським часом",
                "standard": "за стандартним бразильським часом",
                "daylight": "за літнім бразильським часом"
              }
            },
            "Brunei": {
              "long": {
                "standard": "за часом у Брунеї"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "за часом на островах Кабо-Верде",
                "standard": "за стандартним часом на островах Кабо-Верде",
                "daylight": "за літнім часом на островах Кабо-Верде"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "за часом на Північних Маріанських островах"
              }
            },
            "Chatham": {
              "long": {
                "generic": "за часом на архіпелазі Чатем",
                "standard": "за стандартним часом на архіпелазі Чатем",
                "daylight": "за літнім часом на архіпелазі Чатем"
              }
            },
            "Chile": {
              "long": {
                "generic": "за чилійським часом",
                "standard": "за стандартним чилійським часом",
                "daylight": "за літнім чилійським часом"
              }
            },
            "China": {
              "long": {
                "generic": "за китайським часом",
                "standard": "за китайським стандартним часом",
                "daylight": "за китайським літнім часом"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "за часом у Чойбалсані",
                "standard": "за стандартним часом у Чойбалсані",
                "daylight": "за літнім часом у Чойбалсані"
              }
            },
            "Christmas": {
              "long": {
                "standard": "за часом на острові Різдва"
              }
            },
            "Cocos": {
              "long": {
                "standard": "за часом на Кокосових Островах"
              }
            },
            "Colombia": {
              "long": {
                "generic": "за колумбійським часом",
                "standard": "за стандартним колумбійським часом",
                "daylight": "за літнім колумбійським часом"
              }
            },
            "Cook": {
              "long": {
                "generic": "за часом на Островах Кука",
                "standard": "за стандартним часом на Островах Кука",
                "daylight": "за літнім часом на Островах Кука"
              }
            },
            "Cuba": {
              "long": {
                "generic": "за часом на Кубі",
                "standard": "за стандартним часом на Кубі",
                "daylight": "за літнім часом на Кубі"
              }
            },
            "Davis": {
              "long": {
                "standard": "за часом у Девіс"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "за часом у Дюмон дʼЮрвіль"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "за часом у Східному Тиморі"
              }
            },
            "Easter": {
              "long": {
                "generic": "за часом на острові Пасхи",
                "standard": "за стандартним часом на острові Пасхи",
                "daylight": "за літнім часом на острові Пасхи"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "за часом в Еквадорі"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "за центральноєвропейським часом",
                "standard": "за центральноєвропейським стандартним часом",
                "daylight": "за центральноєвропейським літнім часом"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "за східноєвропейським часом",
                "standard": "за східноєвропейським стандартним часом",
                "daylight": "за східноєвропейським літнім часом"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "за далекосхідним європейським часом"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "за західноєвропейським часом",
                "standard": "за західноєвропейським стандартним часом",
                "daylight": "за західноєвропейським літнім часом"
              }
            },
            "Falkland": {
              "long": {
                "generic": "за часом на Фолклендських Островах",
                "standard": "за стандартним часом на Фолклендських Островах",
                "daylight": "за літнім часом на Фолклендських Островах"
              }
            },
            "Fiji": {
              "long": {
                "generic": "за часом на Фіджі",
                "standard": "за стандартним часом на Фіджі",
                "daylight": "за літнім часом на Фіджі"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "за часом Французької Ґвіани"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "за часом на Французьких Південних і Антарктичних територіях"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "за часом Ґалапаґосу"
              }
            },
            "Gambier": {
              "long": {
                "standard": "за часом на острові Ґамбʼє"
              }
            },
            "Georgia": {
              "long": {
                "generic": "за часом у Грузії",
                "standard": "за стандартним часом у Грузії",
                "daylight": "за літнім часом у Грузії"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "за часом на островах Гілберта"
              }
            },
            "GMT": {
              "long": {
                "standard": "за Ґрінвічем"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "за східним часом у Ґренландії",
                "standard": "за стандартним східним часом у Ґренландії",
                "daylight": "за літнім східним часом у Ґренландії"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "за західним часом у Ґренландії",
                "standard": "за стандартним західним часом у Ґренландії",
                "daylight": "за літнім західним часом у Ґренландії"
              }
            },
            "Gulf": {
              "long": {
                "standard": "за часом Перської затоки"
              }
            },
            "Guyana": {
              "long": {
                "standard": "за часом у Ґаяні"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "за гавайсько-алеутським часом",
                "standard": "за стандартним гавайсько-алеутським часом",
                "daylight": "за літнім гавайсько-алеутським часом"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "за часом у Гонконзі",
                "standard": "за стандартним часом у Гонконзі",
                "daylight": "за літнім часом у Гонконзі"
              }
            },
            "Hovd": {
              "long": {
                "generic": "за часом у Ховді",
                "standard": "за стандартним часом у Ховді",
                "daylight": "за літнім часом у Ховді"
              }
            },
            "India": {
              "long": {
                "standard": "за індійським стандартним часом"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "за часом в Індійському Океані"
              }
            },
            "Indochina": {
              "long": {
                "standard": "за часом в Індокитаї"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "за центральноіндонезійським часом"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "за східноіндонезійським часом"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "за західноіндонезійським часом"
              }
            },
            "Iran": {
              "long": {
                "generic": "за іранським часом",
                "standard": "за іранським стандартним часом",
                "daylight": "за іранським літнім часом"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "за іркутським часом",
                "standard": "за іркутським стандартним часом",
                "daylight": "за іркутським літнім часом"
              }
            },
            "Israel": {
              "long": {
                "generic": "за ізраїльським часом",
                "standard": "за ізраїльським стандартним часом",
                "daylight": "за ізраїльським літнім часом"
              }
            },
            "Japan": {
              "long": {
                "generic": "за японським часом",
                "standard": "за японським стандартним часом",
                "daylight": "за японським літнім часом"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "за камчатським часом",
                "standard": "за камчатським стандартним часом",
                "daylight": "за камчатським літнім часом"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "за східним часом у Казахстані"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "за західним часом у Казахстані"
              }
            },
            "Korea": {
              "long": {
                "generic": "за корейським часом",
                "standard": "за корейським стандартним часом",
                "daylight": "за корейським літнім часом"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "за часом на острові Косрае"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "за красноярським часом",
                "standard": "за красноярським стандартним часом",
                "daylight": "за красноярським літнім часом"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "за часом у Киргизстані"
              }
            },
            "Lanka": {
              "long": {
                "standard": "час: Ланка"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "за часом на острові Лайн"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "за часом на острові Лорд-Хау",
                "standard": "за стандартним часом на острові Лорд-Хау",
                "daylight": "за літнім часом на острові Лорд-Хау"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "за часом на острові Маккуорі"
              }
            },
            "Magadan": {
              "long": {
                "generic": "за магаданським часом",
                "standard": "за магаданським стандартним часом",
                "daylight": "за магаданським літнім часом"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "за часом у Малайзії"
              }
            },
            "Maldives": {
              "long": {
                "standard": "за часом на Мальдівах"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "за часом на Маркізьких островах"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "за часом на Маршаллових Островах"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "за часом на острові Маврікій",
                "standard": "за стандартним часом на острові Маврікій",
                "daylight": "за літнім часом на острові Маврікій"
              }
            },
            "Mawson": {
              "long": {
                "standard": "за часом на станції Моусон"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "за північнозахідним часом у Мексиці",
                "standard": "за стандартним північнозахідним часом у Мексиці",
                "daylight": "за літнім північнозахідним часом у Мексиці"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "за тихоокеанським часом у Мексиці",
                "standard": "за стандартним тихоокеанським часом у Мексиці",
                "daylight": "за літнім тихоокеанським часом у Мексиці"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "за часом в Улан-Баторі",
                "standard": "за стандартним часом в Улан-Баторі",
                "daylight": "за літнім часом в Улан-Баторі"
              }
            },
            "Moscow": {
              "long": {
                "generic": "за московським часом",
                "standard": "за московським стандартним часом",
                "daylight": "за московським літнім часом"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "за часом у Мʼянмі"
              }
            },
            "Nauru": {
              "long": {
                "standard": "за часом на острові Науру"
              }
            },
            "Nepal": {
              "long": {
                "standard": "за часом у Непалі"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "за часом на островах Нової Каледонії",
                "standard": "за стандартним часом на островах Нової Каледонії",
                "daylight": "за літнім часом на островах Нової Каледонії"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "за часом у Новій Зеландії",
                "standard": "за стандартним часом у Новій Зеландії",
                "daylight": "за літнім часом у Новій Зеландії"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "за часом на острові Ньюфаундленд",
                "standard": "за стандартним часом на острові Ньюфаундленд",
                "daylight": "за літнім часом у Ньюфаундленд"
              }
            },
            "Niue": {
              "long": {
                "standard": "за часом на острові Ніуе"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "за часом на острові Норфолк"
              }
            },
            "Noronha": {
              "long": {
                "generic": "за часом на архіпелазі Фернанду-ді-Норонья",
                "standard": "за стандартним часом на архіпелазі Фернанду-ді-Норонья",
                "daylight": "за літнім часом на архіпелазі Фернанду-ді-Норонья"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "за новосибірським часом",
                "standard": "за новосибірським стандартним часом",
                "daylight": "за новосибірським літнім часом"
              }
            },
            "Omsk": {
              "long": {
                "generic": "за омським часом",
                "standard": "за омським стандартним часом",
                "daylight": "за омським літнім часом"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "за часом у Пакистані",
                "standard": "за стандартним часом у Пакистані",
                "daylight": "за літнім часом у Пакистані"
              }
            },
            "Palau": {
              "long": {
                "standard": "за часом на острові Палау"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "за часом на островах Папуа-Нова Ґвінея"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "за параґвайським часом",
                "standard": "за стандартним параґвайським часом",
                "daylight": "за літнім параґвайським часом"
              }
            },
            "Peru": {
              "long": {
                "generic": "за часом у Перу",
                "standard": "за стандартним часом у Перу",
                "daylight": "за літнім часом у Перу"
              }
            },
            "Philippines": {
              "long": {
                "generic": "за часом на Філіппінах",
                "standard": "за стандартним часом на Філіппінах",
                "daylight": "за літнім часом на Філіппінах"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "за часом на островах Фенікс"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "за часом на островах Сен-П’єр і Мікелон",
                "standard": "за стандартним часом на островах Сен-П’єр і Мікелон",
                "daylight": "за літнім часом на островах Сен-П’єр і Мікелон"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "за часом на островах Піткерн"
              }
            },
            "Ponape": {
              "long": {
                "standard": "за часом на острові Понапе"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "за часом у Пхеньяні"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "час: Кизилорда",
                "standard": "час: Кизилорда, стандартний",
                "daylight": "час: Кизилорда, літній"
              }
            },
            "Reunion": {
              "long": {
                "standard": "за часом на острові Реюньйон"
              }
            },
            "Rothera": {
              "long": {
                "standard": "за часом на станції Ротера"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "за сахалінським часом",
                "standard": "за сахалінським стандартним часом",
                "daylight": "за сахалінським літнім часом"
              }
            },
            "Samara": {
              "long": {
                "generic": "за самарським часом",
                "standard": "за самарським стандартним часом",
                "daylight": "за самарським літнім часом"
              }
            },
            "Samoa": {
              "long": {
                "generic": "за часом на острові Самоа",
                "standard": "за стандартним часом на острові Самоа",
                "daylight": "за літнім часом на острові Самоа"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "за часом на Сейшельських Островах"
              }
            },
            "Singapore": {
              "long": {
                "standard": "за часом у Сінґапурі"
              }
            },
            "Solomon": {
              "long": {
                "standard": "за часом на Соломонових Островах"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "за часом на острові Південна Джорджія"
              }
            },
            "Suriname": {
              "long": {
                "standard": "за часом у Суринамі"
              }
            },
            "Syowa": {
              "long": {
                "standard": "за часом на станції Сева"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "за часом на острові Таїті"
              }
            },
            "Taipei": {
              "long": {
                "generic": "за часом у Тайбеї",
                "standard": "за стандартним часом у Тайбеї",
                "daylight": "за літнім часом у Тайбеї"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "за часом у Таджикистані"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "за часом на островах Токелау"
              }
            },
            "Tonga": {
              "long": {
                "generic": "за часом на островах Тонґа",
                "standard": "за стандартним часом на островах Тонґа",
                "daylight": "за літнім часом на островах Тонґа"
              }
            },
            "Truk": {
              "long": {
                "standard": "за часом на островах Чуук"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "за часом у Туркменістані",
                "standard": "за стандартним часом у Туркменістані",
                "daylight": "за літнім часом у Туркменістані"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "за часом на островах Тувалу"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "за часом в Уруґваї",
                "standard": "за стандартним часом в Уруґваї",
                "daylight": "за літнім часом в Уруґваї"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "за часом в Узбекистані",
                "standard": "за стандартним часом в Узбекистані",
                "daylight": "за літнім часом в Узбекистані"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "за часом на островах Вануату",
                "standard": "за стандартним часом на островах Вануату",
                "daylight": "за літнім часом на островах Вануату"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "за часом у Венесуелі"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "за владивостоцьким часом",
                "standard": "за владивостоцьким стандартним часом",
                "daylight": "за владивостоцьким літнім часом"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "за волгоградським часом",
                "standard": "за волгоградським стандартним часом",
                "daylight": "за волгоградським літнім часом"
              }
            },
            "Vostok": {
              "long": {
                "standard": "за часом на станції Восток"
              }
            },
            "Wake": {
              "long": {
                "standard": "за часом на острові Вейк"
              }
            },
            "Wallis": {
              "long": {
                "standard": "за часом на островах Уолліс і Футуна"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "за якутським часом",
                "standard": "за якутським стандартним часом",
                "daylight": "за якутським літнім часом"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "за єкатеринбурзьким часом",
                "standard": "за єкатеринбурзьким стандартним часом",
                "daylight": "за єкатеринбурзьким літнім часом"
              }
            }
          }
        }
      }
    }
  }
}
