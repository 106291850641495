/* src/app/commonComponents/modals/other-modals/video-modal/video-modal.component.scss */
.modal-video-template {
  position: relative;
  width: 800px;
  background-color: #000;
}
.modal-video-template .close-modal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 2;
  cursor: pointer;
}
.modal-video-template .close-modal span {
  font-size: 15px;
  color: #fff;
}
.modal-video-template .video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.modal-video-template iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=video-modal.component.css.map */
