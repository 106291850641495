/* src/app/commonComponents/modals/other-modals/account-correspondence-modal/account-correspondence-modal.component.scss */
.modal-template .checkbox-container {
  display: inline-block;
  margin-right: 12px;
}
.modal-template .modal-body {
  padding: 0;
}
.modal-template .proff-fileds,
.modal-template .default-fileds {
  position: absolute;
  min-height: 100px;
  width: calc(100% - 44px);
  left: 22px;
}
.modal-template .inner-input {
  position: relative;
  display: flex;
  align-items: center;
}
.modal-template .inner-input input {
  margin: 0 !important;
  order: 2;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.modal-template .inner-input .abs-name {
  position: absolute;
  top: 8px;
  right: 20px;
  z-index: 10;
}
.modal-template .inner-input .inbox-container {
  border: 1px solid #d5d5d5;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  color: #57626E;
  margin-right: -2px;
  z-index: 2;
  background: #FCFCFC;
  transition: all 0.5s ease-in-out;
}
.modal-template .preview {
  margin-top: 10px;
  font-size: 13px;
}
.modal-template .preview .txt {
  color: #707B86;
}
.modal-template .modal-footer .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.modal-template .modal-footer .button-container a {
  color: #7b8994;
  font-size: 14px;
  font-weight: 400;
}
.modal-template .sub-title {
  font-size: 13px;
  color: #707B86;
  margin-bottom: 15px;
  margin-top: 9px;
}
.input-wrapper .input-container:last-child {
  flex: 0 0 155px;
  max-width: 155px;
}
.input-wrapper .input-container:first-child {
  flex: 0 0 calc(100% - 165px);
  max-width: calc(100% - 165px);
}
input:-internal-autofill-selected {
  background-color: none !important;
}
::ng-deep .deff {
  width: calc(100% - 44px);
}
::ng-deep .deff .content-container {
  min-height: 120px;
}
::ng-deep .proff .content-container {
  min-height: 280px;
}
::ng-deep .account-save .mat-button-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}
.title-text {
  display: flex;
  align-items: center;
}
.title-text .j2-icon-cloud {
  padding-right: 7px;
}
.linkToDocument-error {
  font-size: 13px;
}
.linkToDocument-error .error-header {
  color: #e03b4b;
  margin: 0;
}
.linkToDocument-error .error-body {
  color: #222222;
  margin-top: 0;
}
.linkToDocument-error .error-body span:hover {
  text-decoration: underline;
}
.modal-footer .button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.modal-footer .button-container a {
  color: #7b8994;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.tag-block {
  position: relative;
  order: 1;
}
.tag-block.active {
  order: 3;
}
.document-add-more-field-button {
  margin-bottom: 14px;
}
.document-container {
  display: flex;
  flex-direction: column;
}
.divider {
  position: absolute;
  top: -14px;
  left: -12px;
  right: -12px;
  height: 1px;
  background-color: #eceef2;
}
/*# sourceMappingURL=account-correspondence-modal.component.css.map */
