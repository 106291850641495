import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { ETeamMemberRoles } from '@app/enums/team-member-roles';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAppState } from '@app/store/state/app.state';
import { Invite } from '@app/modules/settings/store/actions/team-member.actions';
import { invitedTeamMember } from '@app/modules/settings/store/selectors/team-member.selector';
import { selectAnyoneWithoutSystemWorkGroups } from '@app/modules/settings/store/selectors/team.selector';
import { GetAnyoneWithoutSystemWorkGroups } from '@app/modules/settings/store/actions/team.actions';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';

@Component({
    selector: 'app-team-invite-modal',
    templateUrl: './team-invite-modal.component.html',
    styleUrls: ['./team-invite-modal.component.scss'],
    standalone: false
})
export class TeamInviteModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<TeamInviteModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private store: Store<IAppState>
  ) {
    this.subscribeToSuccessInvite();
    this.subscribeToGetGroups();
  }

  get f(): UntypedFormGroup {
    return this.inviteForm;
  }
  @ViewChild('usersComboBox', { static: false }) usersComboBox: MultiSelectComponent;

  public inviteForm: UntypedFormGroup;
  public templateRoles = [
    { label: this.translate.instant('ROLE_SITE_ADMIN'), value: ETeamMemberRoles.admin, checked: false, disabled: false },
    { label: this.translate.instant('ROLE_SITE_ACCOUNTS_MANAGER'), value: ETeamMemberRoles.accounts, checked: false, disabled: false },
    { label: this.translate.instant('ROLE_SITE_REPORTS_MANAGER'), value: ETeamMemberRoles.reports, checked: false, disabled: false },
    { label: this.translate.instant('ROLE_SITE_BILLING_MANAGER'), value: ETeamMemberRoles.billing, checked: false, disabled: false }
  ];
  public groups;
  public submittedInviteForm = false;
  private allSubscriptions = new Subscription();
  checkOnSubmit = false;

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {
    this.allSubscriptions.unsubscribe();
  }

  public closeModal() {
    this.dialogRef.close();
  }
  public onSubmit() {
    this.submittedInviteForm = true;
    if (this.inviteForm.valid && !this.checkOnSubmit) {
      this.checkOnSubmit = true;
      const data = {
        roles: this.inviteForm.value.roles,
        groups: this.inviteForm.value.groups,
        user: {
          email: this.inviteForm.value.email,
          person: {
            firstName: this.inviteForm.value.firstName,
            lastName: this.inviteForm.value.lastName
          }
        }
      };
      this.store.dispatch(new Invite(data));
      this.closeModal();
    }
  }

  public toggleAdminRole(event, value?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    const newValue = typeof value !== 'undefined' ? value : !this.templateRoles[0].checked;
    this.templateRoles[0].checked = newValue;

    if (newValue) {
      this.clearInviteFormRoles();
      this.setRoleToInviteForm(this.templateRoles[0].value);
    } else {
      this.clearInviteFormRoles();
    }
    this.manageCheckboxesOnAdminRoleToggle(newValue);
  }

  public toggleRole(event, index, value?) {
    event.stopPropagation();
    event.preventDefault();

    if (!this.templateRoles[index].disabled) {
      const newValue = typeof value !== 'undefined' ? value : !this.templateRoles[index].checked;
      this.templateRoles[index].checked = newValue;

      if (newValue) {
        this.setRoleToInviteForm(this.templateRoles[index].value);
      } else {
        this.removeRoleInviteForm(this.templateRoles[index].value);
      }
    }
  }

  private subscribeToSuccessInvite() {
    this.allSubscriptions.add(
      this.store.select(invitedTeamMember).subscribe(invitedUser => {
        if (invitedUser && invitedUser.id && this.inviteForm) {
          this.closeModal();
        }
      })
    );
  }

  private subscribeToGetGroups() {
    this.store.dispatch(new GetAnyoneWithoutSystemWorkGroups());
    this.allSubscriptions.add(
      this.store.select(selectAnyoneWithoutSystemWorkGroups).subscribe(teams => {
        this.groups = teams;
      })
    );
  }

  private setRoleToInviteForm(value) {
    const roles = this.inviteForm.get('roles') as UntypedFormArray;
    if (!roles.value.includes(value)) {
      roles.push(this.formBuilder.control(value));
    }
  }

  private removeRoleInviteForm(value) {
    const roles = this.inviteForm.get('roles') as UntypedFormArray;
    if (roles.value.includes(value)) {
      const deleteIndex = roles.value.indexOf(value);
      roles.removeAt(deleteIndex);
    }
  }

  private manageCheckboxesOnAdminRoleToggle(value) {
    for (let i = 1; i <= 3; i++) {
      this.templateRoles[i].checked = value;
      this.templateRoles[i].disabled = value;
    }
  }

  private clearInviteFormRoles() {
    const roles = this.inviteForm.get('roles') as UntypedFormArray;
    roles.clear();
  }

  private createForm() {
    this.inviteForm = this.formBuilder.group({
      email: [null, Validators.required],
      firstName: [null, [Validators.required, Validators.minLength(3)]],
      middleName: null,
      lastName: [null, [Validators.required, Validators.minLength(3)]],
      roles: this.formBuilder.array([]),
      groups: [[]],
      message: null
    });
  }
  fixPopUp(event, selectName) {
    const e = event?.event || event;
    if (!(e['target'] && e['target']['className'] === 'e-dropdownbase')) {
      this[selectName].hidePopup();
    }
  }
  selectUser() {
    setTimeout(() => {
      const multiselectInput = document.querySelector('.team-invite-modal .e-dropdownbase');
      multiselectInput.setAttribute('placeholder', this.translate.instant('common.typeHere'));
    }, 10);
  }
  selectIUser() {
    setTimeout(() => {
      const multiselectInput = document.querySelector('.team-invite-modal .individual-group .e-dropdownbase');
      multiselectInput.setAttribute('placeholder', this.translate.instant('chooseUsers'));
    }, 10);
  }
  fixHeightList(selectName) {
    setTimeout(() => {
      this[selectName].focusOut();
    }, 0);
  }
}
