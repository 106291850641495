<form [formGroup]="form" *ngIf="matters">
  <div>
    <div class="input-container" [ngClass]="{
      'invalid-validation': form.get('mainMatter').errors && submittedFormBill && modalSwitch
    }">
      <ng-container>
        <label>{{ 'matter' | translate }}<span class="required-validation" *ngIf="modalSwitch">*</span></label>
        <ejs-combobox
        #dropDownMatters
        class="customAdditionalMatter"
        [dataSource]="mainMatters"
        formControlName="mainMatter"
        [fields]="{ text: 'name', value: 'id' }"
        [showClearButton]="false"
        [allowFiltering]="true"
        [placeholder]="'common.select' | translate"
        [noRecordsTemplate]="'noRecord' | translate"
        (filtering)="onFilteringMatters($event, 0)"
        (change)="changeMatter($event.itemData)"
      ></ejs-combobox>
      </ng-container>
    </div>

    <ng-container *appCheckTariff="[1,4]">
      <ng-container *ngIf="showAddSelect && modalSwitch && addionalMatters?.length">
        <label class="title-additional">
          {{ 'activitiesFromMatters' | translate }}
        </label>
        <div class="input-container" *ngIf="showAddSelect && modalSwitch && matters?.length > 1" [@fadeLeftAnimation]>
          <div
            class="input-container addition-fields-container"
            [@fadeLeftAnimation]
          >
            <div class="input-container">
              <ejs-multiselect
              id="multiselectelement"
              [dataSource]="addionalMatters"
              [fields]="fields"
              [mode]="mode"
              formControlName="additionalMatters"
              [selectAllText]="'bill.addActivitiesMatters' | translate"
              [unSelectAllText]="'bill.addActivitiesMatters' | translate"
              showSelectAll="true"
              [placeholder]="'common.select' | translate"
              [filterBarPlaceholder]="'discussion.startTyping' | translate"
              [noRecordsTemplate]="'noRecord' | translate"
              (valueChange)="changeAddMatters($event)"
              (filtering)="onFilteringAddMatters($event)"
              >
            </ejs-multiselect>
            </div>
            <div class="remove-matter-field" (click)="removeAddMatters()">
              <span class="j2-icon-small-bold-plus"></span>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>


  </div>
</form>

<ng-container *appCheckTariff="[1,4]">
 <div class="control-additional">
  <span>
    <button
    type="button"
    class="add-more-field"
    (click)="showAdditionalSelect()"
    [disabled]="matters?.length < 2 || !contactId"
    *ngIf="!showAddSelect && matters?.length && modalSwitch">
      <i class="j2-icon-small-bold-plus"></i>
      <p>{{ 'addActivitiesFromMatters' | translate }}</p>
    </button>
  </span>
  <app-select-date-popup
    (applyEmit)="changeDates($event)"
    *ngIf='modalSwitch && contactId'
  ></app-select-date-popup>
 </div>
</ng-container>

