<div class="modal-template">
  <div class="modal-container">
    <div class="slider-container">
      <div
        class="slider-item"
        *ngFor="let item of sliderItems; let i = index"
        [ngClass]="{
          slide1: item.count == 1,
          slide2: item.count == 2
        }"
      >
        <div class="modal-header-image" [ngStyle]="{ 'background-image': 'url(' + item.plugin.image + ')' }">
          <div class="close-modal" (click)="closeModal()">
            <span class="j-icon-cross"></span>
          </div>
        </div>
        <div class="text">
          <p class="slide-title">{{ item.plugin.title }}</p>
          <p class="slide-description" [innerHTML]="item.plugin.description"></p>
          <button *ngIf="i === 2; else nextButton" (click)="closeModal()" class="btn-template btn-blue btn-next">
            {{ 'juscourtTracker.tutorial.begin' | translate }}
          </button>
          <ng-template #nextButton>
            <button (click)="nextSlide()" class="btn-template btn-blue btn-next">{{ 'settings.subscription.next' | translate }}</button>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="dots-container">
      <div class="dots-item" *ngFor="let item of sliderItems" [ngClass]="{ active: item.count == 1 }"></div>
    </div>
  </div>
</div>
