/* src/app/commonComponents/modals/other-modals/group-create-modal/group-create-modal.component.scss */
.modal-template .modal-container {
  width: 590px;
  height: 100%;
}
.modal-template .modal-body {
  padding: 10px 0 0px 0;
  height: calc(100% - 49px);
}
.modal-template .modal-body .group-list-container,
.modal-template .modal-body .group-settings-container {
  height: 100%;
}
.modal-template .modal-body .group-list-container {
  flex: 0 0 32%;
  max-width: 32%;
}
.modal-template .group-list-container .group-list-title {
  position: relative;
  padding: 0 10px 0 20px;
  margin-bottom: 6px;
}
.modal-template .group-list-container .group-list-title p {
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.modal-template .group-list-container .group-search-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
.modal-template .group-list-container .group-search-container span {
  font-size: 14px;
  color: #222222;
}
.modal-template .group-list-outer {
  height: calc(100% - 25px);
}
.modal-template .group-list-outer .group-list-inner {
  padding: 0 10px 0 0px;
}
.modal-template .group-list-outer .group-list-inner .group-item {
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 14px;
  margin-bottom: 6px;
}
.modal-template .group-list-outer .group-list-inner .group-item.last-added {
  position: relative;
}
.modal-template .group-list-outer .group-list-inner .group-item.last-added:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -11px;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: #1252af;
  border-radius: 50%;
}
.modal-template .group-list-outer .group-list-inner .group-item span {
  font-size: 11px;
  color: #7b8994;
  margin-left: 5px;
  cursor: pointer;
}
.modal-template .group-settings-container {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal-template .group-settings-container .group-fields-container {
  height: calc(100% - 41px);
  padding-left: 22px;
}
.modal-template .group-settings-container .group-fields-container-wrapper {
  padding-right: 20px;
}
.modal-template .group-settings-container .group-fields-container-wrapper label {
  margin-left: 1px;
}
.modal-template .group-settings-container .group-fields-container-wrapper .group-name {
  display: flex;
  align-items: center;
}
.modal-template .group-settings-container .group-fields-container-wrapper .group-name .j2-icon-ellipse {
  font-size: 7px;
  color: #1252AF;
  margin-right: 14px;
}
.modal-template .modal-footer {
  padding: 0 20px;
  height: 70px;
  justify-content: space-between;
  background: #ffffff;
}
.modal-template .modal-footer .button-container {
  justify-content: space-between;
}
.table-search-container input:focus {
  width: 225px;
  border-color: #6d7b8a;
  pointer-events: auto;
  padding-left: 26px;
  padding-right: 5px;
}
.blueCircle {
  width: 2px;
  height: 2px;
  border-radius: 30px;
  border: 2px solid white;
  margin: 10px 8px;
}
.delayBlueCircle {
  animation: delay 5s;
  animation-iteration-count: 1;
}
@keyframes delay {
  0% {
    border: 2px solid #1252af;
  }
  50% {
    border: 2px solid #1252af;
  }
  100% {
    border: 2px solid white;
  }
}
.group-fields .input-container .disabled {
  background: #f4f4f4;
  border-color: #d5d5d5;
  -webkit-text-fill-color: #a6a6a6;
  color: #a6a6a6;
  opacity: 1;
}
.button-container {
  width: auto;
}
.button-container .btn-attach {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1252af;
  border-radius: 3px;
  height: 30px;
  min-width: 160px;
}
.button-container .btn-attach .name {
  color: #1252af;
  height: 28px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 17px;
  padding-right: 15px;
  cursor: pointer;
  justify-content: center;
}
.button-container .btn-attach .name:hover {
  background: #f7faff;
}
.button-container .btn-attach .icon-container {
  border-left: 1px solid #1252af;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  height: 28px;
  padding-top: 1px;
  cursor: pointer;
  position: relative;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.button-container .btn-attach .icon-container .j-icon-arrow-down-thin {
  transition: 0.3s ease;
  font-size: 7px;
}
.button-container .btn-attach .icon-container:hover {
  background: #f7faff;
}
.button-container .btn-attach .icon-container:hover .j-icon-arrow-down-thin {
  transition: 0.3s ease;
  transform: rotate(180deg);
}
.button-container .btn-attach .icon-container:hover .dropdown-items {
  max-height: 200px;
  opacity: 1;
  height: 41px;
  border-width: 1px;
}
.button-container .btn-attach .icon-container:hover .empty-drop-down {
  height: 10px;
}
.button-container .empty-drop-down {
  width: 180px;
  position: absolute;
  right: 0;
  bottom: 25px;
}
.button-container .dropdown-items {
  width: 165px;
  max-height: 0px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border: 0px solid #F8EFF0;
  border-radius: 2px;
  position: absolute;
  bottom: 34px;
  right: 0px;
  background: #ffffff;
}
.button-container .dropdown-items .item-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;
  height: 41px;
  color: #E03B4B;
}
.button-container .dropdown-items .item-dropdown i {
  margin-left: 14px;
  margin-right: 8px;
  color: #E03B4B;
}
.button-container .dropdown-items .item-dropdown:hover {
  background: #FFF8F9;
}
/*# sourceMappingURL=group-create-modal.component.css.map */
