<div class="modal-template">
  <div class="modal-container">
    <div class="modal-header-not-available"></div>
    <div class="modal-body-not-available">
      <h2>This feature is available in Professional package.</h2>
      <p>
        Please, contact your administrator and inform that you need a function that is available in the «Professional» package.
      </p>
      <button (click)="closeModal()" class="btn-template btn-blue dropdown-button" >Okay</button>
      <p class="learn-text">Learn more about the plan <a href="#">here</a></p>
    </div>
  </div>
</div>
