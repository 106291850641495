/* src/app/commonComponents/modals/default-modals/event-info-modal/event-info-modal.component.scss */
textarea {
  height: 94px;
}
.modal-template {
  width: 400px;
  position: relative;
}
.modal-template .modal-container {
  width: 400px;
}
.modal-template .modal-header {
  height: 34px;
  max-width: 400px;
}
.modal-template .modal-header .title-text {
  margin: 5px 18px !important;
}
.modal-template .modal-body {
  margin: 16px 16px 34px;
  padding: 0 !important;
  max-width: 400px;
}
.modal-template .modal-body .content-container {
  padding: 0 !important;
  max-width: 366px;
}
.modal-template .modal-body .content-container .task-about {
  padding: 0 0 2px 0;
  margin: 0;
}
.modal-template .modal-body .content-container .time-container {
  padding: 2px 0 6px 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.modal-template .modal-body .content-container .time-container .time {
  padding: 0;
  margin: 0;
}
.modal-template .modal-body .content-container .time-container .separator-container {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.modal-template .modal-body .content-container .time-container .separator-container .j-icon-date-separator {
  font-size: 9px;
  color: #b1d1ff;
}
.modal-template .modal-body .content-container .time-container .separator-container span {
  color: #7b8994;
  font-size: 14px;
  font-weight: 400;
}
.modal-template .modal-body .content-container .events-item {
  display: flex;
}
.modal-template .modal-body .content-container .events-item input {
  display: none;
}
.modal-template .modal-body .content-container .events-item input:checked ~ label {
  background-color: #f2f2f2;
  border: 2px solid #1252af;
  position: relative;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  margin-right: 9px;
}
.modal-template .modal-body .content-container .events-item input:checked ~ label:before {
  background-color: #1252af;
  content: "";
  position: absolute;
  margin: 4px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  left: -2px;
  top: -2px;
}
.modal-template .modal-body .content-container .events-item label {
  position: relative;
  display: block;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #d5d5d5;
  margin-right: 9px;
}
.modal-template .modal-body .content-container .events-item label:before {
  content: "";
  position: absolute;
  margin: 2px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
}
.modal-template .hr-line {
  background-color: #ededed;
  height: 1px;
  width: 100%;
  max-width: 354px;
  margin: 0 23px;
}
.modal-template .modal-footer {
  max-width: 400px;
  height: 46px !important;
  padding: 0 !important;
}
.modal-template .modal-footer .active-block {
  width: 100%;
  display: flex;
  margin: 12px 23px 14px 23px;
}
.modal-template .modal-footer .active-block .more-info {
  color: #1252af;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  padding: 0;
  cursor: pointer;
}
.modal-template .modal-footer .active-block .more-info a:active,
.modal-template .modal-footer .active-block .more-info a:focus {
  outline: none;
  cursor: pointer;
}
.modal-template .modal-footer .active-block .buttons-container {
  display: flex;
  margin: auto;
  margin-right: 0;
}
.modal-template .modal-footer .active-block .buttons-container a {
  font-weight: 600 !important;
  margin-left: 10px;
  align-items: baseline;
  display: flex;
  cursor: pointer;
}
.modal-template .modal-footer .active-block .buttons-container a .j2-icon-remove,
.modal-template .modal-footer .active-block .buttons-container a .j-icon-edit {
  margin-right: 4px;
}
.modal-template .modal-footer .active-block .buttons-container a:active,
.modal-template .modal-footer .active-block .buttons-container a:focus {
  outline: none;
}
.modal-template .modal-footer .active-block .button-delete a,
.modal-template .modal-footer .active-block .button-delete .j2-icon-remove {
  color: #e03b4b;
}
/*# sourceMappingURL=event-info-modal.component.css.map */
