/* src/app/app.component.scss */
::ng-deep .main .wrapper {
  width: 100%;
  min-width: 800px;
  margin: 0 auto;
  display: flex;
  min-height: 100vh;
}
::ng-deep .main .wrapper .content-block {
  width: 100%;
  min-width: calc(100% - 180px);
}
::ng-deep .main.onboard .components-wrapper {
  max-height: calc(100vh - 60px - 37px);
  min-height: calc(100vh - 60px - 37px);
  height: calc(100vh - 60px - 37px);
}
::ng-deep .main.onboard .scroll-loop,
::ng-deep .main.onboard .change-table-wrapper {
  max-height: calc(100vh - 165px - 37px);
}
::ng-deep .main.onboard .discussion-sidebar {
  top: 100%;
  height: calc(100vh - 97px);
}
::ng-deep .main .app-routing-container {
  position: relative;
  min-height: calc(100vh - 127px);
}
::ng-deep .main .onboarding {
  height: 33px;
  padding: 0 20px;
  background: #F8F8F8;
  display: flex;
  align-items: center;
  letter-spacing: 0.14px;
  color: #707B86;
  width: 100%;
  font-size: 12px;
  border-bottom: 1px solid #f0f4f7;
}
::ng-deep .main .onboarding a {
  text-decoration: underline;
  color: #1252AF;
  transition: 0.2s;
  cursor: pointer;
}
::ng-deep .main .onboarding a:hover {
  opacity: 0.6;
}
/*# sourceMappingURL=app.component.css.map */
