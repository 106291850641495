<div class="modal-template default-modal">
  <form [formGroup]="libraryForm">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">{{ 'button.create' | translate }} {{ data.name | translate }}</div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="content-container">
          <div class="input-container" [ngClass]="{ 'invalid-validation': f.get(['name']).errors && submittedForm }">
            <label class="dot-label">{{ 'table.name' | translate }}</label>
            <input type="text" placeholder="{{ 'common.typeHere' | translate }}" formControlName="name" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <button class="btn-template btn-blue dropdown-button"  (click)="onLibraryCreate()">
            {{ 'button.save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
