/* src/app/commonComponents/modals/default-modals/tax-create-modal/tax-create-modal.component.scss */
.text-select-field .left-item {
  max-width: calc(100% - 88px);
  width: 100%;
}
.text-select-field .right-item {
  width: 88px;
}
.custom-tooltip-activator .j2-icon-info {
  margin: 0 0 3px 2px;
}
/*# sourceMappingURL=tax-create-modal.component.css.map */
