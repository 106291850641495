/* src/app/commonComponents/modals/other-modals/share-public-modal/share-public-modal.component.scss */
.modal-share-template .modal-title {
  margin-bottom: 10px;
}
.modal-share-template .modal-title i {
  font-size: 14px;
  margin-right: 8px;
}
.modal-share-template .modal-body > p {
  color: #222222;
  font-size: 13px;
  margin-bottom: 15px;
}
.modal-share-template .modal-body .input-wrapper .input-container:first-child {
  flex: 0 0 205px;
  max-width: 205px;
}
.modal-share-template .modal-body .input-wrapper .input-container:last-child {
  flex: 0 0 calc(100% - 215px);
  max-width: calc(100% - 215px);
}
.modal-share-template .modal-body .text-select-field .left-item {
  width: calc(100% - 35px);
}
.modal-share-template .modal-body .text-select-field .right-item {
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-share-template .modal-body .text-select-field .right-item i {
  display: block;
  font-size: 20px;
  color: #d5d5d5;
  transition: 0.3s ease;
  cursor: pointer;
  pointer-events: none;
}
.modal-share-template .modal-body .text-select-field .right-item i.active {
  color: #1252af;
  pointer-events: auto;
}
.modal-share-template .button-container {
  justify-content: space-between;
  align-items: center;
}
.modal-share-template .button-container p {
  color: #1252af;
  font-size: 13px;
}
.preview-block-wrapper iframe {
  width: 100%;
}
.document-container {
  transform: scale(0.5);
  width: 200% !important;
  left: -50%;
  position: absolute;
  top: -50%;
  height: 200%;
}
/*# sourceMappingURL=share-public-modal.component.css.map */
