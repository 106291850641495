<div class="modal-share-template">
  <div class="modal-container">
    <div class="modal-title">
      <h3>
        <i class="j2-icon-cloud"></i>
        {{ 'bill.shareInvoice' | translate }}
      </h3>
      <div class="close-modal" (click)="closeModal()">
        <span class="j-icon-cross"></span>
      </div>
    </div>
    <div class="modal-body">
      <p>{{ 'bill.shareInvoice.generateLink' | translate }}</p>
      <div class="preview-block">
        <div class="preview-block-wrapper">
          <iframe class="document-container" id="share-bill-iframe" name="invoice-iframe" frameborder="0" scrolling="no"></iframe>
          <div class="preview-document-status {{ dialogData.bill?.status !== 'awaiting_payment' ? dialogData.bill?.status : '' }}">
            <svg width="59" version="1.1" viewBox="0 0 129.48 31.572" xmlns="http://www.w3.org/2000/svg">
              <defs>
                <mask id="tjm9d" x="-1" y="-1" width="2" height="2">
                  <path d="m-27-22h120v120h-120z" fill="#fff" />
                  <path d="m71.697-21.456 20.517 20.602-98.334 98.744-20.517-20.603z" />
                </mask>
                <filter id="tjm9c" x="-43" y="-38" width="152" height="155" filterUnits="userSpaceOnUse">
                  <feOffset dy="3" in="SourceGraphic" result="FeOffset1043Out" />
                  <feGaussianBlur in="FeOffset1043Out" result="FeGaussianBlur1044Out" stdDeviation="2.4 2.4" />
                </filter>
              </defs>
              <path class="svg-corner" d="m129.49 29.012-1.5938 1.5938-1.7996-1.7996 2.3533-0.83439z" fill="#032e6c" />
              <path class="svg-corner" d="m2.1651 27.126-2.1553 2.1553 2.2861 2.2861 2.7287-2.7287z" fill="#032e6c" />
              <path
                transform="rotate(45 75.407 60.525)"
                d="m71.697-21.456 20.517 20.602-98.334 98.744-20.517-20.603z"
                fill="none"
                filter="url(#tjm9c)"
                mask="url(#tjm9d)"
              />
              <path
                transform="rotate(45 75.407 60.525)"
                d="m50.33 0h41.035l-91.365 91.746v-41.207z"
                fill-opacity=".16"
                filter="url(#tjm9c)"
              />
              <path class="svg-plate" d="m100.47-0.0043944-71.325 0.14777-29.138 29.138 129.48-0.26931z" fill="#1252af" />
            </svg>
            <p>{{ dialogData.bill?.status !== 'awaiting_payment' ? dialogData.bill?.status : 'open' }}</p>
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <div class="input-container" [ngClass]="{ 'invalid-validation': !dateHash }">
          <label>{{ 'bill.shareInvoice.dateRemove' | translate }}<span class="required-validation">*</span></label>
          <ejs-datepicker
            #datePickerHash
            [(ngModel)]="dateHash"
            [ngModelOptions]="{ standalone: true }"
            [min]="minDate"
            [locale]="locale$ | async"
            [format]="dateFormat"
            [placeholder]="'common.select' | translate"
            (keypress)="changeDate()"
            (change)="changeDate()"
          >
          </ejs-datepicker>
        </div>
        <div class="input-container">
          <label>{{ 'bill.shareInvoice.invoiceLink' | translate }}</label>
          <div class="text-select-field">
            <input
              id="link-Node"
              class="left-item"
              placeholder="Сгенеруйте посилання"
              [(ngModel)]="linkFile"
              [ngModelOptions]="{ standalone: true }"
              #sharableLink
            />
            <div class="right-item right-text" (click)="copyLink(sharableLink)">
              <i class="j-icon-copy-text" [ngClass]="{ active: linkFile }"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <p>{{ 'bill.shareInvoice.descLine' | translate }}</p>
      <button *ngIf="!linkFile" class="btn-template btn-blue dropdown-button" (click)="generateHash($event)">
        {{ 'bill.shareInvoice.generate' | translate }}
      </button>
      <button *ngIf="linkFile" class="btn-template btn-blue dropdown-button" (click)="copyLink(sharableLink)">
        {{ 'bill.shareInvoice.copy' | translate }}
      </button>
    </div>
  </div>
</div>
