import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PracticeBranch, TimeEntryType, RelationshipType, PersonType } from '@app/models';
import { DocumentCategory } from '@app/models/document-category';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-library-create-modal',
    templateUrl: './library-create-modal.component.html',
    styleUrls: ['./library-create-modal.component.scss'],
    standalone: false
})
export class LibraryCreateModalComponent implements OnInit {
  item: PracticeBranch | TimeEntryType | DocumentCategory | RelationshipType | PersonType;
  libraryForm: UntypedFormGroup;
  submittedForm = false;
  constructor(
    public dialogRef: MatDialogRef<LibraryCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { name: string; value?: PracticeBranch | TimeEntryType | DocumentCategory | RelationshipType | PersonType },
    private formBuilder: UntypedFormBuilder,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    if (this.data.value) {
      this.item = this.data.value;
      this.initFormValues(this.item);
    } else {
      this.item = {
        name: ''
      };
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  onLibraryCreate() {
    this.checkSubmit();
    this.libraryForm.updateValueAndValidity();
    if (!this.libraryForm.valid) {
      return;
    }
    this.item.name = this.libraryForm.get('name').value;
    this.dialogRef.close(this.item);
    this.toastrService.success(this.translate.instant('itemAdded'));
  }

  checkSubmit() {
    this.submittedForm = true;
  }

  get f() {
    return this.libraryForm;
  }

  private createForm() {
    this.libraryForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.required])
    });
  }

  private initFormValues(data: PracticeBranch | TimeEntryType | DocumentCategory | RelationshipType | PersonType): void {
    this.libraryForm.get('name').setValue(data.name);
  }
}
