/* src/app/commonComponents/modals/other-modals/reports-tariff-modal/reports-tariff-modal.component.scss */
.modal-container {
  width: 790px;
  height: 550px;
}
.modal-container header {
  position: relative;
}
.modal-container header .bg-img {
  background-color: #1252AF;
  height: 311px;
}
.modal-container header .close-btn {
  cursor: pointer;
}
.modal-container header .close-btn .j-icon-cross {
  position: absolute;
  top: 21px;
  right: 21px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.modal-container main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 540px;
  margin: auto;
}
.modal-container main .main-text {
  padding: 33px 0px;
}
.modal-container main .main-text .title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 14px;
}
.modal-container main .main-text .description {
  text-align: center;
}
.modal-container main .buy-subs {
  background-color: #1252AF;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.modal-container main .buy-subs:hover {
  background-color: #0f438e;
}
.modal-container main .buy-subs a {
  color: #ffffff;
}
/*# sourceMappingURL=reports-tariff-modal.component.css.map */
