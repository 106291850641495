<div class="modal-template">
  <div class="modal-container">
    <header>
      <div class="title-container">
        <div class="cross">
          <span class="j-icon-cross"></span>
        </div>
        <div class="title">{{ 'subscriptionPayFailure.errorOccurred' | translate }}</div>
      </div>
      <div class="text">
        {{ 'juscourtTracker.errorPayText' | translate }}
      </div>
    </header>
    <main>
      <div class="img-container">
        <img src="/assets/images/plugins/juscourtTracker-icon.png" />
        <div class="trouble-circle">
          <div class="symbol-circle">!</div>
          <div class="small-circle"></div>
          <div class="big-circle"></div>
        </div>
      </div>
      <div class="name">Juscourt tracker</div>
    </main>
    <footer>
      <button (click)="closeModal()" class="btn-template btn-blue button-retry">
        {{ 'subscriptionPayFailure.retry' | translate }}
      </button>
    </footer>
  </div>
</div>
