/* src/app/commonComponents/modals/other-modals/templates-save-modal/templates-save-modal.component.scss */
.modal-template .modal-container {
  width: 540px;
  height: 100%;
}
.modal-template .modal-header-template {
  padding: 14px 19px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-template .modal-header-template .title-text {
  margin: 0;
  color: #1252af;
  font-size: 18px;
  font-weight: 500;
}
.modal-template .modal-header-template .close-modal {
  cursor: pointer;
}
.modal-template .modal-header-template .close-modal span {
  font-size: 14px;
  color: #1252af;
}
.modal-template .modal-body-template {
  padding: 0 19px 26px;
}
.modal-template .modal-body-template .button-container {
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;
}
/*# sourceMappingURL=templates-save-modal.component.css.map */
