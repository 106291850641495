/* src/app/commonComponents/additional-matter/additional-matter.component.scss */
.addition-fields-container {
  display: flex;
  align-items: center;
}
.addition-fields-container .input-container {
  width: 100%;
  max-width: calc(100% - 30px);
}
.addition-fields-container .field-separator {
  margin: 30px 10px 0;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
}
.addition-fields-container .text-select-field > .left-item {
  min-width: calc(100% - 50px);
}
.addition-fields-container .text-select-field > .right-item {
  min-width: 50px;
}
.addition-fields-container .remove-matter-field {
  cursor: pointer;
  border: 1px solid #EAEEF2;
  border-radius: 5px;
  padding: 5px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  transition: 240ms;
  margin-bottom: 15px;
  margin-left: 10px;
}
.addition-fields-container .remove-matter-field span {
  font-size: 7px;
  color: #707B86;
}
.addition-fields-container .remove-matter-field .j2-icon-small-bold-plus {
  transform: rotate(45deg);
}
.addition-fields-container .remove-matter-field:hover {
  border-color: #F5D6DC;
  background: #FFF5F7;
}
.addition-fields-container .remove-matter-field:hover .j2-icon-small-bold-plus {
  color: #E51A41 !important;
}
.control-additional {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title-additional {
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
}
.add-more-field .j-icon-create-fill {
  margin-right: 3px;
}
.popup-block {
  position: fixed;
  z-index: 7;
}
.popup-block .backdrop {
  display: block;
  content: " ";
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 6;
  left: 0;
  right: 0;
}
/*# sourceMappingURL=additional-matter.component.css.map */
