{
  "main": {
    "pl": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "pl"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tysiąc",
              "1000-count-few": "0 tysiące",
              "1000-count-many": "0 tysięcy",
              "1000-count-other": "0 tysiąca",
              "10000-count-one": "00 tysiąc",
              "10000-count-few": "00 tysiące",
              "10000-count-many": "00 tysięcy",
              "10000-count-other": "00 tysiąca",
              "100000-count-one": "000 tysiąc",
              "100000-count-few": "000 tysiące",
              "100000-count-many": "000 tysięcy",
              "100000-count-other": "000 tysiąca",
              "1000000-count-one": "0 milion",
              "1000000-count-few": "0 miliony",
              "1000000-count-many": "0 milionów",
              "1000000-count-other": "0 miliona",
              "10000000-count-one": "00 milion",
              "10000000-count-few": "00 miliony",
              "10000000-count-many": "00 milionów",
              "10000000-count-other": "00 miliona",
              "100000000-count-one": "000 milion",
              "100000000-count-few": "000 miliony",
              "100000000-count-many": "000 milionów",
              "100000000-count-other": "000 miliona",
              "1000000000-count-one": "0 miliard",
              "1000000000-count-few": "0 miliardy",
              "1000000000-count-many": "0 miliardów",
              "1000000000-count-other": "0 miliarda",
              "10000000000-count-one": "00 miliard",
              "10000000000-count-few": "00 miliardy",
              "10000000000-count-many": "00 miliardów",
              "10000000000-count-other": "00 miliarda",
              "100000000000-count-one": "000 miliard",
              "100000000000-count-few": "000 miliardy",
              "100000000000-count-many": "000 miliardów",
              "100000000000-count-other": "000 miliarda",
              "1000000000000-count-one": "0 bilion",
              "1000000000000-count-few": "0 biliony",
              "1000000000000-count-many": "0 bilionów",
              "1000000000000-count-other": "0 biliona",
              "10000000000000-count-one": "00 bilion",
              "10000000000000-count-few": "00 biliony",
              "10000000000000-count-many": "00 bilionów",
              "10000000000000-count-other": "00 biliona",
              "100000000000000-count-one": "000 bilion",
              "100000000000000-count-few": "000 biliony",
              "100000000000000-count-many": "000 bilionów",
              "100000000000000-count-other": "000 biliona"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tys'.'",
              "1000-count-few": "0 tys'.'",
              "1000-count-many": "0 tys'.'",
              "1000-count-other": "0 tys'.'",
              "10000-count-one": "00 tys'.'",
              "10000-count-few": "00 tys'.'",
              "10000-count-many": "00 tys'.'",
              "10000-count-other": "00 tys'.'",
              "100000-count-one": "000 tys'.'",
              "100000-count-few": "000 tys'.'",
              "100000-count-many": "000 tys'.'",
              "100000-count-other": "000 tys'.'",
              "1000000-count-one": "0 mln",
              "1000000-count-few": "0 mln",
              "1000000-count-many": "0 mln",
              "1000000-count-other": "0 mln",
              "10000000-count-one": "00 mln",
              "10000000-count-few": "00 mln",
              "10000000-count-many": "00 mln",
              "10000000-count-other": "00 mln",
              "100000000-count-one": "000 mln",
              "100000000-count-few": "000 mln",
              "100000000-count-many": "000 mln",
              "100000000-count-other": "000 mln",
              "1000000000-count-one": "0 mld",
              "1000000000-count-few": "0 mld",
              "1000000000-count-many": "0 mld",
              "1000000000-count-other": "0 mld",
              "10000000000-count-one": "00 mld",
              "10000000000-count-few": "00 mld",
              "10000000000-count-many": "00 mld",
              "10000000000-count-other": "00 mld",
              "100000000000-count-one": "000 mld",
              "100000000000-count-few": "000 mld",
              "100000000000-count-many": "000 mld",
              "100000000000-count-other": "000 mld",
              "1000000000000-count-one": "0 bln",
              "1000000000000-count-few": "0 bln",
              "1000000000000-count-many": "0 bln",
              "1000000000000-count-other": "0 bln",
              "10000000000000-count-one": "00 bln",
              "10000000000000-count-few": "00 bln",
              "10000000000000-count-many": "00 bln",
              "10000000000000-count-other": "00 bln",
              "100000000000000-count-one": "000 bln",
              "100000000000000-count-few": "000 bln",
              "100000000000000-count-many": "000 bln",
              "100000000000000-count-other": "000 bln"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 tys'.' ¤",
              "1000-count-few": "0 tys'.' ¤",
              "1000-count-many": "0 tys'.' ¤",
              "1000-count-other": "0 tys'.' ¤",
              "10000-count-one": "00 tys'.' ¤",
              "10000-count-few": "00 tys'.' ¤",
              "10000-count-many": "00 tys'.' ¤",
              "10000-count-other": "00 tys'.' ¤",
              "100000-count-one": "000 tys'.' ¤",
              "100000-count-few": "000 tys'.' ¤",
              "100000-count-many": "000 tys'.' ¤",
              "100000-count-other": "000 tys'.' ¤",
              "1000000-count-one": "0 mln ¤",
              "1000000-count-few": "0 mln ¤",
              "1000000-count-many": "0 mln ¤",
              "1000000-count-other": "0 mln ¤",
              "10000000-count-one": "00 mln ¤",
              "10000000-count-few": "00 mln ¤",
              "10000000-count-many": "00 mln ¤",
              "10000000-count-other": "00 mln ¤",
              "100000000-count-one": "000 mln ¤",
              "100000000-count-few": "000 mln ¤",
              "100000000-count-many": "000 mln ¤",
              "100000000-count-other": "000 mln ¤",
              "1000000000-count-one": "0 mld ¤",
              "1000000000-count-few": "0 mld ¤",
              "1000000000-count-many": "0 mld ¤",
              "1000000000-count-other": "0 mld ¤",
              "10000000000-count-one": "00 mld ¤",
              "10000000000-count-few": "00 mld ¤",
              "10000000000-count-many": "00 mld ¤",
              "10000000000-count-other": "00 mld ¤",
              "100000000000-count-one": "000 mld ¤",
              "100000000000-count-few": "000 mld ¤",
              "100000000000-count-many": "000 mld ¤",
              "100000000000-count-other": "000 mld ¤",
              "1000000000000-count-one": "0 bln ¤",
              "1000000000000-count-few": "0 bln ¤",
              "1000000000000-count-many": "0 bln ¤",
              "1000000000000-count-other": "0 bln ¤",
              "10000000000000-count-one": "00 bln ¤",
              "10000000000000-count-few": "00 bln ¤",
              "10000000000000-count-many": "00 bln ¤",
              "10000000000000-count-other": "00 bln ¤",
              "100000000000000-count-one": "000 bln ¤",
              "100000000000000-count-few": "000 bln ¤",
              "100000000000000-count-many": "000 bln ¤",
              "100000000000000-count-other": "000 bln ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} miesiąc",
          "pluralMinimalPairs-count-few": "{0} miesiące",
          "pluralMinimalPairs-count-many": "{0} miesięcy",
          "pluralMinimalPairs-count-other": "{0} miesiąca",
          "other": "Skręć w {0} w prawo."
        }
      }
    }
  }
}
