<div class="modal-template default-modal">
  <form>
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          {{ 'reports.reportsCustom' | translate }}
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">
        <perfect-scrollbar>
          <div class="content-container">
            <div class="text-container">
              <h6>{{ 'reports.reportsCustom.title' | translate }}</h6>
              <p>{{ 'reports.reportsCustom.text' | translate }}</p>
            </div>
            <div class="support-team-contacts">
              <div class="reason-contacts-inner">
                <span class="j-icon-support-team"></span>
                <h5>{{ 'needHelp' | translate }}</h5>
                <div class="contact-info">
                  <p class="general-info">
                    {{ 'needHelpText' | translate }}:<br />
                    <span>{{ 'needHelpPhone' | translate }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="input-container">
              <label>{{ 'reports.reportsCustom.reportWant' | translate }}</label>
              <textarea placeholder="{{ 'reports.reportsCustom.reportWantText' | translate }}"></textarea>
            </div>
            <div class="input-container">
              <label>{{ 'table.phone' | translate }}</label>
              <input type="text" placeholder="{{ 'reports.reportsCustom.phoneText' | translate }}" />
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="modal-footer">
        <div class="button-container">
          <p></p>
          <button class="btn-template btn-blue dropdown-button" >{{ 'reports.reportsCustom.button' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!--"reports.reportsCustom": "Замовити звіт",
"reports.reportsCustom.button": "Направити",
"reports.reportsCustom.phoneText": "Напишіть",
"reports.reportsCustom.reportWant": "Вкажіть звіт, який вам потрібен",
"reports.reportsCustom.reportWantText": "Опишіть тут",
"reports.reportsCustom.text": "Заповніть форму нижче або зв’яжіться з нами, щоб замовити спеціальний звіт для власної юридичної практики",
"reports.reportsCustom.title": "Потрібен індивідуалізований звіт?",-->

<!--"reports.reportsCustom": "Заказать отчет",
"reports.reportsCustom.button": "Отправить",
"reports.reportsCustom.phoneText": "Написать",
"reports.reportsCustom.reportWant": "Какой отчет вам необходим?",
"reports.reportsCustom.reportWantText": "Описание отчета",
"reports.reportsCustom.text": "Заполните форму ниже или свяжитесь с нами, чтобы заказать отчет для вашей юридической практики",
"reports.reportsCustom.title": "Необходим индивидуальный отчет?",-->

<!--"reports.reportsCustom": "Custom report",
"reports.reportsCustom.button": "Send",
"reports.reportsCustom.phoneText": "Type",
"reports.reportsCustom.reportWant": "What report do you need?",
"reports.reportsCustom.reportWantText": "Describe it",
"reports.reportsCustom.text": "Please, fill in the form below or contact our team to request a custom report for your own practice",
"reports.reportsCustom.title": "Need an individual report?",-->






