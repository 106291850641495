/* src/app/commonComponents/modals/system-modals/login-modal/login-modal.component.scss */
.input-container.disabled {
  pointer-events: none;
}
.resend-code {
  margin: 0;
  margin-bottom: 11px;
}
.resend-code .link {
  color: #1252AF;
  cursor: pointer;
}
/*# sourceMappingURL=login-modal.component.css.map */
