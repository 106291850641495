<ng-container [formGroup]="form">
  <div class="input-wrapper">
    <div class="input-container" [ngClass]="{ 'invalid-validation': form.get(['startDate']).errors && submittedForm }">
      <label>{{ 'taskAndEvents.modal.startDate' | translate }}<span *ngIf="form.get(['startDate'])?.validator && form.get(['startDate'])?.validator('')?.required" class="required-validation">*</span></label>
      <div class="datepicker-container">
        <ejs-datepicker
          id="startDate"
          [placeholder]="'common.select' | translate"
          formControlName="startDate"
          [format]="dateFormat"
          [locale]="locale"
          (change)="updateDateEnd.emit($event)"
        >
        </ejs-datepicker>
      </div>
    </div>
    <div class="input-container" [ngClass]="{ 'invalid-validation': form.get(['endDate']).errors && submittedForm }">
      <label>{{ 'taskAndEvents.modal.endDate' | translate }}<span *ngIf="form.get(['endDate'])?.validator && form.get(['endDate'])?.validator('')?.required" class="required-validation">*</span></label>
      <div class="datepicker-container">
        <ejs-datepicker
          id="endDate"
          [placeholder]="'common.select' | translate"
          formControlName="endDate"
          [format]="dateFormat"
          [locale]="locale"
          [min]="minEndDate"
        >
        </ejs-datepicker>
      </div>
    </div>
  </div>
</ng-container>
