{
  "main": {
    "pl": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "pl"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "czas: {0}",
          "regionFormat-type-daylight": "{0} (czas letni)",
          "regionFormat-type-standard": "{0} (czas standardowy)",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Anguilla"
              },
              "Antigua": {
                "exemplarCity": "Antigua"
              },
              "Araguaina": {
                "exemplarCity": "Araguaina"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Rio Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucuman"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Asunción"
              },
              "Bahia": {
                "exemplarCity": "Salvador"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahia Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belém"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogota"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Cancún"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Kajenna"
              },
              "Cayman": {
                "exemplarCity": "Kajmany"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Córdoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Kostaryka"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiabá"
              },
              "Curacao": {
                "exemplarCity": "Curaçao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominika"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepe"
              },
              "El_Salvador": {
                "exemplarCity": "Salwador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Gwadelupa"
              },
              "Guatemala": {
                "exemplarCity": "Gwatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guayaquil"
              },
              "Guyana": {
                "exemplarCity": "Gujana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Hawana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamajka"
              },
              "Jujuy": {
                "exemplarCity": "Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Lower Prince’s Quarter"
              },
              "Maceio": {
                "exemplarCity": "Maceió"
              },
              "Managua": {
                "exemplarCity": "Managua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martynika"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlan"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Merida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Meksyk (miasto)"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrey"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "Nowy Jork"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, Dakota Północna"
                },
                "New_Salem": {
                  "exemplarCity": "New Salem, Dakota Północna"
                },
                "Center": {
                  "exemplarCity": "Center, Dakota Północna"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port-au-Prince"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port-of-Spain"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Portoryko"
              },
              "Punta_Arenas": {
                "exemplarCity": "Punta Arenas"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Isabel"
              },
              "Santarem": {
                "exemplarCity": "Santarem"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "Sao Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "Saint-Barthélemy"
              },
              "St_Johns": {
                "exemplarCity": "St. John’s"
              },
              "St_Kitts": {
                "exemplarCity": "Saint Kitts"
              },
              "St_Lucia": {
                "exemplarCity": "Saint Lucia"
              },
              "St_Thomas": {
                "exemplarCity": "Saint Thomas"
              },
              "St_Vincent": {
                "exemplarCity": "Saint Vincent"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Qaanaaq"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azory"
              },
              "Bermuda": {
                "exemplarCity": "Bermudy"
              },
              "Canary": {
                "exemplarCity": "Wyspy Kanaryjskie"
              },
              "Cape_Verde": {
                "exemplarCity": "Republika Zielonego Przylądka"
              },
              "Faeroe": {
                "exemplarCity": "Wyspy Owcze"
              },
              "Madeira": {
                "exemplarCity": "Madera"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavik"
              },
              "South_Georgia": {
                "exemplarCity": "Georgia Południowa"
              },
              "St_Helena": {
                "exemplarCity": "Święta Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andora"
              },
              "Astrakhan": {
                "exemplarCity": "Astrachań"
              },
              "Athens": {
                "exemplarCity": "Ateny"
              },
              "Belgrade": {
                "exemplarCity": "Belgrad"
              },
              "Berlin": {
                "exemplarCity": "Berlin"
              },
              "Bratislava": {
                "exemplarCity": "Bratysława"
              },
              "Brussels": {
                "exemplarCity": "Bruksela"
              },
              "Bucharest": {
                "exemplarCity": "Bukareszt"
              },
              "Budapest": {
                "exemplarCity": "Budapeszt"
              },
              "Busingen": {
                "exemplarCity": "Büsingen am Hochrhein"
              },
              "Chisinau": {
                "exemplarCity": "Kiszyniów"
              },
              "Copenhagen": {
                "exemplarCity": "Kopenhaga"
              },
              "Dublin": {
                "long": {
                  "daylight": "Irlandia (czas letni)"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsinki"
              },
              "Isle_of_Man": {
                "exemplarCity": "Wyspa Man"
              },
              "Istanbul": {
                "exemplarCity": "Stambuł"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrad"
              },
              "Kiev": {
                "exemplarCity": "Kijów"
              },
              "Kirov": {
                "exemplarCity": "Kirow"
              },
              "Lisbon": {
                "exemplarCity": "Lizbona"
              },
              "Ljubljana": {
                "exemplarCity": "Lublana"
              },
              "London": {
                "long": {
                  "daylight": "Brytyjski czas letni"
                },
                "exemplarCity": "Londyn"
              },
              "Luxembourg": {
                "exemplarCity": "Luksemburg"
              },
              "Madrid": {
                "exemplarCity": "Madryt"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Maarianhamina"
              },
              "Minsk": {
                "exemplarCity": "Mińsk"
              },
              "Monaco": {
                "exemplarCity": "Monako"
              },
              "Moscow": {
                "exemplarCity": "Moskwa"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Paryż"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Praga"
              },
              "Riga": {
                "exemplarCity": "Ryga"
              },
              "Rome": {
                "exemplarCity": "Rzym"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajewo"
              },
              "Saratov": {
                "exemplarCity": "Saratów"
              },
              "Simferopol": {
                "exemplarCity": "Symferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sofia"
              },
              "Stockholm": {
                "exemplarCity": "Sztokholm"
              },
              "Tallinn": {
                "exemplarCity": "Tallin"
              },
              "Tirane": {
                "exemplarCity": "Tirana"
              },
              "Ulyanovsk": {
                "exemplarCity": "Uljanowsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Użgorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Watykan"
              },
              "Vienna": {
                "exemplarCity": "Wiedeń"
              },
              "Vilnius": {
                "exemplarCity": "Wilno"
              },
              "Volgograd": {
                "exemplarCity": "Wołgograd"
              },
              "Warsaw": {
                "exemplarCity": "Warszawa"
              },
              "Zagreb": {
                "exemplarCity": "Zagrzeb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporoże"
              },
              "Zurich": {
                "exemplarCity": "Zurych"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidżan"
              },
              "Accra": {
                "exemplarCity": "Akra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Addis Abeba"
              },
              "Algiers": {
                "exemplarCity": "Algier"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangi"
              },
              "Banjul": {
                "exemplarCity": "Bandżul"
              },
              "Bissau": {
                "exemplarCity": "Bissau"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bużumbura"
              },
              "Cairo": {
                "exemplarCity": "Kair"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Konakry"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Dżibuti"
              },
              "Douala": {
                "exemplarCity": "Duala"
              },
              "El_Aaiun": {
                "exemplarCity": "Al-Ujun"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johannesburg"
              },
              "Juba": {
                "exemplarCity": "Dżuba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Chartum"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinszasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lomé"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadiszu"
              },
              "Monrovia": {
                "exemplarCity": "Monrovia"
              },
              "Nairobi": {
                "exemplarCity": "Nairobi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndżamena"
              },
              "Niamey": {
                "exemplarCity": "Niamey"
              },
              "Nouakchott": {
                "exemplarCity": "Nawakszut"
              },
              "Ouagadougou": {
                "exemplarCity": "Wagadugu"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "São Tomé"
              },
              "Tripoli": {
                "exemplarCity": "Trypolis"
              },
              "Tunis": {
                "exemplarCity": "Tunis"
              },
              "Windhoek": {
                "exemplarCity": "Windhuk"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Ałmaty"
              },
              "Amman": {
                "exemplarCity": "Amman"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aktau"
              },
              "Aqtobe": {
                "exemplarCity": "Aktiubińsk"
              },
              "Ashgabat": {
                "exemplarCity": "Aszchabad"
              },
              "Atyrau": {
                "exemplarCity": "Atyrau"
              },
              "Baghdad": {
                "exemplarCity": "Bagdad"
              },
              "Bahrain": {
                "exemplarCity": "Bahrajn"
              },
              "Baku": {
                "exemplarCity": "Baku"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnauł"
              },
              "Beirut": {
                "exemplarCity": "Bejrut"
              },
              "Bishkek": {
                "exemplarCity": "Biszkek"
              },
              "Brunei": {
                "exemplarCity": "Brunei"
              },
              "Calcutta": {
                "exemplarCity": "Kalkuta"
              },
              "Chita": {
                "exemplarCity": "Czyta"
              },
              "Choibalsan": {
                "exemplarCity": "Czojbalsan"
              },
              "Colombo": {
                "exemplarCity": "Kolombo"
              },
              "Damascus": {
                "exemplarCity": "Damaszek"
              },
              "Dhaka": {
                "exemplarCity": "Dhaka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubaj"
              },
              "Dushanbe": {
                "exemplarCity": "Duszanbe"
              },
              "Famagusta": {
                "exemplarCity": "Famagusta"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hongkong"
              },
              "Hovd": {
                "exemplarCity": "Kobdo"
              },
              "Irkutsk": {
                "exemplarCity": "Irkuck"
              },
              "Jakarta": {
                "exemplarCity": "Dżakarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jerozolima"
              },
              "Kabul": {
                "exemplarCity": "Kabul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamczatka"
              },
              "Karachi": {
                "exemplarCity": "Karaczi"
              },
              "Katmandu": {
                "exemplarCity": "Katmandu"
              },
              "Khandyga": {
                "exemplarCity": "Chandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnojarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lumpur"
              },
              "Kuching": {
                "exemplarCity": "Kuching"
              },
              "Kuwait": {
                "exemplarCity": "Kuwejt"
              },
              "Macau": {
                "exemplarCity": "Makau"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makassar"
              },
              "Manila": {
                "exemplarCity": "Manila"
              },
              "Muscat": {
                "exemplarCity": "Maskat"
              },
              "Nicosia": {
                "exemplarCity": "Nikozja"
              },
              "Novokuznetsk": {
                "exemplarCity": "Nowokuźnieck"
              },
              "Novosibirsk": {
                "exemplarCity": "Nowosybirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Uralsk"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnom Penh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pjongjang"
              },
              "Qatar": {
                "exemplarCity": "Katar"
              },
              "Qostanay": {
                "exemplarCity": "Kustanaj"
              },
              "Qyzylorda": {
                "exemplarCity": "Kyzyłorda"
              },
              "Rangoon": {
                "exemplarCity": "Rangun"
              },
              "Riyadh": {
                "exemplarCity": "Rijad"
              },
              "Saigon": {
                "exemplarCity": "Ho Chi Minh"
              },
              "Sakhalin": {
                "exemplarCity": "Sachalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkanda"
              },
              "Seoul": {
                "exemplarCity": "Seul"
              },
              "Shanghai": {
                "exemplarCity": "Szanghaj"
              },
              "Singapore": {
                "exemplarCity": "Singapur"
              },
              "Srednekolymsk": {
                "exemplarCity": "Sriedniekołymsk"
              },
              "Taipei": {
                "exemplarCity": "Tajpej"
              },
              "Tashkent": {
                "exemplarCity": "Taszkient"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teheran"
              },
              "Thimphu": {
                "exemplarCity": "Thimphu"
              },
              "Tokyo": {
                "exemplarCity": "Tokio"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ułan Bator"
              },
              "Urumqi": {
                "exemplarCity": "Urumczi"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Niera"
              },
              "Vientiane": {
                "exemplarCity": "Wientian"
              },
              "Vladivostok": {
                "exemplarCity": "Władywostok"
              },
              "Yakutsk": {
                "exemplarCity": "Jakuck"
              },
              "Yekaterinburg": {
                "exemplarCity": "Jekaterynburg"
              },
              "Yerevan": {
                "exemplarCity": "Erywań"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarywa"
              },
              "Chagos": {
                "exemplarCity": "Czagos"
              },
              "Christmas": {
                "exemplarCity": "Wyspa Bożego Narodzenia"
              },
              "Cocos": {
                "exemplarCity": "Wyspy Kokosowe"
              },
              "Comoro": {
                "exemplarCity": "Komory"
              },
              "Kerguelen": {
                "exemplarCity": "Wyspy Kerguelena"
              },
              "Mahe": {
                "exemplarCity": "Mahé"
              },
              "Maldives": {
                "exemplarCity": "Malediwy"
              },
              "Mauritius": {
                "exemplarCity": "Mauritius"
              },
              "Mayotte": {
                "exemplarCity": "Majotta"
              },
              "Reunion": {
                "exemplarCity": "Réunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Wyspa Bougainville’a"
              },
              "Chatham": {
                "exemplarCity": "Chatham"
              },
              "Easter": {
                "exemplarCity": "Wyspa Wielkanocna"
              },
              "Efate": {
                "exemplarCity": "Efate"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fidżi"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galapagos"
              },
              "Gambier": {
                "exemplarCity": "Wyspy Gambiera"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Johnston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Markizy"
              },
              "Midway": {
                "exemplarCity": "Midway"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Numea"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairn"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Tahiti"
              },
              "Tarawa": {
                "exemplarCity": "Tarawa"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuuk"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Syowa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Wostok"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "uniwersalny czas koordynowany"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "Nieznane miasto"
              }
            }
          },
          "metazone": {
            "Afghanistan": {
              "long": {
                "standard": "Afganistan"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "czas środkowoafrykański"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "czas wschodnioafrykański"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "czas południowoafrykański"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "czas zachodnioafrykański",
                "standard": "czas zachodnioafrykański standardowy",
                "daylight": "czas zachodnioafrykański letni"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Alaska",
                "standard": "Alaska (czas standardowy)",
                "daylight": "Alaska (czas letni)"
              }
            },
            "Amazon": {
              "long": {
                "generic": "czas amazoński",
                "standard": "czas amazoński standardowy",
                "daylight": "czas amazoński letni"
              }
            },
            "America_Central": {
              "long": {
                "generic": "czas środkowoamerykański",
                "standard": "czas środkowoamerykański standardowy",
                "daylight": "czas środkowoamerykański letni"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "czas wschodnioamerykański",
                "standard": "czas wschodnioamerykański standardowy",
                "daylight": "czas wschodnioamerykański letni"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "czas górski",
                "standard": "czas górski standardowy",
                "daylight": "czas górski letni"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "czas pacyficzny",
                "standard": "czas pacyficzny standardowy",
                "daylight": "czas pacyficzny letni"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "czas Anadyr",
                "standard": "czas standardowy Anadyr",
                "daylight": "czas Anadyr letni"
              }
            },
            "Apia": {
              "long": {
                "generic": "Apia",
                "standard": "Apia (czas standardowy)",
                "daylight": "Apia (czas letni)"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Półwysep Arabski",
                "standard": "Półwysep Arabski (czas standardowy)",
                "daylight": "Półwysep Arabski (czas letni)"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Argentyna",
                "standard": "Argentyna (czas standardowy)",
                "daylight": "Argentyna (czas letni)"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "Argentyna Zachodnia",
                "standard": "Argentyna Zachodnia (czas standardowy)",
                "daylight": "Argentyna Zachodnia (czas letni)"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Armenia",
                "standard": "Armenia (czas standardowy)",
                "daylight": "Armenia (czas letni)"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "czas atlantycki",
                "standard": "czas atlantycki standardowy",
                "daylight": "czas atlantycki letni"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "czas środkowoaustralijski",
                "standard": "czas środkowoaustralijski standardowy",
                "daylight": "czas środkowoaustralijski letni"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "czas środkowo-zachodnioaustralijski",
                "standard": "czas środkowo-zachodnioaustralijski standardowy",
                "daylight": "czas środkowo-zachodnioaustralijski letni"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "czas wschodnioaustralijski",
                "standard": "czas wschodnioaustralijski standardowy",
                "daylight": "czas wschodnioaustralijski letni"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "czas zachodnioaustralijski",
                "standard": "czas zachodnioaustralijski standardowy",
                "daylight": "czas zachodnioaustralijski letni"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Azerbejdżan",
                "standard": "Azerbejdżan (czas standardowy)",
                "daylight": "Azerbejdżan (czas letni)"
              }
            },
            "Azores": {
              "long": {
                "generic": "Azory",
                "standard": "Azory (czas standardowy)",
                "daylight": "Azory (czas letni)"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Bangladesz",
                "standard": "Bangladesz (czas standardowy)",
                "daylight": "Bangladesz (czas letni)"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Bhutan"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Boliwia"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Brasília",
                "standard": "Brasília (czas standardowy)",
                "daylight": "Brasília (czas letni)"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Brunei"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Wyspy Zielonego Przylądka",
                "standard": "Wyspy Zielonego Przylądka (czas standardowy)",
                "daylight": "Wyspy Zielonego Przylądka (czas letni)"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Czamorro"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Chatham",
                "standard": "Chatham (czas standardowy)",
                "daylight": "Chatham (czas letni)"
              }
            },
            "Chile": {
              "long": {
                "generic": "Chile",
                "standard": "Chile (czas standardowy)",
                "daylight": "Chile (czas letni)"
              }
            },
            "China": {
              "long": {
                "generic": "Chiny",
                "standard": "Chiny (czas standardowy)",
                "daylight": "Chiny (czas letni)"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Czojbalsan",
                "standard": "Czojbalsan (czas standardowy)",
                "daylight": "Czojbalsan (czas letni)"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Wyspa Bożego Narodzenia"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Wyspy Kokosowe"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Kolumbia",
                "standard": "Kolumbia (czas standardowy)",
                "daylight": "Kolumbia (czas letni)"
              }
            },
            "Cook": {
              "long": {
                "generic": "Wyspy Cooka",
                "standard": "Wyspy Cooka (czas standardowy)",
                "daylight": "Wyspy Cooka (czas letni)"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Kuba",
                "standard": "Kuba (czas standardowy)",
                "daylight": "Kuba (czas letni)"
              }
            },
            "Davis": {
              "long": {
                "standard": "Davis"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Dumont-d’Urville"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "Timor Wschodni"
              }
            },
            "Easter": {
              "long": {
                "generic": "Wyspa Wielkanocna",
                "standard": "Wyspa Wielkanocna (czas standardowy)",
                "daylight": "Wyspa Wielkanocna (czas letni)"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Ekwador"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "czas środkowoeuropejski",
                "standard": "czas środkowoeuropejski standardowy",
                "daylight": "czas środkowoeuropejski letni"
              },
              "short": {
                "generic": "CET",
                "standard": "CET",
                "daylight": "CEST"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "czas wschodnioeuropejski",
                "standard": "czas wschodnioeuropejski standardowy",
                "daylight": "czas wschodnioeuropejski letni"
              },
              "short": {
                "generic": "EET",
                "standard": "EET",
                "daylight": "EEST"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "czas wschodnioeuropejski dalszy"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "czas zachodnioeuropejski",
                "standard": "czas zachodnioeuropejski standardowy",
                "daylight": "czas zachodnioeuropejski letni"
              },
              "short": {
                "generic": "WET",
                "standard": "WET",
                "daylight": "WEST"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Falklandy",
                "standard": "Falklandy (czas standardowy)",
                "daylight": "Falklandy (czas letni)"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fidżi",
                "standard": "Fidżi (czas standardowy)",
                "daylight": "Fidżi (czas letni)"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Gujana Francuska"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Francuskie Terytoria Południowe i Antarktyczne"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galapagos"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Wyspy Gambiera"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Gruzja",
                "standard": "Gruzja (czas standardowy)",
                "daylight": "Gruzja (czas letni)"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Wyspy Gilberta"
              }
            },
            "GMT": {
              "long": {
                "standard": "czas uniwersalny"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "Grenlandia Wschodnia",
                "standard": "Grenlandia Wschodnia (czas standardowy)",
                "daylight": "Grenlandia Wschodnia (czas letni)"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "Grenlandia Zachodnia",
                "standard": "Grenlandia Zachodnia (czas standardowy)",
                "daylight": "Grenlandia Zachodnia (czas letni)"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Zatoka Perska"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Gujana"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Hawaje-Aleuty",
                "standard": "Hawaje-Aleuty (czas standardowy)",
                "daylight": "Hawaje-Aleuty (czas letni)"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hongkong",
                "standard": "Hongkong (czas standardowy)",
                "daylight": "Hongkong (czas letni)"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Kobdo",
                "standard": "Kobdo (czas standardowy)",
                "daylight": "Kobdo (czas letni)"
              }
            },
            "India": {
              "long": {
                "standard": "czas indyjski standardowy"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Ocean Indyjski"
              }
            },
            "Indochina": {
              "long": {
                "standard": "czas indochiński"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Indonezja Środkowa"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Indonezja Wschodnia"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "Indonezja Zachodnia"
              }
            },
            "Iran": {
              "long": {
                "generic": "Iran",
                "standard": "Iran (czas standardowy)",
                "daylight": "Iran (czas letni)"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Irkuck",
                "standard": "Irkuck (czas standardowy)",
                "daylight": "Irkuck (czas letni)"
              }
            },
            "Israel": {
              "long": {
                "generic": "Izrael",
                "standard": "Izrael (czas standardowy)",
                "daylight": "Izrael (czas letni)"
              }
            },
            "Japan": {
              "long": {
                "generic": "Japonia",
                "standard": "Japonia (czas standardowy)",
                "daylight": "Japonia (czas letni)"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "czas Pietropawłowsk Kamczacki",
                "standard": "czas standardowy Pietropawłowsk Kamczacki",
                "daylight": "czas Pietropawłowsk Kamczacki letni"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "Kazachstan Wschodni"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "Kazachstan Zachodni"
              }
            },
            "Korea": {
              "long": {
                "generic": "Korea",
                "standard": "Korea (czas standardowy)",
                "daylight": "Korea (czas letni)"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Kosrae"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Krasnojarsk",
                "standard": "Krasnojarsk (czas standardowy)",
                "daylight": "Krasnojarsk (czas letni)"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Kirgistan"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Line Islands"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Lord Howe",
                "standard": "Lord Howe (czas standardowy)",
                "daylight": "Lord Howe (czas letni)"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Macquarie"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Magadan",
                "standard": "Magadan (czas standardowy)",
                "daylight": "Magadan (czas letni)"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Malezja"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Malediwy"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Markizy"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Wyspy Marshalla"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Mauritius",
                "standard": "Mauritius (czas standardowy)",
                "daylight": "Mauritius (czas letni)"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Mawson"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Meksyk Północno-Zachodni",
                "standard": "Meksyk Północno-Zachodni (czas standardowy)",
                "daylight": "Meksyk Północno-Zachodni (czas letni)"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Meksyk (czas pacyficzny)",
                "standard": "Meksyk (czas pacyficzny standardowy)",
                "daylight": "Meksyk (czas pacyficzny letni)"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Ułan Bator",
                "standard": "Ułan Bator (czas standardowy)",
                "daylight": "Ułan Bator (czas letni)"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moskwa",
                "standard": "Moskwa (czas standardowy)",
                "daylight": "Moskwa (czas letni)"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Mjanma"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Nauru"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Nepal"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Nowa Kaledonia",
                "standard": "Nowa Kaledonia (czas standardowy)",
                "daylight": "Nowa Kaledonia (czas letni)"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "Nowa Zelandia",
                "standard": "Nowa Zelandia (czas standardowy)",
                "daylight": "Nowa Zelandia (czas letni)"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Nowa Fundlandia",
                "standard": "Nowa Fundlandia (czas standardowy)",
                "daylight": "Nowa Fundlandia (czas letni)"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niue"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Norfolk"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Fernando de Noronha",
                "standard": "Fernando de Noronha (czas standardowy)",
                "daylight": "Fernando de Noronha (czas letni)"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Nowosybirsk",
                "standard": "Nowosybirsk (czas standardowy)",
                "daylight": "Nowosybirsk (czas letni)"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Omsk",
                "standard": "Omsk (czas standardowy)",
                "daylight": "Omsk (czas letni)"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Pakistan",
                "standard": "Pakistan (czas standardowy)",
                "daylight": "Pakistan (czas letni)"
              }
            },
            "Palau": {
              "long": {
                "standard": "Palau"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Papua-Nowa Gwinea"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Paragwaj",
                "standard": "Paragwaj (czas standardowy)",
                "daylight": "Paragwaj (czas letni)"
              }
            },
            "Peru": {
              "long": {
                "generic": "Peru",
                "standard": "Peru (czas standardowy)",
                "daylight": "Peru (czas letni)"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Filipiny",
                "standard": "Filipiny (czas standardowy)",
                "daylight": "Filipiny (czas letni)"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Feniks"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "Saint-Pierre i Miquelon",
                "standard": "Saint-Pierre i Miquelon (czas standardowy)",
                "daylight": "Saint-Pierre i Miquelon (czas letni)"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Pitcairn"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Pohnpei"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Pjongjang"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Reunion"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Rothera"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Sachalin",
                "standard": "Sachalin (czas standardowy)",
                "daylight": "Sachalin (czas letni)"
              }
            },
            "Samara": {
              "long": {
                "generic": "czas Samara",
                "standard": "czas standardowy Samara",
                "daylight": "czas Samara letni"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Samoa",
                "standard": "Samoa (czas standardowy)",
                "daylight": "Samoa (czas letni)"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Seszele"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singapur"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Wyspy Salomona"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "Georgia Południowa"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Surinam"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Syowa"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahiti"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Tajpej",
                "standard": "Tajpej (czas standardowy)",
                "daylight": "Tajpej (czas letni)"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tadżykistan"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tokelau"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tonga",
                "standard": "Tonga (czas standardowy)",
                "daylight": "Tonga (czas letni)"
              }
            },
            "Truk": {
              "long": {
                "standard": "Chuuk"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Turkmenistan",
                "standard": "Turkmenistan (czas standardowy)",
                "daylight": "Turkmenistan (czas letni)"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Tuvalu"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Urugwaj",
                "standard": "Urugwaj (czas standardowy)",
                "daylight": "Urugwaj (czas letni)"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Uzbekistan",
                "standard": "Uzbekistan (czas standardowy)",
                "daylight": "Uzbekistan (czas letni)"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanuatu",
                "standard": "Vanuatu (czas standardowy)",
                "daylight": "Vanuatu (czas letni)"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Wenezuela"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Władywostok",
                "standard": "Władywostok (czas standardowy)",
                "daylight": "Władywostok (czas letni)"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Wołgograd",
                "standard": "Wołgograd (czas standardowy)",
                "daylight": "Wołgograd (czas letni)"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Wostok"
              }
            },
            "Wake": {
              "long": {
                "standard": "Wake"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Wallis i Futuna"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Jakuck",
                "standard": "Jakuck (czas standardowy)",
                "daylight": "Jakuck (czas letni)"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Jekaterynburg",
                "standard": "Jekaterynburg (czas standardowy)",
                "daylight": "Jekaterynburg (czas letni)"
              }
            }
          }
        }
      }
    }
  }
}
