/* angular:styles/component:css;ec3f5d00b2d3f89d5dca606c628ccf25ba8a2900b8621943a7cd38e6f9b741c7;/Users/admin/Desktop/jusnote/jusnote-2-front/src/app/commonComponents/custom-toaster/custom-toaster.component.ts */
:host {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 10px 10px 10px 10px;
  color: #ffffff;
  pointer-events: all;
  cursor: pointer;
}
/*# sourceMappingURL=custom-toaster.component.css.map */
