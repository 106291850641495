<div class="modal-template">
  <div class="modal-container">
    <div class="modal-header">
      <div class="title-text">{{ 'sidebar.calendar' | translate }}</div>
    </div>
    <div class="modal-body">
      <div class="content-container">
        <h4 class="task-about">{{ task.name }}<span *ngIf="task.description">,</span> {{ task.description }}</h4>
        <div class="time-container">
          <p class="time">
            {{ task.type === 'task' ? (task.startDate | date: 'dd.MM.yyyy') : (task.startDate | date: 'dd.MM.yyyy, HH:mm') }}
          </p>
          <div class="separator-container">
            <span class="j-icon-date-separator"></span>
          </div>
          <p class="time">{{ task.type === 'task' ? (task.endDate | date: 'dd.MM.yyyy') : (task.endDate | date: 'dd.MM.yyyy, HH:mm') }}</p>
        </div>
        <div *ngIf="task.type === 'event'">
          <div class="events-item" *ngIf="task.court; else elseBlock">
            <input type="checkbox" id="type" checked disabled />
            <label for="type"></label>
            {{ 'calendar.taskInfo.court' | translate }}
          </div>
          <ng-template #elseBlock>
            <div class="events-item">
              <input type="checkbox" id="type" disabled />
              <label for="type"></label>
              {{ 'calendar.taskInfo.court' | translate }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="hr-line"></div>
    <div class="modal-footer">
      <div class="active-block">
        <div class="more-info" (click)="openTaskInfo(task.id)">
          <a>{{ 'button.view' | translate }}</a>
        </div>
        <div class="buttons-container">
          <div class="button-delete" (click)="deleteTask(task)">
            <a><span class="j2-icon-remove"></span>{{ 'button.delete' | translate }}</a>
          </div>
          <div class="button-edit" (click)="editTask(task)">
            <a><span class="j-icon-edit"></span>{{ 'button.edit' | translate }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
