import { Component, Inject, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription, Subject, take } from 'rxjs';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { CreateWorkGroup, RemoveWorkGroup, UpdateWorkGroup } from '@app/modules/settings/store/actions/team.actions';
import { TeamEffects } from '@app/modules/settings/store/effects/team.effects';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { environment } from '@environments/environment';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { TeamMemberService } from '@app/modules/settings/services/team-member.service';
import { ComboBox, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { TeamMemberFilter } from '@app/models/interfaces/team-member-filter';
import { IUser } from '@app/models';
import { EmitType } from '@syncfusion/ej2-base';
import { TranslateService } from '@ngx-translate/core';
import { DebounceService } from '../../../../helpers/debounce.service';

@Component({
    selector: 'app-group-create-modal',
    templateUrl: './group-create-modal.component.html',
    styleUrls: ['./group-create-modal.component.scss'],
    standalone: false
})
export class GroupCreateModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<GroupCreateModalComponent>,
    private store: Store<IAppState>,
    private teamEffects: TeamEffects,
    private formBuilder: UntypedFormBuilder,
    private userService: TeamMemberService,
    private dialog: MatDialog,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private debounceService: DebounceService
  ) {
    this.subscription.add(this.teamEffects.createWorkGroup$.subscribe(() => this.closeModal()));
    this.subscription.add(this.teamEffects.updateWorkGroup$.subscribe(() => this.closeModal()));
  }

  get f() {
    return this.groupForm;
  }
  private subscription = new Subscription();
  private createdSubscription: Subscription;
  submittedGroupForm = false;
  maxInputNameLength = 254;
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  usersArray: IUser[];
  groupForm: UntypedFormGroup;
  checkClickSubmit = false;

  public sorting = 'Ascending';
  filtersUser: TeamMemberFilter = { status: 'active', 'companyAccesses.enable': true };
  @ViewChild('usersComboBox', { static: false })
  public usersComboBox: ComboBox;

  @ViewChild('search', { static: false }) inputEl: ElementRef;
  searchStringSubject = new Subject();


  ngOnInit() {
    this.createForm();
    this.loadUsers();

    this.createdSubscription = this.subscribeToEffects();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.createdSubscription.unsubscribe();
    this.debounceService.removeDebounceData();
  }

  closeModal() {
    this.dialogRef.close();
  }

  removeGroup(id) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.panelClass = 'default-mat-dialog';

    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteGroupText'
    };

    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.store.dispatch(new RemoveWorkGroup(id));
      } else {
        dialogRef.close();
      }
    });
  }
  onSubmit(draft = false) {
    this.submittedGroupForm = true;
    if (this.groupForm.valid && !this.checkClickSubmit) {
      this.checkClickSubmit = true;
      const payload = this.groupForm?.value;
      if (this.dialogData.id) {
        if (draft) {
          payload['status'] = this.dialogData.status === 'enabled' ? 'disabled' : 'enabled';
          payload['currentAction'] =  this.dialogData.status === 'enabled' ? 'returnedFromTheArchive' : 'addedToTheArchive';
        }
        this.store.dispatch(new UpdateWorkGroup(this.dialogData.id, payload));
      } else {
        this.store.dispatch(new CreateWorkGroup(this.groupForm?.value));
      }
    }
  }

  private createForm() {
    this.groupForm = this.formBuilder.group({
      isDefault: false,
      name: this.controlCustomValidators(this.dialogData.name ? this.dialogData.name : null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(this.maxInputNameLength)
      ]),
      users: this.control([], true)
    });

    if (this.dialogData.matter) {
      this.groupForm.get('name').setValue(`${this.translate.instant('idMatter')}: ${this.dialogData.matter.id}`);
      this.groupForm.get('name').disable();
    }

  }

  private subscribeToEffects(): Subscription {
    const subscription = new Subscription();
    const actionAfterUpdate = worker => {
      this.closeModal();
    };

    subscription.add(this.teamEffects.removeWorkGroup$.subscribe(actionAfterUpdate));

    return subscription;
  }

  private control(value, optional = false): UntypedFormControl {
    return this.formBuilder.control(value, optional ? [] : Validators.required);
  }

  private controlCustomValidators(value, rules = []): UntypedFormControl {
    return this.formBuilder.control(value, rules);
  }

  loadUsers() {
    this.userService.get(environment.defaultPage, 10000, this.filtersUser).pipe(take(1)).subscribe(x => {
      this.usersArray = x.member;
      this.groupForm.get('users').setValue(this.dialogData.users ? this.dialogData.users.map(item => item.id) : []);
      setTimeout(() => {
        this.selectUser();
      });
    });
  }

  public filterUsers: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.usersArray as any);
    } else {
      const filteringData = [];
      this.usersArray.forEach(i => {
        if (i.person.fullName.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  }


  fixPopUp(e, selectName) {
    if (!(e['target'] && e['target']['className'] === 'e-dropdownbase')) {
      this[selectName]?.hidePopup();
    }
  }

  selectUser() {
    setTimeout(() => {
      const multiselectInput = document.querySelector('.group-fields .e-dropdownbase');
      multiselectInput.setAttribute('placeholder', this.translate.instant('common.select'));

      const selectedUserList = document.querySelector('.group-fields .e-chips-collection') as HTMLDivElement;
      const modalBody = document.querySelector('.group-fields') as HTMLDivElement;
      const hList = modalBody.offsetHeight - (156 + 70 + 29);
      selectedUserList.style.height = hList + 'px';
    }, 10);
  }
}
