<div class="modal-template wide-modal group-fields">
  <form [formGroup]="groupForm" *ngIf="groupForm" (submit)="onSubmit()">
    <div class="modal-container">
      <div class="modal-header">
        <div class="title-text">
          <span *ngIf="dialogData?.name; else defaultTitle">
            {{'groupEditing' | translate}}
          </span>
          <ng-template
          #defaultTitle
            >
            <span>{{ 'settings.groupsNew' | translate }}</span>
          </ng-template>
        </div>
        <div class="close-modal" (click)="closeModal()">
          <span class="j-icon-cross"></span>
        </div>
      </div>
      <div class="modal-body">

        <div class="group-settings-container">
          <div class="group-fields-container">
              <div class="group-fields-container-wrapper">
                <div class="input-container" [ngClass]="{ 'invalid-validation': f.get(['name']).errors && submittedGroupForm }">
                  <label class="group-name">
                    <span class="j2-icon-ellipse"></span>
                    {{ 'settings.groupsName' | translate }}<span class="required-validation">*</span>
                  </label>
                  <input
                    type="text"
                    formControlName="name"
                    placeholder="{{ 'common.typeHere' | translate }}"
                    minlength="3"
                    maxlength="{{ maxInputNameLength }}"
                    [ngClass]="{disabled: this.dialogData.matter}"
                  />

                </div>
                <div class="input-container person-multiselect">
                  <label class="custom-tooltip-activator">{{ 'settings.groupsTeamAdd' | translate }} </label>
                  <ejs-multiselect
                    #usersComboBox
                    [allowFiltering]="true"
                    (filtering)="filterUsers($event)"
                    [sortOrder]="sorting"
                    [dataSource]="usersArray"
                    [mode]="'Box'"
                    formControlName="users"
                    [fields]="{ text: 'person.fullName', value: 'id' }"
                    [placeholder]="'common.select' | translate"
                    [noRecordsTemplate]="'noRecord' | translate"
                    (valueChange)="selectUser(); fixPopUp($event, 'usersComboBox')"
                    (blur)="selectUser()"
                    (created)="selectUser()"
                    (close)="selectUser()"
                    (open)="fixPopUp($event, 'usersComboBox')"
                    (mousedown)="fixPopUp($event, 'usersComboBox')"
                    [ngStyle]="{'margin-bottom': (f.get(['users']).value['length'] * 33 + 11) + 'px'}"
                    >
                    </ejs-multiselect>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <div class="button-container" *ngIf="dialogData?.id && !dialogData?.matter; else infoBlock">
              <div class="btn-attach">
                <span class="name" (click)="onSubmit(true)">
                  <span *ngIf="dialogData.status === 'enabled'; else statusDisabled">{{ 'toArchive' | translate }}</span>
                  <ng-template #statusDisabled>
                    {{'returnFromArchive' | translate}}
                  </ng-template>
                </span>
                <div class="icon-container" *ngIf="!dialogData.matter">
                  <i class="j-icon-arrow-down-thin"></i>
                  <div class="empty-drop-down"></div>
                  <div class="dropdown-items">
                    <div class="item-dropdown" (click)="removeGroup(dialogData?.id)">
                      <i class="j2-icon-remove"></i>
                      {{ 'deleteGroup' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #infoBlock>
              <div class="button-container">
                <p class="text-container" [innerHTML]="'moreAboutGroupsReadHere' | translate"></p>
              </div>
            </ng-template>
            <div class="button-container">
              <button class="btn-template btn-blue dropdown-button">
                {{ 'button.save' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
