/* src/app/commonComponents/modals/other-modals/tracker-tutorial-modal/tracker-tutorial-modal.component.scss */
.modal-template .modal-container {
  width: 790px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-template .modal-container .slider-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.modal-template .modal-container .slider-container .slider-item {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  left: -790px;
  z-index: 0;
  transition: 0.3s ease;
}
.modal-template .modal-container .slider-container .slider-item.slide1 {
  left: 0;
  z-index: 3;
}
.modal-template .modal-container .slider-container .slider-item.slide2 {
  left: 790px;
  z-index: 0;
}
.modal-template .modal-container .slider-container .slider-item .modal-header-image {
  position: relative;
  height: 311px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.modal-template .modal-container .slider-container .slider-item .modal-header-image .close-modal {
  position: absolute;
  top: 14px;
  right: 21px;
  cursor: pointer;
}
.modal-template .modal-container .slider-container .slider-item .modal-header-image .close-modal span {
  font-size: 14px;
  color: #ffffff;
}
.modal-template .modal-container .slider-container .slider-item .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  margin: 48px 145px 0 145px;
  height: 150px;
}
.modal-template .modal-container .slider-container .slider-item .text .slide-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.modal-template .modal-container .slider-container .slider-item .text .slide-description {
  text-align: center;
}
.modal-template .modal-container .btn-next {
  width: 120px;
  display: flex;
  justify-content: center;
  margin: 0;
}
.modal-template .modal-container .dots-container {
  margin-top: 17px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-template .modal-container .dots-container .dots-item {
  width: 6px;
  height: 6px;
  background-color: #f0f0f0;
  border-radius: 50%;
  margin: 0 2px;
  transition: 0.3s ease;
}
.modal-template .modal-container .dots-container .dots-item.active {
  background-color: #1252af;
}
/*# sourceMappingURL=tracker-tutorial-modal.component.css.map */
