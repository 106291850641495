{
  "main": {
    "uk": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "uk"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "січ.",
                  "2": "лют.",
                  "3": "бер.",
                  "4": "квіт.",
                  "5": "трав.",
                  "6": "черв.",
                  "7": "лип.",
                  "8": "серп.",
                  "9": "вер.",
                  "10": "жовт.",
                  "11": "лист.",
                  "12": "груд."
                },
                "narrow": {
                  "1": "с",
                  "2": "л",
                  "3": "б",
                  "4": "к",
                  "5": "т",
                  "6": "ч",
                  "7": "л",
                  "8": "с",
                  "9": "в",
                  "10": "ж",
                  "11": "л",
                  "12": "г"
                },
                "wide": {
                  "1": "січня",
                  "2": "лютого",
                  "3": "березня",
                  "4": "квітня",
                  "5": "травня",
                  "6": "червня",
                  "7": "липня",
                  "8": "серпня",
                  "9": "вересня",
                  "10": "жовтня",
                  "11": "листопада",
                  "12": "грудня"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "січ",
                  "2": "лют",
                  "3": "бер",
                  "4": "кві",
                  "5": "тра",
                  "6": "чер",
                  "7": "лип",
                  "8": "сер",
                  "9": "вер",
                  "10": "жов",
                  "11": "лис",
                  "12": "гру"
                },
                "narrow": {
                  "1": "С",
                  "2": "Л",
                  "3": "Б",
                  "4": "К",
                  "5": "Т",
                  "6": "Ч",
                  "7": "Л",
                  "8": "С",
                  "9": "В",
                  "10": "Ж",
                  "11": "Л",
                  "12": "Г"
                },
                "wide": {
                  "1": "січень",
                  "2": "лютий",
                  "3": "березень",
                  "4": "квітень",
                  "5": "травень",
                  "6": "червень",
                  "7": "липень",
                  "8": "серпень",
                  "9": "вересень",
                  "10": "жовтень",
                  "11": "листопад",
                  "12": "грудень"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "нд",
                  "mon": "пн",
                  "tue": "вт",
                  "wed": "ср",
                  "thu": "чт",
                  "fri": "пт",
                  "sat": "сб"
                },
                "narrow": {
                  "sun": "Н",
                  "mon": "П",
                  "tue": "В",
                  "wed": "С",
                  "thu": "Ч",
                  "fri": "П",
                  "sat": "С"
                },
                "short": {
                  "sun": "нд",
                  "mon": "пн",
                  "tue": "вт",
                  "wed": "ср",
                  "thu": "чт",
                  "fri": "пт",
                  "sat": "сб"
                },
                "wide": {
                  "sun": "неділя",
                  "mon": "понеділок",
                  "tue": "вівторок",
                  "wed": "середа",
                  "thu": "четвер",
                  "fri": "пʼятниця",
                  "sat": "субота"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "нд",
                  "mon": "пн",
                  "tue": "вт",
                  "wed": "ср",
                  "thu": "чт",
                  "fri": "пт",
                  "sat": "сб"
                },
                "narrow": {
                  "sun": "Н",
                  "mon": "П",
                  "tue": "В",
                  "wed": "С",
                  "thu": "Ч",
                  "fri": "П",
                  "sat": "С"
                },
                "short": {
                  "sun": "нд",
                  "mon": "пн",
                  "tue": "вт",
                  "wed": "ср",
                  "thu": "чт",
                  "fri": "пт",
                  "sat": "сб"
                },
                "wide": {
                  "sun": "неділя",
                  "mon": "понеділок",
                  "tue": "вівторок",
                  "wed": "середа",
                  "thu": "четвер",
                  "fri": "пʼятниця",
                  "sat": "субота"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "1-й кв.",
                  "2": "2-й кв.",
                  "3": "3-й кв.",
                  "4": "4-й кв."
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1-й квартал",
                  "2": "2-й квартал",
                  "3": "3-й квартал",
                  "4": "4-й квартал"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "1-й кв.",
                  "2": "2-й кв.",
                  "3": "3-й кв.",
                  "4": "4-й кв."
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1-й квартал",
                  "2": "2-й квартал",
                  "3": "3-й квартал",
                  "4": "4-й квартал"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "опівночі",
                  "am": "дп",
                  "noon": "пополудні",
                  "pm": "пп",
                  "morning1": "ранку",
                  "afternoon1": "дня",
                  "evening1": "вечора",
                  "night1": "ночі"
                },
                "narrow": {
                  "midnight": "північ",
                  "am": "дп",
                  "noon": "п",
                  "pm": "пп",
                  "morning1": "ранку",
                  "afternoon1": "дня",
                  "evening1": "вечора",
                  "night1": "ночі"
                },
                "wide": {
                  "midnight": "опівночі",
                  "am": "дп",
                  "noon": "пополудні",
                  "pm": "пп",
                  "morning1": "ранку",
                  "afternoon1": "дня",
                  "evening1": "вечора",
                  "night1": "ночі"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "північ",
                  "am": "дп",
                  "noon": "полудень",
                  "pm": "пп",
                  "morning1": "ранок",
                  "afternoon1": "день",
                  "evening1": "вечір",
                  "night1": "ніч"
                },
                "narrow": {
                  "midnight": "північ",
                  "am": "дп",
                  "noon": "полудень",
                  "pm": "пп",
                  "morning1": "ранок",
                  "afternoon1": "день",
                  "evening1": "вечір",
                  "night1": "ніч"
                },
                "wide": {
                  "midnight": "опівніч",
                  "am": "дп",
                  "noon": "полудень",
                  "pm": "пп",
                  "morning1": "ранок",
                  "afternoon1": "день",
                  "evening1": "вечір",
                  "night1": "ніч"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "до нашої ери",
                "0-alt-variant": "до нової ери",
                "1": "нашої ери",
                "1-alt-variant": "нової ери"
              },
              "eraAbbr": {
                "0": "до н. е.",
                "0-alt-variant": "до н. е.",
                "1": "н. е.",
                "1-alt-variant": "н. е."
              },
              "eraNarrow": {
                "0": "до н.е.",
                "0-alt-variant": "до н. е.",
                "1": "н.е.",
                "1-alt-variant": "н. е."
              }
            },
            "dateFormats": {
              "full": "EEEE, d MMMM y 'р'.",
              "long": "d MMMM y 'р'.",
              "medium": "d MMM y 'р'.",
              "short": "dd.MM.yy"
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} 'о' {0}",
              "long": "{1} 'о' {0}",
              "medium": "{1}, {0}",
              "short": "{1}, {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "h:mm B",
                "Bhms": "h:mm:ss B",
                "d": "d",
                "E": "ccc",
                "EBhm": "E h:mm B",
                "EBhms": "E h:mm:ss B",
                "Ed": "E, d",
                "Ehm": "E h:mm a",
                "EHm": "E HH:mm",
                "Ehms": "E h:mm:ss a",
                "EHms": "E HH:mm:ss",
                "Gy": "y G",
                "GyMMM": "LLL y G",
                "GyMMMd": "d MMM y G",
                "GyMMMEd": "E, d MMM y G",
                "h": "h a",
                "H": "HH",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "HH:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "HH:mm v",
                "M": "LL",
                "Md": "dd.MM",
                "MEd": "E, dd.MM",
                "MMM": "LLL",
                "MMMd": "d MMM",
                "MMMEd": "E, d MMM",
                "MMMMd": "d MMMM",
                "MMMMEd": "E, d MMMM",
                "MMMMW-count-one": "W-'й' 'тиж'. MMMM",
                "MMMMW-count-few": "W-'й' 'тиж'. MMMM",
                "MMMMW-count-many": "W-'й' 'тиж'. MMMM",
                "MMMMW-count-other": "W-'й' 'тиж'. MMMM",
                "ms": "mm:ss",
                "y": "y",
                "yM": "MM.y",
                "yMd": "dd.MM.y",
                "yMEd": "E, dd.MM.y",
                "yMMM": "LLL y",
                "yMMMd": "d MMM y",
                "yMMMEd": "E, d MMM y",
                "yMMMM": "LLLL y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y 'р'.",
                "yw-count-one": "w-'й' 'тиж'. Y 'р'.",
                "yw-count-few": "w-'й' 'тиж'. Y 'р'.",
                "yw-count-many": "w-'й' 'тиж'. Y 'р'.",
                "yw-count-other": "w-'й' 'тиж'. Y 'р'."
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "h B – h B",
                  "h": "h–h B"
                },
                "Bhm": {
                  "B": "h:mm B – h:mm B",
                  "h": "h:mm–h:mm B",
                  "m": "h:mm–h:mm B"
                },
                "d": {
                  "d": "d–d"
                },
                "Gy": {
                  "G": "y 'р'. G – y 'р'. G",
                  "y": "y–y 'рр'. G"
                },
                "GyM": {
                  "G": "MM.y G – MM.y G",
                  "M": "MM.y – MM.y G",
                  "y": "MM.y – MM.y G"
                },
                "GyMd": {
                  "d": "dd.MM.y – dd.MM.y G",
                  "G": "dd.MM.y G – dd.MM.y G",
                  "M": "dd.MM.y – dd.MM.y G",
                  "y": "dd.MM.y – dd.MM.y G"
                },
                "GyMEd": {
                  "d": "ccc, dd.MM.y – ccc, dd.MM.y G",
                  "G": "ccc, dd.MM.y G – ccc, dd.MM.y G",
                  "M": "ccc, dd.MM.y – ccc, dd.MM.y G",
                  "y": "ccc, dd.MM.y – ccc, dd.MM.y G"
                },
                "GyMMM": {
                  "G": "LLL y 'р'. G – LLL y 'р'. G",
                  "M": "LLL – LLL y 'р'. G",
                  "y": "LLL y – LLL y 'рр'. G"
                },
                "GyMMMd": {
                  "d": "d–d MMM y 'р'. G",
                  "G": "d MMM y 'р'. G – d MMM y 'р'. G",
                  "M": "d MMM – d MMM y 'р'. G",
                  "y": "d MMM y – d MMM y 'рр'. G"
                },
                "GyMMMEd": {
                  "d": "ccc, d MMM – ccc, d MMM y 'р'. G",
                  "G": "ccc, d MMM y 'р'. G – ccc, d MMM y 'р'. G",
                  "M": "ccc, d MMM – ccc, d MMM y 'р'. G",
                  "y": "ccc, d MMM y – ccc, d MMM y 'рр'. G"
                },
                "h": {
                  "a": "h a – h a",
                  "h": "h–h a"
                },
                "H": {
                  "H": "HH–HH"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm",
                  "m": "HH:mm–HH:mm"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm–HH:mm v",
                  "m": "HH:mm–HH:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "HH–HH v"
                },
                "M": {
                  "M": "M–M"
                },
                "Md": {
                  "d": "dd.MM – dd.MM",
                  "M": "dd.MM – dd.MM"
                },
                "MEd": {
                  "d": "E, dd.MM – E, dd.MM",
                  "M": "E, dd.MM – E, dd.MM"
                },
                "MMM": {
                  "M": "LLL–LLL"
                },
                "MMMd": {
                  "d": "d–d MMM",
                  "M": "d MMM – d MMM"
                },
                "MMMEd": {
                  "d": "E, d – E, d MMM",
                  "M": "E, d MMM – E, d MMM"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "MM.y – MM.y",
                  "y": "MM.y – MM.y"
                },
                "yMd": {
                  "d": "dd.MM.y – dd.MM.y",
                  "M": "dd.MM.y – dd.MM.y",
                  "y": "dd.MM.y – dd.MM.y"
                },
                "yMEd": {
                  "d": "E, dd.MM.y – E, dd.MM.y",
                  "M": "E, dd.MM.y – E, dd.MM.y",
                  "y": "E, dd.MM.y – E, dd.MM.y"
                },
                "yMMM": {
                  "M": "LLL–LLL y",
                  "y": "LLL y – LLL y"
                },
                "yMMMd": {
                  "d": "d–d MMM y",
                  "M": "d MMM – d MMM y",
                  "y": "d MMM y – d MMM y"
                },
                "yMMMEd": {
                  "d": "E, d – E, d MMM y",
                  "M": "E, d MMM – E, d MMM y",
                  "y": "E, d MMM y – E, d MMM y"
                },
                "yMMMM": {
                  "M": "LLLL – LLLL y",
                  "y": "LLLL y – LLLL y"
                }
              }
            }
          }
        }
      }
    }
  }
}
