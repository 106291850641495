/* src/app/commonComponents/subscription-left/subscription-left.component.scss */
.right-block_days {
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.right-block_days p {
  letter-spacing: 0.14px;
  color: #707B86;
  font-size: 12px;
  margin: 0;
}
.right-block_days a {
  cursor: pointer;
  color: #1252af;
}
/*# sourceMappingURL=subscription-left.component.css.map */
