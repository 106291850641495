/* src/app/commonComponents/modals/other-modals/create-automation-modal/create-automation-modal.component.scss */
.modal-container {
  min-width: 500px;
  max-width: 600px;
}
.modal-container .input-container {
  margin-top: 15px;
}
.modal-header {
  height: 47px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededed;
}
.modal-header h3 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 0 20px;
}
.modal-body {
  padding: 5px 20px 8px 20px;
}
.modal-body p {
  color: #222222;
  font-size: 14px;
  margin: 0;
}
.modal-footer .button-container {
  display: flex;
  justify-content: flex-end;
}
/*# sourceMappingURL=create-automation-modal.component.css.map */
