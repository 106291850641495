<div class="modal-transaction-template">
  <div class="modal-container">
    <div class="modal-title">
      <h3>{{ 'transfer' | translate }}</h3>
      <div class="close-modal" (click)="closeModal()">
        <span class="j-icon-cross"></span>
      </div>
    </div>
    <div class="modal-body">
      <div class="icon-container">
        <i class="j-icon-converter-icon"></i>
      </div>
      <div *ngIf="visibleMatterBlock && !visibleMatterText">
        <h4 class="custom-tooltip-activator">
          {{ 'transfer.prepayMatter' | translate }}
          <span class="j2-icon-info"></span>
          <div class="custom-tooltip-container custom-tooltip-bottom">
            <div class="tooltip-wrapper">
              <p class="tooltip-text">
                {{ 'transfer.toolTipPrepayMatter' | translate }}
              </p>
            </div>
          </div>
        </h4>
        <div class="transaction-content">
          <div class="transaction-col">
            <div class="number">
              <label>{{ currencyMatter }}:</label>
              <p>
                <span> {{ amountMatter | number: '1.2-2' }}</span>
                {{ currencyMatter }}
              </p>
            </div>
            <div class="sign-item">-</div>
          </div>
          <div class="transaction-input-col">
            <div class="input-container">
              <label>{{ 'transfer.rate' | translate }}:</label>
              <input type="text" [(ngModel)]="rateBillToMatter" (ngModelChange)="changeRateMatter($event)" />
            </div>
          </div>
          <div class="transaction-col">
            <div class="sign-item">=</div>
            <div class="number">
              <label>{{ currencyBill }}:</label>
              <p>{{ amountTransactionIsMatter | number: '1.2-2' }} {{ currencyBill }}</p>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div class="modal-body" *ngIf="visibleMatterBlock && visibleMatterText">
        <p>
          {{ 'transfer.getMatter' | translate }}
          <b
            >"{{ dialogData.currentBill.matter.name }}" {{ 'transfer.suma' | translate }} {{ amountTransactionIsMatter }}
            {{ dialogData.currentBill.matter.currencyBill.code }}
          </b>
        </p>
      </div>
      <div *ngIf="visibleClientBlock && !visibleClientText">
        <h4 class="custom-tooltip-activator">
          {{ 'transfer.prepayClient' | translate }}
          <span class="j2-icon-info"></span>
          <div class="custom-tooltip-container custom-tooltip-bottom">
            <div class="tooltip-wrapper">
              <p class="tooltip-text">
                {{ 'transfer.toolTipPrepayClient' | translate }}
              </p>
            </div>
          </div>
        </h4>
        <div class="transaction-content">
          <div class="transaction-col">
            <div class="number">
              <label>{{ currencyCompany }}:</label>
              <p>{{ amountClient | number: '1.2-2' }} {{ currencyCompany }}</p>
            </div>
            <div class="sign-item">-</div>
          </div>
          <div class="transaction-input-col">
            <div class="input-container">
              <label>{{ 'transfer.rate' | translate }}:</label>
              <!-- курс -->
              <input type="text" [(ngModel)]="rateBillToCompanySettings" (ngModelChange)="changeRateClient($event)" />
            </div>
          </div>
          <div class="transaction-col">
            <div class="sign-item">=</div>
            <div class="number">
              <!-- валюта біла -->
              <label>{{ currencyBill }}:</label>
              <p>{{ amountTransactionIsClient | number: '1.2-2' }} {{ currencyBill }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-body" *ngIf="visibleClientBlock && visibleClientText">
        <p>
          {{ 'transfer.payment' | translate }}
          <b>{{ amountTransactionIsClient | number: '1.2-2' }} {{ dialogData.currentCompanySettings.currency.code }}</b>
          {{ 'transfer.textPayment' | translate }}
        </p>
      </div>
      <div class="sum-container">
        <div class="sum-item">
          <p>{{ 'transfer.amount' | translate }}:</p>
          <!-- сума транзакції у тій валюті, на яку відбувається конвертація -->
          <p>{{ +(amountTransactionIsClient + amountTransactionIsMatter) | number: '1.2-2' }}{{ currencyBill }}</p>
        </div>
      </div>
    </div>

    <ng-container *ngIf="dialogData.showDateInput">
      <div class="input-container date" [ngClass]="{ 'invalid-validation': !dateTransfer && formSubmitted }">
        <label>{{ 'payment.date' | translate }}<span class="required-validation">*</span></label>
        <div class="datepicker-container">
          <ejs-datepicker
            #startDate
            [placeholder]="'common.select' | translate"
            [(ngModel)]="dateTransfer"
            [format]="dateFormat"
            [locale]="locale$ | async"
            [showTodayButton]="false"
          ></ejs-datepicker>
        </div>
      </div>
    </ng-container>

    <div class="button-container">
      <p [innerHTML]="'transfer.readMore' | translate"></p>
      <button class="btn-template btn-blue dropdown-button" (click)="onSubmit()">
        {{ 'button.apply' | translate }}
      </button>
    </div>
  </div>
</div>
