{
  "main": {
    "fr": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "fr"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "janv.",
                  "2": "févr.",
                  "3": "mars",
                  "4": "avr.",
                  "5": "mai",
                  "6": "juin",
                  "7": "juil.",
                  "8": "août",
                  "9": "sept.",
                  "10": "oct.",
                  "11": "nov.",
                  "12": "déc."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "janvier",
                  "2": "février",
                  "3": "mars",
                  "4": "avril",
                  "5": "mai",
                  "6": "juin",
                  "7": "juillet",
                  "8": "août",
                  "9": "septembre",
                  "10": "octobre",
                  "11": "novembre",
                  "12": "décembre"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "janv.",
                  "2": "févr.",
                  "3": "mars",
                  "4": "avr.",
                  "5": "mai",
                  "6": "juin",
                  "7": "juil.",
                  "8": "août",
                  "9": "sept.",
                  "10": "oct.",
                  "11": "nov.",
                  "12": "déc."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "janvier",
                  "2": "février",
                  "3": "mars",
                  "4": "avril",
                  "5": "mai",
                  "6": "juin",
                  "7": "juillet",
                  "8": "août",
                  "9": "septembre",
                  "10": "octobre",
                  "11": "novembre",
                  "12": "décembre"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "dim.",
                  "mon": "lun.",
                  "tue": "mar.",
                  "wed": "mer.",
                  "thu": "jeu.",
                  "fri": "ven.",
                  "sat": "sam."
                },
                "narrow": {
                  "sun": "D",
                  "mon": "L",
                  "tue": "M",
                  "wed": "M",
                  "thu": "J",
                  "fri": "V",
                  "sat": "S"
                },
                "short": {
                  "sun": "di",
                  "mon": "lu",
                  "tue": "ma",
                  "wed": "me",
                  "thu": "je",
                  "fri": "ve",
                  "sat": "sa"
                },
                "wide": {
                  "sun": "dimanche",
                  "mon": "lundi",
                  "tue": "mardi",
                  "wed": "mercredi",
                  "thu": "jeudi",
                  "fri": "vendredi",
                  "sat": "samedi"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "dim.",
                  "mon": "lun.",
                  "tue": "mar.",
                  "wed": "mer.",
                  "thu": "jeu.",
                  "fri": "ven.",
                  "sat": "sam."
                },
                "narrow": {
                  "sun": "D",
                  "mon": "L",
                  "tue": "M",
                  "wed": "M",
                  "thu": "J",
                  "fri": "V",
                  "sat": "S"
                },
                "short": {
                  "sun": "di",
                  "mon": "lu",
                  "tue": "ma",
                  "wed": "me",
                  "thu": "je",
                  "fri": "ve",
                  "sat": "sa"
                },
                "wide": {
                  "sun": "dimanche",
                  "mon": "lundi",
                  "tue": "mardi",
                  "wed": "mercredi",
                  "thu": "jeudi",
                  "fri": "vendredi",
                  "sat": "samedi"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "T1",
                  "2": "T2",
                  "3": "T3",
                  "4": "T4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1er trimestre",
                  "2": "2e trimestre",
                  "3": "3e trimestre",
                  "4": "4e trimestre"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "T1",
                  "2": "T2",
                  "3": "T3",
                  "4": "T4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1er trimestre",
                  "2": "2e trimestre",
                  "3": "3e trimestre",
                  "4": "4e trimestre"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "minuit",
                  "am": "AM",
                  "noon": "midi",
                  "pm": "PM",
                  "morning1": "mat.",
                  "afternoon1": "ap.m.",
                  "evening1": "soir",
                  "night1": "nuit"
                },
                "narrow": {
                  "midnight": "minuit",
                  "am": "AM",
                  "noon": "midi",
                  "pm": "PM",
                  "morning1": "mat.",
                  "afternoon1": "ap.m.",
                  "evening1": "soir",
                  "night1": "nuit"
                },
                "wide": {
                  "midnight": "minuit",
                  "am": "AM",
                  "noon": "midi",
                  "pm": "PM",
                  "morning1": "du matin",
                  "afternoon1": "de l’après-midi",
                  "evening1": "du soir",
                  "night1": "du matin"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "minuit",
                  "am": "AM",
                  "noon": "midi",
                  "pm": "PM",
                  "morning1": "mat.",
                  "afternoon1": "ap.m.",
                  "evening1": "soir",
                  "night1": "nuit"
                },
                "narrow": {
                  "midnight": "minuit",
                  "am": "AM",
                  "noon": "midi",
                  "pm": "PM",
                  "morning1": "mat.",
                  "afternoon1": "ap.m.",
                  "evening1": "soir",
                  "night1": "nuit"
                },
                "wide": {
                  "midnight": "minuit",
                  "am": "AM",
                  "noon": "midi",
                  "pm": "PM",
                  "morning1": "matin",
                  "afternoon1": "après-midi",
                  "evening1": "soir",
                  "night1": "nuit"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "avant Jésus-Christ",
                "0-alt-variant": "avant l’ère commune",
                "1": "après Jésus-Christ",
                "1-alt-variant": "de l’ère commune"
              },
              "eraAbbr": {
                "0": "av. J.-C.",
                "0-alt-variant": "AEC",
                "1": "ap. J.-C.",
                "1-alt-variant": "EC"
              },
              "eraNarrow": {
                "0": "av. J.-C.",
                "0-alt-variant": "AEC",
                "1": "ap. J.-C.",
                "1-alt-variant": "EC"
              }
            },
            "dateFormats": {
              "full": "EEEE d MMMM y",
              "long": "d MMMM y",
              "medium": "d MMM y",
              "short": "dd/MM/y"
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} 'à' {0}",
              "long": "{1} 'à' {0}",
              "medium": "{1} 'à' {0}",
              "short": "{1} {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "h:mm B",
                "Bhms": "h:mm:ss B",
                "d": "d",
                "E": "E",
                "EBhm": "E h:mm B",
                "EBhms": "E h:mm:ss B",
                "Ed": "E d",
                "Ehm": "E h:mm a",
                "EHm": "E HH:mm",
                "Ehms": "E h:mm:ss a",
                "EHms": "E HH:mm:ss",
                "Gy": "y G",
                "GyMMM": "MMM y G",
                "GyMMMd": "d MMM y G",
                "GyMMMEd": "E d MMM y G",
                "h": "h a",
                "H": "HH 'h'",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "HH:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "HH:mm v",
                "M": "L",
                "Md": "dd/MM",
                "MEd": "E dd/MM",
                "MMM": "LLL",
                "MMMd": "d MMM",
                "MMMEd": "E d MMM",
                "MMMMd": "d MMMM",
                "MMMMW-count-one": "'semaine' W (MMMM)",
                "MMMMW-count-other": "'semaine' W (MMMM)",
                "ms": "mm:ss",
                "y": "y",
                "yM": "MM/y",
                "yMd": "dd/MM/y",
                "yMEd": "E dd/MM/y",
                "yMMM": "MMM y",
                "yMMMd": "d MMM y",
                "yMMMEd": "E d MMM y",
                "yMMMM": "MMMM y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y",
                "yw-count-one": "'semaine' w 'de' Y",
                "yw-count-other": "'semaine' w 'de' Y"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "h B – h B",
                  "h": "h – h B"
                },
                "Bhm": {
                  "B": "h:mm B – h:mm B",
                  "h": "h:mm – h:mm B",
                  "m": "h:mm – h:mm B"
                },
                "d": {
                  "d": "d–d"
                },
                "Gy": {
                  "G": "y G 'à' y G",
                  "y": "y–y G"
                },
                "GyM": {
                  "G": "M/y G 'à' M/y G",
                  "M": "M–M/y G",
                  "y": "M/y 'à' M/y G"
                },
                "GyMd": {
                  "d": "d–d/M/y G",
                  "G": "d/M/y G 'à' d/M/y G",
                  "M": "d/M 'à' d/M/y G",
                  "y": "d/M/y 'à' d/M/y G"
                },
                "GyMEd": {
                  "d": "E d 'à' E d/M/y G",
                  "G": "E d/M/y G 'à' E d/M/y G",
                  "M": "E d/M 'à' E d/M/y G",
                  "y": "E d/M/y 'à' E d/M/y G"
                },
                "GyMMM": {
                  "G": "MMM y G 'à' MMM y G",
                  "M": "MMM 'à' MMM y G",
                  "y": "MMM y 'à' MMM y G"
                },
                "GyMMMd": {
                  "d": "d–d MMM y G",
                  "G": "d MMM y G 'à' d MMM y G",
                  "M": "d MMM 'à' d MMM y G",
                  "y": "d MMM y 'à' d MMM y G"
                },
                "GyMMMEd": {
                  "d": "E d 'à' E d MMM y G",
                  "G": "E d MMM y G 'à' E d MMM y G",
                  "M": "E d MMM 'à' E d MMM y G",
                  "y": "E d MMM y 'à' E d MMM y G"
                },
                "h": {
                  "a": "h a – h a",
                  "h": "h – h a"
                },
                "H": {
                  "H": "HH – HH"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm – h:mm a",
                  "m": "h:mm – h:mm a"
                },
                "Hm": {
                  "H": "HH:mm – HH:mm",
                  "m": "HH:mm – HH:mm"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm – h:mm a v",
                  "m": "h:mm – h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm – HH:mm v",
                  "m": "HH:mm – HH:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h – h a v"
                },
                "Hv": {
                  "H": "HH – HH v"
                },
                "M": {
                  "M": "M–M"
                },
                "Md": {
                  "d": "dd/MM – dd/MM",
                  "M": "dd/MM – dd/MM"
                },
                "MEd": {
                  "d": "E dd/MM – E dd/MM",
                  "M": "E dd/MM – E dd/MM"
                },
                "MMM": {
                  "M": "MMM–MMM"
                },
                "MMMd": {
                  "d": "d–d MMM",
                  "M": "d MMM – d MMM"
                },
                "MMMEd": {
                  "d": "E d – E d MMM",
                  "M": "E d MMM – E d MMM"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "MM/y – MM/y",
                  "y": "MM/y – MM/y"
                },
                "yMd": {
                  "d": "dd/MM/y – dd/MM/y",
                  "M": "dd/MM/y – dd/MM/y",
                  "y": "dd/MM/y – dd/MM/y"
                },
                "yMEd": {
                  "d": "E dd/MM/y – E dd/MM/y",
                  "M": "E dd/MM/y – E dd/MM/y",
                  "y": "E dd/MM/y – E dd/MM/y"
                },
                "yMMM": {
                  "M": "MMM–MMM y",
                  "y": "MMM y – MMM y"
                },
                "yMMMd": {
                  "d": "d–d MMM y",
                  "M": "d MMM – d MMM y",
                  "y": "d MMM y – d MMM y"
                },
                "yMMMEd": {
                  "d": "E d – E d MMM y",
                  "M": "E d MMM – E d MMM y",
                  "y": "E d MMM y – E d MMM y"
                },
                "yMMMM": {
                  "M": "MMMM – MMMM y",
                  "y": "MMMM y – MMMM y"
                }
              }
            }
          }
        }
      }
    }
  }
}
