/* src/app/commonComponents/modals/default-modals/settings-library-modal/library-create-modal/library-create-modal.component.scss */
.input-container.checkbox-container label {
  display: inline-flex;
}
.input-container.checkbox-container label .j2-icon-info {
  margin-left: 5px;
}
.modal-template .modal-body {
  padding: 0;
}
/*# sourceMappingURL=library-create-modal.component.css.map */
