/* src/app/commonComponents/modals/other-modals/apps-modal/apps-modal.component.scss */
.modal-app-template .modal-container {
  width: 540px;
  height: 100%;
}
.modal-app-template .modal-header-image {
  position: relative;
  height: 180px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 40px 20px 0 20px;
  border-radius: 8px;
}
.modal-app-template .modal-header-image .close-modal {
  position: absolute;
  top: -27px;
  right: -7px;
  cursor: pointer;
}
.modal-app-template .modal-header-image .close-modal span {
  font-size: 11px;
  color: #333333;
}
.modal-app-template .modal-header-image .close-modal:hover {
  opacity: 0.5;
  transform: scale(0.9);
}
.modal-app-template .modal-app-body {
  height: calc(100% - 210px);
}
.modal-app-template .modal-app-body .title-block {
  padding: 0 21px;
}
.modal-app-template .modal-app-body .image-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  transform: scale(1.57);
  background-color: #ffffff;
  margin: auto;
  margin-top: -31px;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
}
.modal-app-template .modal-app-body .image-container.juscourtTracker {
  background-size: 26px;
  background-position-y: 17px;
  background-position-x: 19px;
}
.modal-app-template .modal-app-body .image-container.accountingOrders {
  background-size: 28px;
}
.modal-app-template .modal-app-body .image-container .new-app {
  color: #222222;
  font-size: 9px;
  font-weight: 600;
  border-radius: 3px;
  background-color: #D2FF15;
  padding: 1px 12px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -4px;
  left: -7px;
  height: 15px;
  width: 47px;
  box-shadow: 0px 3px 6px rgba(90, 90, 90, 0.16);
  line-height: 13px;
}
.modal-app-template .modal-app-body .image-container .new-app.visible {
  opacity: 1;
  pointer-events: auto;
}
.modal-app-template .modal-app-body .title-info {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding-left: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: wrap;
}
.modal-app-template .modal-app-body .title-info h5 {
  color: #222222;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin-top: 27px;
  margin-bottom: 10px;
  text-align: center;
}
.modal-app-template .modal-app-body .button-container {
  display: flex;
  align-items: center;
}
.modal-app-template .modal-app-body .button-container a,
.modal-app-template .modal-app-body .button-container .installed-button {
  display: block;
  border: none;
  border-radius: 3px;
  background-color: #1252AF;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  padding: 7px 29px;
  width: 150px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;
}
.modal-app-template .modal-app-body .button-container a:hover {
  color: #ffffff;
  background-color: #0F438E;
}
.modal-app-template .modal-app-body .button-container .installed-button {
  position: relative;
  color: #637283;
  background: #F4F4F4;
  transition: 0.3s ease;
}
.modal-app-template .modal-app-body .button-container .installed-button:hover {
  background: #D9D9D9;
}
.modal-app-template .modal-app-body .button-container .installed-button:hover .delete-button {
  opacity: 1;
  pointer-events: auto;
}
.modal-app-template .modal-app-body .button-container .installed-button .delete-button {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-top: 4px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease;
  width: 150px;
}
.modal-app-template .modal-app-body .button-container .installed-button .delete-button-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 5px 8px;
  color: #b22b2b;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  background-color: #ffffff;
  position: fixed;
  width: 150px;
}
.modal-app-template .modal-app-body .button-container .installed-button .delete-button-inner .j2-icon-remove {
  font-size: 15px;
  color: #b22b2b;
  margin-right: 3px;
}
.modal-app-template .modal-app-body .description-container {
  height: calc(100% - 60px);
  margin: 54px 25px 0;
  padding: 20px 0px 26px;
  border-top: 1px solid #F4F4F4;
}
.modal-app-template .modal-app-body .description-container .description-container-inner .title {
  font-weight: 800;
}
.modal-app-template .modal-app-body .description-container .description-container-inner .description {
  color: #637283;
}
.modal-app-template .modal-app-body .description-container .description-container-inner .description ::ng-deep b {
  color: black;
}
/*# sourceMappingURL=apps-modal.component.css.map */
