<div class="auth-modal-template">
  <div class="review-wrapper">
    <div class="j-logo">
      <img src="/assets/images/auth/jusnote-logo.svg" alt="" />
    </div>
    <div class="dots-item dots-top">
      <img src="/assets/images/auth/auth-dots-top.svg" alt="" />
    </div>
    <div class="dots-item dots-bottom">
      <img src="/assets/images/auth/auth-dots-bottom.svg" alt="" />
    </div>
    <div class="bottom-logos">
      <a href="https://smotrow.com/" target="_blank">
        <img class="logo-smotrow" src="/assets/images/auth/smotrow.png" alt="" />
      </a>
    </div>
  </div>
  <div class="content-wrapper">
    <p class="form-link">
      {{ 'login.notAccount' | translate }}
      <a href="#" (click)="linkTo('/auth/register'); (false)">
        {{ 'login.signUp' | translate }}
      </a>
    </p>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-container">
        <h1>{{ 'login.expired' | translate }}</h1>
        <p>{{ 'login.expired.text' | translate }}</p>
        <div class="input-container" [ngClass]="{ 'invalid-validation error-text': submitted && f.username.errors }">
          <label>Email</label>
          <input type="email" placeholder="example@jusnote.com" formControlName="username" />
          <!-- <div class="error-text-display">Error text</div> -->
        </div>

        <div class="input-container" [ngClass]="{ 'invalid-validation error-text': submitted && f.password.errors }">
          <label>{{ 'login.password' | translate }}</label>
          <input type="password" placeholder="" formControlName="password" />
          <!-- <div class="error-text-display">Error text</div> -->
        </div>
        <ng-container *ngIf="two_factor_authentication">
          <div class="input-container" [ngClass]="{ 'invalid-validation error-text': submitted && (loginForm.get('2fa').errors || codeFail) }">
            <label>{{ 'login.two_factor_code' | translate }}</label>
            <input type="text" placeholder="{{'login.two_factor_code.placeholder' | translate}}" formControlName="2fa" (paste)="pasteCode()" />
            <div class="error-text-display">
              {{loginForm.get('2fa').errors || codeFail ? ('login.two_factor_code.errorMsg' | translate) : '' }}
            </div>
          </div>
          <p class="resend-code">
            <span>{{'login.two_factor_code.resendPart1' | translate}} <span class="link" (click)="onSubmit(true)">{{'login.two_factor_code.resendPart2' | translate}}</span></span>
          </p>
        </ng-container>
        <div class="router-tag">
          <a href="#" (click)="linkTo('/auth/forgot'); (false)">
            {{ 'login.remember' | translate }}
          </a>
        </div>
        <button  mat-raised-button [disabled]="loading">
          {{ 'login.button' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
<app-loader *ngIf="showLoader"></app-loader>
