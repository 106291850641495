{
  "main": {
    "cs": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "cs"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "led",
                  "2": "úno",
                  "3": "bře",
                  "4": "dub",
                  "5": "kvě",
                  "6": "čvn",
                  "7": "čvc",
                  "8": "srp",
                  "9": "zář",
                  "10": "říj",
                  "11": "lis",
                  "12": "pro"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4",
                  "5": "5",
                  "6": "6",
                  "7": "7",
                  "8": "8",
                  "9": "9",
                  "10": "10",
                  "11": "11",
                  "12": "12"
                },
                "wide": {
                  "1": "ledna",
                  "2": "února",
                  "3": "března",
                  "4": "dubna",
                  "5": "května",
                  "6": "června",
                  "7": "července",
                  "8": "srpna",
                  "9": "září",
                  "10": "října",
                  "11": "listopadu",
                  "12": "prosince"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "led",
                  "2": "úno",
                  "3": "bře",
                  "4": "dub",
                  "5": "kvě",
                  "6": "čvn",
                  "7": "čvc",
                  "8": "srp",
                  "9": "zář",
                  "10": "říj",
                  "11": "lis",
                  "12": "pro"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4",
                  "5": "5",
                  "6": "6",
                  "7": "7",
                  "8": "8",
                  "9": "9",
                  "10": "10",
                  "11": "11",
                  "12": "12"
                },
                "wide": {
                  "1": "leden",
                  "2": "únor",
                  "3": "březen",
                  "4": "duben",
                  "5": "květen",
                  "6": "červen",
                  "7": "červenec",
                  "8": "srpen",
                  "9": "září",
                  "10": "říjen",
                  "11": "listopad",
                  "12": "prosinec"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "ne",
                  "mon": "po",
                  "tue": "út",
                  "wed": "st",
                  "thu": "čt",
                  "fri": "pá",
                  "sat": "so"
                },
                "narrow": {
                  "sun": "N",
                  "mon": "P",
                  "tue": "Ú",
                  "wed": "S",
                  "thu": "Č",
                  "fri": "P",
                  "sat": "S"
                },
                "short": {
                  "sun": "ne",
                  "mon": "po",
                  "tue": "út",
                  "wed": "st",
                  "thu": "čt",
                  "fri": "pá",
                  "sat": "so"
                },
                "wide": {
                  "sun": "neděle",
                  "mon": "pondělí",
                  "tue": "úterý",
                  "wed": "středa",
                  "thu": "čtvrtek",
                  "fri": "pátek",
                  "sat": "sobota"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "ne",
                  "mon": "po",
                  "tue": "út",
                  "wed": "st",
                  "thu": "čt",
                  "fri": "pá",
                  "sat": "so"
                },
                "narrow": {
                  "sun": "N",
                  "mon": "P",
                  "tue": "Ú",
                  "wed": "S",
                  "thu": "Č",
                  "fri": "P",
                  "sat": "S"
                },
                "short": {
                  "sun": "ne",
                  "mon": "po",
                  "tue": "út",
                  "wed": "st",
                  "thu": "čt",
                  "fri": "pá",
                  "sat": "so"
                },
                "wide": {
                  "sun": "neděle",
                  "mon": "pondělí",
                  "tue": "úterý",
                  "wed": "středa",
                  "thu": "čtvrtek",
                  "fri": "pátek",
                  "sat": "sobota"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "Q1",
                  "2": "Q2",
                  "3": "Q3",
                  "4": "Q4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1. čtvrtletí",
                  "2": "2. čtvrtletí",
                  "3": "3. čtvrtletí",
                  "4": "4. čtvrtletí"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "Q1",
                  "2": "Q2",
                  "3": "Q3",
                  "4": "Q4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1. čtvrtletí",
                  "2": "2. čtvrtletí",
                  "3": "3. čtvrtletí",
                  "4": "4. čtvrtletí"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "půln.",
                  "am": "dop.",
                  "noon": "pol.",
                  "pm": "odp.",
                  "morning1": "r.",
                  "morning2": "dop.",
                  "afternoon1": "odp.",
                  "evening1": "več.",
                  "night1": "v n."
                },
                "narrow": {
                  "midnight": "půl.",
                  "am": "dop.",
                  "noon": "pol.",
                  "pm": "odp.",
                  "morning1": "r.",
                  "morning2": "d.",
                  "afternoon1": "o.",
                  "evening1": "v.",
                  "night1": "n."
                },
                "wide": {
                  "midnight": "půlnoc",
                  "am": "dop.",
                  "noon": "poledne",
                  "pm": "odp.",
                  "morning1": "ráno",
                  "morning2": "dopoledne",
                  "afternoon1": "odpoledne",
                  "evening1": "večer",
                  "night1": "v noci"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "půlnoc",
                  "am": "dop.",
                  "noon": "poledne",
                  "pm": "odp.",
                  "morning1": "ráno",
                  "morning2": "dopoledne",
                  "afternoon1": "odpoledne",
                  "evening1": "večer",
                  "night1": "noc"
                },
                "narrow": {
                  "midnight": "půl.",
                  "am": "dop.",
                  "noon": "pol.",
                  "pm": "odp.",
                  "morning1": "ráno",
                  "morning2": "dop.",
                  "afternoon1": "odp.",
                  "evening1": "več.",
                  "night1": "noc"
                },
                "wide": {
                  "midnight": "půlnoc",
                  "am": "dop.",
                  "noon": "poledne",
                  "pm": "odp.",
                  "morning1": "ráno",
                  "morning2": "dopoledne",
                  "afternoon1": "odpoledne",
                  "evening1": "večer",
                  "night1": "noc"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "před naším letopočtem",
                "0-alt-variant": "před naším letopočtem",
                "1": "našeho letopočtu",
                "1-alt-variant": "našeho letopočtu"
              },
              "eraAbbr": {
                "0": "př. n. l.",
                "0-alt-variant": "př. n. l.",
                "1": "n. l.",
                "1-alt-variant": "n. l."
              },
              "eraNarrow": {
                "0": "př.n.l.",
                "0-alt-variant": "př. n. l.",
                "1": "n.l.",
                "1-alt-variant": "n. l."
              }
            },
            "dateFormats": {
              "full": "EEEE d. MMMM y",
              "long": "d. MMMM y",
              "medium": "d. M. y",
              "short": "dd.MM.yy"
            },
            "timeFormats": {
              "full": "H:mm:ss zzzz",
              "long": "H:mm:ss z",
              "medium": "H:mm:ss",
              "short": "H:mm"
            },
            "dateTimeFormats": {
              "full": "{1} {0}",
              "long": "{1} {0}",
              "medium": "{1} {0}",
              "short": "{1} {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "h:mm B",
                "Bhms": "h:mm:ss B",
                "d": "d.",
                "E": "ccc",
                "EBhm": "E h:mm B",
                "EBhms": "E h:mm:ss B",
                "Ed": "E d.",
                "Ehm": "E h:mm a",
                "EHm": "E H:mm",
                "Ehms": "E h:mm:ss a",
                "EHms": "E H:mm:ss",
                "Gy": "y G",
                "GyMMM": "LLLL y G",
                "GyMMMd": "d. M. y G",
                "GyMMMEd": "E d. M. y G",
                "GyMMMMd": "d. MMMM y G",
                "GyMMMMEd": "E d. MMMM y G",
                "h": "h a",
                "H": "H",
                "hm": "h:mm a",
                "Hm": "H:mm",
                "hms": "h:mm:ss a",
                "Hms": "H:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "H:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "H:mm v",
                "M": "L",
                "Md": "d. M.",
                "MEd": "E d. M.",
                "MMM": "LLL",
                "MMMd": "d. M.",
                "MMMEd": "E d. M.",
                "MMMMd": "d. MMMM",
                "MMMMEd": "E d. MMMM",
                "MMMMW-count-one": "W. 'týden' MMMM",
                "MMMMW-count-few": "W. 'týden' MMMM",
                "MMMMW-count-many": "W. 'týden' MMMM",
                "MMMMW-count-other": "W. 'týden' MMMM",
                "ms": "mm:ss",
                "y": "y",
                "yM": "M/y",
                "yMd": "d. M. y",
                "yMEd": "E d. M. y",
                "yMMM": "LLLL y",
                "yMMMd": "d. M. y",
                "yMMMEd": "E d. M. y",
                "yMMMM": "LLLL y",
                "yMMMMd": "d. MMMM y",
                "yMMMMEd": "E d. MMMM y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y",
                "yw-count-one": "w. 'týden' 'roku' Y",
                "yw-count-few": "w. 'týden' 'roku' Y",
                "yw-count-many": "w. 'týden' 'roku' Y",
                "yw-count-other": "w. 'týden' 'roku' Y"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "h B – h B",
                  "h": "h–h B"
                },
                "Bhm": {
                  "B": "h:mm B – h:mm B",
                  "h": "h:mm–h:mm B",
                  "m": "h:mm–h:mm B"
                },
                "d": {
                  "d": "d.–d."
                },
                "Gy": {
                  "G": "y G – y G",
                  "y": "y–y G"
                },
                "GyM": {
                  "G": "M/y GGGGG – M/y GGGGG",
                  "M": "M/y – M/y GGGGG",
                  "y": "M/y – M/y GGGGG"
                },
                "GyMd": {
                  "d": "d. M. y – d. M. y GGGGG",
                  "G": "d. M. y GGGGG – d. M. y GGGGG",
                  "M": "d. M. y – d. M. y GGGGG",
                  "y": "d. M. y – d. M. y GGGGG"
                },
                "GyMEd": {
                  "d": "E d. M. y – E d. M. y GGGGG",
                  "G": "E d. M. y GGGGG – E d. M. y GGGGG",
                  "M": "E d. M. y – E d. M. y GGGGG",
                  "y": "E d. M. y – E d. M. y GGGGG"
                },
                "GyMMM": {
                  "G": "LLLL y G – LLLL y G",
                  "M": "LLLL–LLLL y G",
                  "y": "LLLL y – LLLL y G"
                },
                "GyMMMd": {
                  "d": "d.–d. M. y G",
                  "G": "d. M. y G – d. M. y G",
                  "M": "d. M. – d. M. y G",
                  "y": "d. M. y – d. M. y G"
                },
                "GyMMMEd": {
                  "d": "E d. M. – E d. M. y G",
                  "G": "E d. M. y G – E d. M. y G",
                  "M": "E d. M. – E d. M. y G",
                  "y": "E d. M. y – E d. M. y G"
                },
                "h": {
                  "a": "h a – h a",
                  "h": "h–h a"
                },
                "H": {
                  "H": "H–H"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "H:mm–H:mm",
                  "m": "H:mm–H:mm"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "H:mm–H:mm v",
                  "m": "H:mm–H:mm v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "H–H v"
                },
                "M": {
                  "M": "M–M"
                },
                "Md": {
                  "d": "d. M. – d. M.",
                  "M": "d. M. – d. M."
                },
                "MEd": {
                  "d": "E d. M. – E d. M.",
                  "M": "E d. M. – E d. M."
                },
                "MMM": {
                  "M": "MMM–MMM"
                },
                "MMMd": {
                  "d": "d.–d. M.",
                  "M": "d. M. – d. M."
                },
                "MMMEd": {
                  "d": "E d. M. – E d. M.",
                  "M": "E d. M. – E d. M."
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "M/y – M/y",
                  "y": "M/y – M/y"
                },
                "yMd": {
                  "d": "dd.MM.y – dd.MM.y",
                  "M": "dd.MM.y – dd.MM.y",
                  "y": "dd.MM.y – dd.MM.y"
                },
                "yMEd": {
                  "d": "E dd.MM.y – E dd.MM.y",
                  "M": "E dd.MM.y – E dd.MM.y",
                  "y": "E dd.MM.y – E dd.MM.y"
                },
                "yMMM": {
                  "M": "MMM–MMM y",
                  "y": "MMM y – MMM y"
                },
                "yMMMd": {
                  "d": "d.–d. M. y",
                  "M": "d. M. – d. M. y",
                  "y": "d. M. y – d. M. y"
                },
                "yMMMEd": {
                  "d": "E d. M. – E d. M. y",
                  "M": "E d. M. – E d. M. y",
                  "y": "E d. M. y – E d. M. y"
                },
                "yMMMM": {
                  "M": "LLLL–LLLL y",
                  "y": "LLLL y – LLLL y"
                }
              }
            }
          }
        }
      }
    }
  }
}
