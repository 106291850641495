{
  "main": {
    "cs": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "cs"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+H:mm;-H:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "Časové pásmo {0}",
          "regionFormat-type-daylight": "{0} (+1)",
          "regionFormat-type-standard": "{0} (+0)",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Anguilla"
              },
              "Antigua": {
                "exemplarCity": "Antigua"
              },
              "Araguaina": {
                "exemplarCity": "Araguaina"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Rio Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucuman"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Asunción"
              },
              "Bahia": {
                "exemplarCity": "Bahía"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahia Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belém"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogotá"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Cancún"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Cayenne"
              },
              "Cayman": {
                "exemplarCity": "Kajmanské ostrovy"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Córdoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Kostarika"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiaba"
              },
              "Curacao": {
                "exemplarCity": "Curaçao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominika"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepe"
              },
              "El_Salvador": {
                "exemplarCity": "Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Guadeloupe"
              },
              "Guatemala": {
                "exemplarCity": "Guatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guayaquil"
              },
              "Guyana": {
                "exemplarCity": "Guyana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Havana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamajka"
              },
              "Jujuy": {
                "exemplarCity": "Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello, Kentucky"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Lower Prince’s Quarter"
              },
              "Maceio": {
                "exemplarCity": "Maceio"
              },
              "Managua": {
                "exemplarCity": "Managua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martinik"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlán"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Merida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Ciudad de México"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrey"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "New York"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, Severní Dakota"
                },
                "New_Salem": {
                  "exemplarCity": "New Salem, Severní Dakota"
                },
                "Center": {
                  "exemplarCity": "Center, Severní Dakota"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port-au-Prince"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port of Spain"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Portoriko"
              },
              "Punta_Arenas": {
                "exemplarCity": "Punta Arenas"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santa_Isabel": {
                "exemplarCity": "Santa Isabel"
              },
              "Santarem": {
                "exemplarCity": "Santarém"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "São Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "Svatý Bartoloměj"
              },
              "St_Johns": {
                "exemplarCity": "St. John’s"
              },
              "St_Kitts": {
                "exemplarCity": "Svatý Kryštof"
              },
              "St_Lucia": {
                "exemplarCity": "Svatá Lucie"
              },
              "St_Thomas": {
                "exemplarCity": "Svatý Tomáš (Karibik)"
              },
              "St_Vincent": {
                "exemplarCity": "Svatý Vincenc"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Thule"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azorské ostrovy"
              },
              "Bermuda": {
                "exemplarCity": "Bermudy"
              },
              "Canary": {
                "exemplarCity": "Kanárské ostrovy"
              },
              "Cape_Verde": {
                "exemplarCity": "Kapverdy"
              },
              "Faeroe": {
                "exemplarCity": "Faerské ostrovy"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavík"
              },
              "South_Georgia": {
                "exemplarCity": "Jižní Georgie"
              },
              "St_Helena": {
                "exemplarCity": "Svatá Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andorra"
              },
              "Astrakhan": {
                "exemplarCity": "Astrachaň"
              },
              "Athens": {
                "exemplarCity": "Athény"
              },
              "Belgrade": {
                "exemplarCity": "Bělehrad"
              },
              "Berlin": {
                "exemplarCity": "Berlín"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Brusel"
              },
              "Bucharest": {
                "exemplarCity": "Bukurešť"
              },
              "Budapest": {
                "exemplarCity": "Budapešť"
              },
              "Busingen": {
                "exemplarCity": "Busingen"
              },
              "Chisinau": {
                "exemplarCity": "Kišiněv"
              },
              "Copenhagen": {
                "exemplarCity": "Kodaň"
              },
              "Dublin": {
                "long": {
                  "daylight": "Irský letní čas"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsinky"
              },
              "Isle_of_Man": {
                "exemplarCity": "Ostrov Man"
              },
              "Istanbul": {
                "exemplarCity": "Istanbul"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrad"
              },
              "Kiev": {
                "exemplarCity": "Kyjev"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lisabon"
              },
              "Ljubljana": {
                "exemplarCity": "Lublaň"
              },
              "London": {
                "long": {
                  "daylight": "Britský letní čas"
                },
                "exemplarCity": "Londýn"
              },
              "Luxembourg": {
                "exemplarCity": "Lucemburk"
              },
              "Madrid": {
                "exemplarCity": "Madrid"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Mariehamn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Monako"
              },
              "Moscow": {
                "exemplarCity": "Moskva"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Paříž"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Praha"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Řím"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevo"
              },
              "Saratov": {
                "exemplarCity": "Saratov"
              },
              "Simferopol": {
                "exemplarCity": "Simferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sofie"
              },
              "Stockholm": {
                "exemplarCity": "Stockholm"
              },
              "Tallinn": {
                "exemplarCity": "Tallinn"
              },
              "Tirane": {
                "exemplarCity": "Tirana"
              },
              "Ulyanovsk": {
                "exemplarCity": "Uljanovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Užhorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vatikán"
              },
              "Vienna": {
                "exemplarCity": "Vídeň"
              },
              "Vilnius": {
                "exemplarCity": "Vilnius"
              },
              "Volgograd": {
                "exemplarCity": "Volgograd"
              },
              "Warsaw": {
                "exemplarCity": "Varšava"
              },
              "Zagreb": {
                "exemplarCity": "Záhřeb"
              },
              "Zaporozhye": {
                "exemplarCity": "Záporoží"
              },
              "Zurich": {
                "exemplarCity": "Curych"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidžan"
              },
              "Accra": {
                "exemplarCity": "Accra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Addis Abeba"
              },
              "Algiers": {
                "exemplarCity": "Alžír"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banjul"
              },
              "Bissau": {
                "exemplarCity": "Bissau"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bujumbura"
              },
              "Cairo": {
                "exemplarCity": "Káhira"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Conakry"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Džibuti"
              },
              "Douala": {
                "exemplarCity": "Douala"
              },
              "El_Aaiun": {
                "exemplarCity": "El Aaiun"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johannesburg"
              },
              "Juba": {
                "exemplarCity": "Juba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Chartúm"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinshasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lomé"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadišu"
              },
              "Monrovia": {
                "exemplarCity": "Monrovia"
              },
              "Nairobi": {
                "exemplarCity": "Nairobi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndžamena"
              },
              "Niamey": {
                "exemplarCity": "Niamey"
              },
              "Nouakchott": {
                "exemplarCity": "Nuakšott"
              },
              "Ouagadougou": {
                "exemplarCity": "Ouagadougou"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto-Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "Svatý Tomáš"
              },
              "Tripoli": {
                "exemplarCity": "Tripolis"
              },
              "Tunis": {
                "exemplarCity": "Tunis"
              },
              "Windhoek": {
                "exemplarCity": "Windhoek"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Almaty"
              },
              "Amman": {
                "exemplarCity": "Ammán"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aktau"
              },
              "Aqtobe": {
                "exemplarCity": "Aktobe"
              },
              "Ashgabat": {
                "exemplarCity": "Ašchabad"
              },
              "Atyrau": {
                "exemplarCity": "Atyrau"
              },
              "Baghdad": {
                "exemplarCity": "Bagdád"
              },
              "Bahrain": {
                "exemplarCity": "Bahrajn"
              },
              "Baku": {
                "exemplarCity": "Baku"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Bejrút"
              },
              "Bishkek": {
                "exemplarCity": "Biškek"
              },
              "Brunei": {
                "exemplarCity": "Brunej"
              },
              "Calcutta": {
                "exemplarCity": "Kalkata"
              },
              "Chita": {
                "exemplarCity": "Čita"
              },
              "Choibalsan": {
                "exemplarCity": "Čojbalsan"
              },
              "Colombo": {
                "exemplarCity": "Kolombo"
              },
              "Damascus": {
                "exemplarCity": "Damašek"
              },
              "Dhaka": {
                "exemplarCity": "Dháka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubaj"
              },
              "Dushanbe": {
                "exemplarCity": "Dušanbe"
              },
              "Famagusta": {
                "exemplarCity": "Famagusta"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hongkong"
              },
              "Hovd": {
                "exemplarCity": "Hovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkutsk"
              },
              "Jakarta": {
                "exemplarCity": "Jakarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jeruzalém"
              },
              "Kabul": {
                "exemplarCity": "Kábul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamčatka"
              },
              "Karachi": {
                "exemplarCity": "Karáčí"
              },
              "Katmandu": {
                "exemplarCity": "Káthmándú"
              },
              "Khandyga": {
                "exemplarCity": "Chandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnojarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lumpur"
              },
              "Kuching": {
                "exemplarCity": "Kučing"
              },
              "Kuwait": {
                "exemplarCity": "Kuvajt"
              },
              "Macau": {
                "exemplarCity": "Macao"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makassar"
              },
              "Manila": {
                "exemplarCity": "Manila"
              },
              "Muscat": {
                "exemplarCity": "Maskat"
              },
              "Nicosia": {
                "exemplarCity": "Nikósie"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuzněck"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Uralsk"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnompenh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pchjongjang"
              },
              "Qatar": {
                "exemplarCity": "Katar"
              },
              "Qostanay": {
                "exemplarCity": "Kostanaj"
              },
              "Qyzylorda": {
                "exemplarCity": "Kyzylorda"
              },
              "Rangoon": {
                "exemplarCity": "Rangún"
              },
              "Riyadh": {
                "exemplarCity": "Rijád"
              },
              "Saigon": {
                "exemplarCity": "Ho Či Minovo město"
              },
              "Sakhalin": {
                "exemplarCity": "Sachalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkand"
              },
              "Seoul": {
                "exemplarCity": "Soul"
              },
              "Shanghai": {
                "exemplarCity": "Šanghaj"
              },
              "Singapore": {
                "exemplarCity": "Singapur"
              },
              "Srednekolymsk": {
                "exemplarCity": "Sredněkolymsk"
              },
              "Taipei": {
                "exemplarCity": "Tchaj-pej"
              },
              "Tashkent": {
                "exemplarCity": "Taškent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Teherán"
              },
              "Thimphu": {
                "exemplarCity": "Thimbú"
              },
              "Tokyo": {
                "exemplarCity": "Tokio"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ulánbátar"
              },
              "Urumqi": {
                "exemplarCity": "Urumči"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vientiane"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Jakutsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Jekatěrinburg"
              },
              "Yerevan": {
                "exemplarCity": "Jerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Chagos"
              },
              "Christmas": {
                "exemplarCity": "Vánoční ostrov"
              },
              "Cocos": {
                "exemplarCity": "Kokosové ostrovy"
              },
              "Comoro": {
                "exemplarCity": "Komory"
              },
              "Kerguelen": {
                "exemplarCity": "Kerguelenovy ostrovy"
              },
              "Mahe": {
                "exemplarCity": "Mahé"
              },
              "Maldives": {
                "exemplarCity": "Maledivy"
              },
              "Mauritius": {
                "exemplarCity": "Mauricius"
              },
              "Mayotte": {
                "exemplarCity": "Mayotte"
              },
              "Reunion": {
                "exemplarCity": "Réunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Bougainville"
              },
              "Chatham": {
                "exemplarCity": "Chathamské ostrovy"
              },
              "Easter": {
                "exemplarCity": "Velikonoční ostrov"
              },
              "Efate": {
                "exemplarCity": "Éfaté"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fidži"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galapágy"
              },
              "Gambier": {
                "exemplarCity": "Gambierovy ostrovy"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "short": {
                  "generic": "HST",
                  "standard": "HST",
                  "daylight": "HDT"
                },
                "exemplarCity": "Honolulu"
              },
              "Johnston": {
                "exemplarCity": "Johnston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Markézy"
              },
              "Midway": {
                "exemplarCity": "Midway"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Nouméa"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairnovy ostrovy"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Tahiti"
              },
              "Tarawa": {
                "exemplarCity": "Tarawa"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuukské ostrovy"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Syowa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "Koordinovaný světový čas"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "neznámé město"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "Acrejský čas",
                "standard": "Acrejský standardní čas",
                "daylight": "Acrejský letní čas"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "Afghánský čas"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "Středoafrický čas"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "Východoafrický čas"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "Jihoafrický čas"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "Západoafrický čas",
                "standard": "Západoafrický standardní čas",
                "daylight": "Západoafrický letní čas"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Aljašský čas",
                "standard": "Aljašský standardní čas",
                "daylight": "Aljašský letní čas"
              },
              "short": {
                "generic": "AKT",
                "standard": "AKST",
                "daylight": "AKDT"
              }
            },
            "Almaty": {
              "long": {
                "generic": "Almatský čas",
                "standard": "Almatský standardní čas",
                "daylight": "Almatský letní čas"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Amazonský čas",
                "standard": "Amazonský standardní čas",
                "daylight": "Amazonský letní čas"
              }
            },
            "America_Central": {
              "long": {
                "generic": "Severoamerický centrální čas",
                "standard": "Severoamerický centrální standardní čas",
                "daylight": "Severoamerický centrální letní čas"
              },
              "short": {
                "generic": "CT",
                "standard": "CST",
                "daylight": "CDT"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "Severoamerický východní čas",
                "standard": "Severoamerický východní standardní čas",
                "daylight": "Severoamerický východní letní čas"
              },
              "short": {
                "generic": "ET",
                "standard": "EST",
                "daylight": "EDT"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Severoamerický horský čas",
                "standard": "Severoamerický horský standardní čas",
                "daylight": "Severoamerický horský letní čas"
              },
              "short": {
                "generic": "MT",
                "standard": "MST",
                "daylight": "MDT"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "Severoamerický pacifický čas",
                "standard": "Severoamerický pacifický standardní čas",
                "daylight": "Severoamerický pacifický letní čas"
              },
              "short": {
                "generic": "PT",
                "standard": "PST",
                "daylight": "PDT"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Anadyrský čas",
                "standard": "Anadyrský standardní čas",
                "daylight": "Anadyrský letní čas"
              }
            },
            "Apia": {
              "long": {
                "generic": "Apijský čas",
                "standard": "Apijský standardní čas",
                "daylight": "Apijský letní čas"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "Aktauský čas",
                "standard": "Aktauský standardní čas",
                "daylight": "Aktauský letní čas"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "Aktobský čas",
                "standard": "Aktobský standardní čas",
                "daylight": "Aktobský letní čas"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Arabský čas",
                "standard": "Arabský standardní čas",
                "daylight": "Arabský letní čas"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Argentinský čas",
                "standard": "Argentinský standardní čas",
                "daylight": "Argentinský letní čas"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "Západoargentinský čas",
                "standard": "Západoargentinský standardní čas",
                "daylight": "Západoargentinský letní čas"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Arménský čas",
                "standard": "Arménský standardní čas",
                "daylight": "Arménský letní čas"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "Atlantický čas",
                "standard": "Atlantický standardní čas",
                "daylight": "Atlantický letní čas"
              },
              "short": {
                "generic": "AT",
                "standard": "AST",
                "daylight": "ADT"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "Středoaustralský čas",
                "standard": "Středoaustralský standardní čas",
                "daylight": "Středoaustralský letní čas"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "Středozápadní australský čas",
                "standard": "Středozápadní australský standardní čas",
                "daylight": "Středozápadní australský letní čas"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "Východoaustralský čas",
                "standard": "Východoaustralský standardní čas",
                "daylight": "Východoaustralský letní čas"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "Západoaustralský čas",
                "standard": "Západoaustralský standardní čas",
                "daylight": "Západoaustralský letní čas"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Ázerbájdžánský čas",
                "standard": "Ázerbájdžánský standardní čas",
                "daylight": "Ázerbájdžánský letní čas"
              }
            },
            "Azores": {
              "long": {
                "generic": "Azorský čas",
                "standard": "Azorský standardní čas",
                "daylight": "Azorský letní čas"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Bangladéšský čas",
                "standard": "Bangladéšský standardní čas",
                "daylight": "Bangladéšský letní čas"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Bhútánský čas"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Bolivijský čas"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Brasilijský čas",
                "standard": "Brasilijský standardní čas",
                "daylight": "Brasilijský letní čas"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Brunejský čas"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Kapverdský čas",
                "standard": "Kapverdský standardní čas",
                "daylight": "Kapverdský letní čas"
              }
            },
            "Casey": {
              "long": {
                "standard": "Čas Caseyho stanice"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Chamorrský čas"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Chathamský čas",
                "standard": "Chathamský standardní čas",
                "daylight": "Chathamský letní čas"
              }
            },
            "Chile": {
              "long": {
                "generic": "Chilský čas",
                "standard": "Chilský standardní čas",
                "daylight": "Chilský letní čas"
              }
            },
            "China": {
              "long": {
                "generic": "Čínský čas",
                "standard": "Čínský standardní čas",
                "daylight": "Čínský letní čas"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Čojbalsanský čas",
                "standard": "Čojbalsanský standardní čas",
                "daylight": "Čojbalsanský letní čas"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Čas Vánočního ostrova"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Čas Kokosových ostrovů"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Kolumbijský čas",
                "standard": "Kolumbijský standardní čas",
                "daylight": "Kolumbijský letní čas"
              }
            },
            "Cook": {
              "long": {
                "generic": "Čas Cookových ostrovů",
                "standard": "Standardní čas Cookových ostrovů",
                "daylight": "Letní čas Cookových ostrovů"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Kubánský čas",
                "standard": "Kubánský standardní čas",
                "daylight": "Kubánský letní čas"
              }
            },
            "Davis": {
              "long": {
                "standard": "Čas Davisovy stanice"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Čas stanice Dumonta d’Urvilla"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "Východotimorský čas"
              }
            },
            "Easter": {
              "long": {
                "generic": "Čas Velikonočního ostrova",
                "standard": "Standardní čas Velikonočního ostrova",
                "daylight": "Letní čas Velikonočního ostrova"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Ekvádorský čas"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "Středoevropský čas",
                "standard": "Středoevropský standardní čas",
                "daylight": "Středoevropský letní čas"
              },
              "short": {
                "generic": "SEČ",
                "standard": "SEČ",
                "daylight": "SELČ"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "Východoevropský čas",
                "standard": "Východoevropský standardní čas",
                "daylight": "Východoevropský letní čas"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Dálněvýchodoevropský čas"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "Západoevropský čas",
                "standard": "Západoevropský standardní čas",
                "daylight": "Západoevropský letní čas"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Falklandský čas",
                "standard": "Falklandský standardní čas",
                "daylight": "Falklandský letní čas"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fidžijský čas",
                "standard": "Fidžijský standardní čas",
                "daylight": "Fidžijský letní čas"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "Francouzskoguyanský čas"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "Čas Francouzských jižních a antarktických území"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galapážský čas"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Gambierský čas"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Gruzínský čas",
                "standard": "Gruzínský standardní čas",
                "daylight": "Gruzínský letní čas"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Čas Gilbertových ostrovů"
              }
            },
            "GMT": {
              "long": {
                "standard": "Greenwichský střední čas"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "Východogrónský čas",
                "standard": "Východogrónský standardní čas",
                "daylight": "Východogrónský letní čas"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "Západogrónský čas",
                "standard": "Západogrónský standardní čas",
                "daylight": "Západogrónský letní čas"
              }
            },
            "Guam": {
              "long": {
                "standard": "Guamský čas"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Standardní čas Perského zálivu"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Guyanský čas"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Havajsko-aleutský čas",
                "standard": "Havajsko-aleutský standardní čas",
                "daylight": "Havajsko-aleutský letní čas"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hongkongský čas",
                "standard": "Hongkongský standardní čas",
                "daylight": "Hongkongský letní čas"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Hovdský čas",
                "standard": "Hovdský standardní čas",
                "daylight": "Hovdský letní čas"
              }
            },
            "India": {
              "long": {
                "standard": "Indický čas"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Indickooceánský čas"
              }
            },
            "Indochina": {
              "long": {
                "standard": "Indočínský čas"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Středoindonéský čas"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Východoindonéský čas"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "Západoindonéský čas"
              }
            },
            "Iran": {
              "long": {
                "generic": "Íránský čas",
                "standard": "Íránský standardní čas",
                "daylight": "Íránský letní čas"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Irkutský čas",
                "standard": "Irkutský standardní čas",
                "daylight": "Irkutský letní čas"
              }
            },
            "Israel": {
              "long": {
                "generic": "Izraelský čas",
                "standard": "Izraelský standardní čas",
                "daylight": "Izraelský letní čas"
              }
            },
            "Japan": {
              "long": {
                "generic": "Japonský čas",
                "standard": "Japonský standardní čas",
                "daylight": "Japonský letní čas"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Petropavlovsko-kamčatský čas",
                "standard": "Petropavlovsko-kamčatský standardní čas",
                "daylight": "Petropavlovsko-kamčatský letní čas"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "Východokazachstánský čas"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "Západokazachstánský čas"
              }
            },
            "Korea": {
              "long": {
                "generic": "Korejský čas",
                "standard": "Korejský standardní čas",
                "daylight": "Korejský letní čas"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Kosrajský čas"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Krasnojarský čas",
                "standard": "Krasnojarský standardní čas",
                "daylight": "Krasnojarský letní čas"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Kyrgyzský čas"
              }
            },
            "Lanka": {
              "long": {
                "standard": "Srílanský čas"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Čas Rovníkových ostrovů"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Čas ostrova lorda Howa",
                "standard": "Standardní čas ostrova lorda Howa",
                "daylight": "Letní čas ostrova lorda Howa"
              }
            },
            "Macau": {
              "long": {
                "generic": "Macajský čas",
                "standard": "Macajský standardní čas",
                "daylight": "Macajský letní čas"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Čas ostrova Macquarie"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Magadanský čas",
                "standard": "Magadanský standardní čas",
                "daylight": "Magadanský letní čas"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Malajský čas"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Maledivský čas"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Markézský čas"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Čas Marshallových ostrovů"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Mauricijský čas",
                "standard": "Mauricijský standardní čas",
                "daylight": "Mauricijský letní čas"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Čas Mawsonovy stanice"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Severozápadní mexický čas",
                "standard": "Severozápadní mexický standardní čas",
                "daylight": "Severozápadní mexický letní čas"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Mexický pacifický čas",
                "standard": "Mexický pacifický standardní čas",
                "daylight": "Mexický pacifický letní čas"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Ulánbátarský čas",
                "standard": "Ulánbátarský standardní čas",
                "daylight": "Ulánbátarský letní čas"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moskevský čas",
                "standard": "Moskevský standardní čas",
                "daylight": "Moskevský letní čas"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Myanmarský čas"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Naurský čas"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Nepálský čas"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "Novokaledonský čas",
                "standard": "Novokaledonský standardní čas",
                "daylight": "Novokaledonský letní čas"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "Novozélandský čas",
                "standard": "Novozélandský standardní čas",
                "daylight": "Novozélandský letní čas"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Newfoundlandský čas",
                "standard": "Newfoundlandský standardní čas",
                "daylight": "Newfoundlandský letní čas"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niuejský čas"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Norfolkský čas"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Čas souostroví Fernando de Noronha",
                "standard": "Standardní čas souostroví Fernando de Noronha",
                "daylight": "Letní čas souostroví Fernando de Noronha"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "Severomariánský čas"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Novosibirský čas",
                "standard": "Novosibirský standardní čas",
                "daylight": "Novosibirský letní čas"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Omský čas",
                "standard": "Omský standardní čas",
                "daylight": "Omský letní čas"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Pákistánský čas",
                "standard": "Pákistánský standardní čas",
                "daylight": "Pákistánský letní čas"
              }
            },
            "Palau": {
              "long": {
                "standard": "Palauský čas"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Čas Papuy-Nové Guiney"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Paraguayský čas",
                "standard": "Paraguayský standardní čas",
                "daylight": "Paraguayský letní čas"
              }
            },
            "Peru": {
              "long": {
                "generic": "Peruánský čas",
                "standard": "Peruánský standardní čas",
                "daylight": "Peruánský letní čas"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Filipínský čas",
                "standard": "Filipínský standardní čas",
                "daylight": "Filipínský letní čas"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Čas Fénixových ostrovů"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "Pierre-miquelonský čas",
                "standard": "Pierre-miquelonský standardní čas",
                "daylight": "Pierre-miquelonský letní čas"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Čas Pitcairnových ostrovů"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Ponapský čas"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Pchjongjangský čas"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "Kyzylordský čas",
                "standard": "Kyzylordský standardní čas",
                "daylight": "Kyzylordský letní čas"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Réunionský čas"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Čas Rotherovy stanice"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Sachalinský čas",
                "standard": "Sachalinský standardní čas",
                "daylight": "Sachalinský letní čas"
              }
            },
            "Samara": {
              "long": {
                "generic": "Samarský čas",
                "standard": "Samarský standardní čas",
                "daylight": "Samarský letní čas"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Samojský čas",
                "standard": "Samojský standardní čas",
                "daylight": "Samojský letní čas"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Seychelský čas"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singapurský čas"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Čas Šalamounových ostrovů"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "Čas Jižní Georgie"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Surinamský čas"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Čas stanice Šówa"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahitský čas"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Tchajpejský čas",
                "standard": "Tchajpejský standardní čas",
                "daylight": "Tchajpejský letní čas"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tádžický čas"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tokelauský čas"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tonžský čas",
                "standard": "Tonžský standardní čas",
                "daylight": "Tonžský letní čas"
              }
            },
            "Truk": {
              "long": {
                "standard": "Chuukský čas"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Turkmenský čas",
                "standard": "Turkmenský standardní čas",
                "daylight": "Turkmenský letní čas"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Tuvalský čas"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Uruguayský čas",
                "standard": "Uruguayský standardní čas",
                "daylight": "Uruguayský letní čas"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Uzbecký čas",
                "standard": "Uzbecký standardní čas",
                "daylight": "Uzbecký letní čas"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanuatský čas",
                "standard": "Vanuatský standardní čas",
                "daylight": "Vanuatský letní čas"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Venezuelský čas"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Vladivostocký čas",
                "standard": "Vladivostocký standardní čas",
                "daylight": "Vladivostocký letní čas"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Volgogradský čas",
                "standard": "Volgogradský standardní čas",
                "daylight": "Volgogradský letní čas"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Čas stanice Vostok"
              }
            },
            "Wake": {
              "long": {
                "standard": "Čas ostrova Wake"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Čas ostrovů Wallis a Futuna"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Jakutský čas",
                "standard": "Jakutský standardní čas",
                "daylight": "Jakutský letní čas"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Jekatěrinburský čas",
                "standard": "Jekatěrinburský standardní čas",
                "daylight": "Jekatěrinburský letní čas"
              }
            }
          }
        }
      }
    }
  }
}
