import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-reports-custom-modal',
    templateUrl: './reports-custom-modal.component.html',
    styleUrls: ['./reports-custom-modal.component.scss'],
    standalone: false
})
export class ReportsCustomModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ReportsCustomModalComponent>) {}

  ngOnInit() {}

  closeModal(): void {
    this.dialogRef.close();
  }
}
