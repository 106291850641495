import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { disappearanceAnimation } from '@app/helpers/fadeAnimation';
import { CustomSelectAdapter } from '@app/helpers/custom-select-adapter';
import { MatterModalService } from '@app/modules/matter-card/services/matter-modal.service';
import { ExpenseType } from '@app/models';
import { MatterStrategy } from '@app/models/matter-strategy';
import { Store } from '@ngrx/store';
import { IAppState } from '@app/store/state/app.state';
import { Subscription } from 'rxjs';
import { SelectCreateMatter } from '@app/store/selectors/matter.selector';
import { MatterExtended } from '@app/models/matter-extended';
import { UntypedFormGroup, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { TimeFormatter } from '@app/helpers';
import { LoadExpenseTypesAction } from '@app/modules/settings/store/actions/expense-types.actions';
import { selectExpenseTypes } from '@app/modules/settings/store/selectors/expence-type.selector';
import { ConfirmModalComponent } from '@app/commonComponents/confirm-modal/confirm-modal.component';
import { selectCurrentTask } from '@app/store/selectors/task.selectors';
import { TranslateService } from '@ngx-translate/core';
import { DocumentFromTemplateUploadModalComponent } from '../../default-modals/document-from-template-upload-modal/document-from-template-upload-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TemplateService } from '@app/modules/settings/services/template.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GetDocumentsByTaskSuccess } from '@app/store/actions/document.actions';
import { TaskEffects } from '@app/store/effects/task.effects';
import { GetTask, GetTaskSuccess } from '@app/store/actions/task.actions';
import { first, take } from 'rxjs/operators';
import { FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { EmitType } from '@syncfusion/ej2-base';
import { DebounceService } from '@app/helpers/debounce.service';
import {CustomDateAdapter} from '@app/helpers/custom-date-adapter';
import { TasksEventsModalComponent } from '@app/commonComponents/wide-modals/task-event-modal/t-e-modal.component';

@Component({
    selector: 'app-matter-plan-of-action-modal',
    templateUrl: './matter-plan-of-action-modal.component.html',
    styleUrls: ['./matter-plan-of-action-modal.component.scss'],
    animations: [disappearanceAnimation],
    standalone: false
})
export class MatterPlanOfActionModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<MatterPlanOfActionModalComponent>,
    private translate: TranslateService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private matterModalService: MatterModalService,
    private router: Router,
    private taskEffects: TaskEffects,
    private store: Store<IAppState>,
    private timeFormatter: TimeFormatter,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private debounceService: DebounceService
  ) {}

  get matterEstimateTimeEntries(): UntypedFormArray {
    return this.dataForm.get('matterEstimateTimeEntries') as UntypedFormArray;
  }
  get matterEstimateExpenses(): UntypedFormArray {
    return this.dataForm.get('matterEstimateExpenses') as UntypedFormArray;
  }

  get matterEstimatesControls() {
    return (this.dataForm.get('matterEstimateTimeEntries') as UntypedFormArray).controls;
  }
  readonly allowCurrent = CustomSelectAdapter.currentAllowCustom;

  private subscriptions = new Subscription();
  matter: MatterExtended;
  dataForm: UntypedFormGroup;
  spinnerName = 'matter-plan-spinner';
  showLoader = false;

  servicesValue = false;
  expensesValue = false;

  info: Array<MatterStrategy>;

  submittedForm = false;

  expenseTypes: ExpenseType[];

  editableWorkIndex = -1;
  editableExpenseIndex = -1;

  isEmpty = false;

  fileUrl;
  fileName;
  checkClickSubmit = false;

  visibleTaskModal = false;

  dateFormat = CustomDateAdapter.dateFormat;

  ngOnInit() {
    this.getInfo();

    this.subscriptions.add(
      this.store.select(SelectCreateMatter).subscribe(matter => {
        this.matter = matter;
      })
    );

    this.getDataFromStore();
    this.createForm();
  }

  ngOnDestroy(): void {
    this.debounceService.removeDebounceData();
  }

  getInfo() {
    this.matterModalService.getInfo(this.data.id).subscribe(dashboard => {
      if (dashboard.member.length !== 0) {
        this.isEmpty = true;
        this.getData(dashboard.member);
        this.takeOtherData(dashboard.member);

        this.servicesValue = dashboard.member[0].withoutTimeEntry;
        this.expensesValue = dashboard.member[0].withoutExpense;
      } else {
        this.isEmpty = false;
      }
    });
  }

  postInfo(fromTempale?) {
    const data = this.dataForm.value;
    if (!this.expensesValue) {
      data.matterEstimateExpenses = [];
    } else {
      for (const matterEstimateExpense of data.matterEstimateExpenses) {
        matterEstimateExpense.amount += '';
      }
    }
    if (!this.servicesValue) {
      data.matterEstimateTimeEntries = [];
    } else {
      let all = 0;
      for (const matterEstimateTimeEntry of data.matterEstimateTimeEntries) {
        matterEstimateTimeEntry.rate += '';
        const duration = matterEstimateTimeEntry.duration;
        if (duration && duration.includes(':')) {
          all = duration.split(':');
          matterEstimateTimeEntry.duration = all[0] * 3600 + all[1] * 60;
        } else {
          all = matterEstimateTimeEntry.duration;
          matterEstimateTimeEntry.duration = Math.floor(all * 3600);
        }
      }
    }
    this.openSpinner();
    this.matterModalService.postInfo(this.dataForm.value).subscribe(() => {
      this.checkClickSubmit = false;
      if (fromTempale) {
        this.addDocumentFromTemplate(data);
      } else {
        this.dialogRef.close();
        this.toastrService.success(this.translate.instant('itemAdded'));
      }
      this.hideSpinner();
      return this.dataForm.value;
    });
    for (const matterEstimateTimeEntry of data.matterEstimateTimeEntries) {
      matterEstimateTimeEntry.duration = this.timeFormatter.convertSecondsToTableString(matterEstimateTimeEntry.duration);
    }
  }

  updateInfo(fromTempale?) {
    const data = this.dataForm.value;
    if (!this.expensesValue) {
      data.matterEstimateExpenses = [];
    } else {
      for (const matterEstimateExpense of data.matterEstimateExpenses) {
        matterEstimateExpense.amount += '';
      }
    }
    if (!this.servicesValue) {
      data.matterEstimateTimeEntries = [];
    } else {
      let all = 0;
      for (const matterEstimateTimeEntry of data.matterEstimateTimeEntries) {
        matterEstimateTimeEntry.rate += '';
        const duration = matterEstimateTimeEntry.duration;
        if (duration && duration.includes(':')) {
          all = duration.split(':');
          matterEstimateTimeEntry.duration = all[0] * 3600 + all[1] * 60;
        } else {
          all = matterEstimateTimeEntry.duration;
          matterEstimateTimeEntry.duration = Math.floor(all * 3600);
        }
      }
    }
    this.openSpinner();
    this.matterModalService.updateInfo(this.dataForm.value).subscribe(i => {
      this.checkClickSubmit = false;
      if (fromTempale) {
        this.addDocumentFromTemplate(data);
      } else {
        this.dialogRef.close();
        this.toastrService.success(this.translate.instant('itemEdited'));
      }
      this.hideSpinner();
      return i;
    });
    for (const matterEstimateTimeEntry of data.matterEstimateTimeEntries) {
      matterEstimateTimeEntry.duration = this.timeFormatter.convertSecondsToTableString(matterEstimateTimeEntry.duration);
    }
  }

  deleteAll() {
    const data = this.dataForm.value;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'default-mat-dialog';
    dialogConfig.data = {
      yesButtonTxt: 'deleteYesButtonTxt',
      noButtonTxt: 'deleteNoButtonTxt',
      title: 'deleteTitleTxt',
      mainTxt: 'deleteTxt'
    };
    if (this.dataForm.value.id) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // this.matterModalService.deleteInfo(this.dataForm.value.id).subscribe(i => {
          //   return i;
          // });
          data.matterEstimateTimeEntries = [];
          data.matterEstimateExpenses = [];
          this.dataForm.value.withoutTimeEntry = false;
          this.dataForm.value.withoutExpense = false;
          this.openSpinner();
          this.matterModalService.updateInfo(this.dataForm.value).subscribe(i => {
            this.toastrService.success(this.translate.instant('itemDeleted'));
            this.dialogRef.close();
            this.hideSpinner();
            return i;
          });
        } else {
          this.hideSpinner();
          dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  addDocumentFromTemplate(data?) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ['default-mat-dialog'];
    dialogConfig.disableClose = true;
    dialogConfig.data = { type: 'matter', matterId: this.data.id };

    const dialogRef = this.dialog.open(DocumentFromTemplateUploadModalComponent, dialogConfig);
    const subscr = dialogRef.afterClosed().subscribe(x => {
      if (x) {
        this.fileName = `${x.name}.${x.type}`;

        this.templateService.getDocumentFromTemplate(x).subscribe(file => {
          this.downloadFile(file, x.type);
        });
      }
    });
    this.subscriptions.add(subscr);

    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  downloadFile(data, type) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
    const fileDownload: HTMLElement = document.getElementById('fileDownload') as HTMLElement;
    const interval = setInterval(() => {
      fileDownload.click();
      clearInterval(interval);
    }, 500);
  }

  getExpensesTypesData(data) {
    return data.data;
  }

  getDataFromStore() {
    this.store.select(selectExpenseTypes).subscribe(data => {
      if (!data) {
        this.store.dispatch(new LoadExpenseTypesAction());
      } else if (data) {
        this.expenseTypes = this.getExpensesTypesData(data);
      }
    });
  }

  // функція для витягу даних з бд
  getData(data) {
    for (const matterEstimateTimeEntry of data[0].matterEstimateTimeEntries) {
      this.matterEstimateTimeEntries.push(
        this.formBuilder.group({
          description: this.formBuilder.control(matterEstimateTimeEntry.description),
          duration: this.formBuilder.control(this.timeFormatter.convertSecondsToTableString(matterEstimateTimeEntry.duration), [
            Validators.required
          ]),
          rate: this.formBuilder.control(matterEstimateTimeEntry.rate, [Validators.required, Validators.min(0)]),
          id: this.formBuilder.control(matterEstimateTimeEntry.id),
          task: this.formBuilder.control(matterEstimateTimeEntry.task ? matterEstimateTimeEntry.task : null)
        })
      );
    }
    for (const matterEstimateExpense of data[0].matterEstimateExpenses) {
      this.matterEstimateExpenses.push(
        this.formBuilder.group({
          description: this.formBuilder.control(matterEstimateExpense.description),
          expenseType: this.formBuilder.control(matterEstimateExpense.expenseType ? matterEstimateExpense.expenseType : null),
          amount: this.formBuilder.control(matterEstimateExpense.amount, [Validators.required, Validators.min(0)]),
          id: this.formBuilder.control(matterEstimateExpense.id)
        })
      );
    }
  }

  takeOtherData(data) {
    this.dataForm.patchValue({
      matter: data[0].matter,
      id: data[0].id,
      withoutTimeEntry: data[0].withoutTimeEntry,
      withoutExpense: data[0].withoutExpense
    });
  }

  createForm() {
    this.dataForm = this.formBuilder.group({
      matter: this.formBuilder.control(this.data),
      withoutTimeEntry: this.formBuilder.control(false),
      withoutExpense: this.formBuilder.control(false),
      id: this.formBuilder.control(null),

      matterEstimateTimeEntries: this.formBuilder.array([]),
      matterEstimateExpenses: this.formBuilder.array([])
    });
  }

  getWorkPlan(): UntypedFormGroup {
    return this.formBuilder.group({
      description: this.formBuilder.control(''),
      task: this.formBuilder.control(null),
      duration: this.formBuilder.control(null, [Validators.required]),
      rate: this.formBuilder.control(null, [Validators.required, Validators.min(0)])
    });
  }

  getArrayDataWork() {
    return this.dataForm.get('matterEstimateTimeEntries').value;
  }

  getExpensePlan(): UntypedFormGroup {
    return this.formBuilder.group({
      description: this.formBuilder.control(''),
      expenseType: this.formBuilder.control(null),
      amount: this.formBuilder.control(null, [Validators.required, Validators.min(0)])
    });
  }

  getExpenseTypeName(i) {
    let name;
    if (this.expenseTypes) {
      this.expenseTypes.forEach(el => {
        if (typeof this.getArrayDataExpense()[i].expenseType === 'number' && +this.getArrayDataExpense()[i].expenseType === +el.id) {
          name = el.name;
        } else if (
          typeof this.getArrayDataExpense()[i].expenseType === 'object' &&
          this.getArrayDataExpense()[i].expenseType !== null &&
          +this.getArrayDataExpense()[i].expenseType.id === +el.id
        ) {
          name = el.name;
        }
      });
    }
    return name;
  }

  getArrayDataExpense() {
    return this.dataForm.get('matterEstimateExpenses').value;
  }

  createNewTask(i) {
    const dialogConfig = new MatDialogConfig();
    if (this.data) {
      dialogConfig.data = { matter: this.data, matterPlanData: this.matterEstimatesControls[i].value, type: 'task' };
    }
    dialogConfig.panelClass = ['default-mat-dialog', 'tasks-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    let resultNewTask = false;
    this.taskEffects.addTask$.pipe(take(1)).subscribe((res: any) => {
      console.log("res", res);
      let task = res.payload;
      if (task && resultNewTask) {
        this.matterEstimatesControls[i].get('task').setValue(task);
      }
    })
    const dialogClosdedSubscription = dialogRef.afterClosed().subscribe(result => {

      if (result) {
        resultNewTask = true;
        // this.store
        //   .select(selectCurrentTask)
        //   .pipe(first(val => val !== null))
        //   .subscribe(task => {
        //     console.log("task", task);

        //     if (task) {
        //       this.matterEstimatesControls[i].get('task').setValue(task);
        //     }
        //   });
      }
    });
    this.subscriptions.add(dialogClosdedSubscription);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  goToTask(task) {
    this.dialogRef.close();
    this.router.navigate([`/tasks/card/${task.id}`]);
  }

  createNewEvent(i) {
    const dialogConfig = new MatDialogConfig();
    if (this.data) {
      dialogConfig.data = { matter: this.data, matterPlanData: this.matterEstimatesControls[i].value, type: 'event' };
    }
    dialogConfig.panelClass = ['default-mat-dialog', 'events-modal'];
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);
    const dialogClosdedSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store
          .select(selectCurrentTask)
          .pipe(first(val => val !== null))
          .subscribe(task => {
            if (task) {
              this.matterEstimatesControls[i].get('task').setValue(task);
            }
          });
      }
    });
    this.subscriptions.add(dialogClosdedSubscription);
    dialogRef.backdropClick().subscribe(() => {
      this.confirmCloseModal(dialogRef);
    });
  }

  getFieldType(type) {
    switch (type) {
      case 'work': {
        return this.matterEstimateTimeEntries;
      }
      case 'expense': {
        return this.matterEstimateExpenses;
      }
    }
  }

  // перевіряє чи можна довати ще одине поле в plan work
  checkValidWork() {
    for (const matterEstimateTimeEntry of this.dataForm.get('matterEstimateTimeEntries').value) {
      if (matterEstimateTimeEntry.duration === null || matterEstimateTimeEntry.rate === null) {
        return false;
      }
    }
    return true;
  }
  // перевіряє чи можна довати ще одине поле в plan expense
  checkValidExpense() {
    for (const matterEstimateExpense of this.dataForm.get('matterEstimateExpenses').value) {
      if (matterEstimateExpense.amount === null) {
        return false;
      }
    }
    return true;
  }

  addWorkField() {
    if (this.checkValidWork()) {
      this.matterEstimateTimeEntries.push(this.getWorkPlan());
      this.editableWorkIndex = this.matterEstimateTimeEntries.controls.length - 1;
    }
    if (this.matterEstimateTimeEntries.status === 'INVALID') {
      this.matterEstimateTimeEntries.clearValidators();
      return;
    }
  }

  addExpenseField() {
    if (this.checkValidExpense()) {
      this.matterEstimateExpenses.push(this.getExpensePlan());
      this.editableExpenseIndex = this.matterEstimateExpenses.controls.length - 1;
    }
  }

  editWorkField(index) {
    const data = this.dataForm.value;

    if (this.editableWorkIndex === -1) {
      this.editableWorkIndex = this.editableWorkIndex === index ? -1 : index;
      return;
    } else if (
      data.matterEstimateTimeEntries[this.editableWorkIndex].rate == null &&
      data.matterEstimateTimeEntries[this.editableWorkIndex].task
    ) {
      this.submittedForm = true;
      return;
    } else if (data.matterEstimateTimeEntries[this.editableWorkIndex].rate == null) {
      // this.dataForm.value.matterEstimateTimeEntries;
      this.editableWorkIndex = this.editableWorkIndex === index ? -1 : index;
      this.removeField(this.dataForm.value.matterEstimateTimeEntries.length - 1, 'work');
      // return;
    }
    this.editableWorkIndex = this.editableWorkIndex === index ? -1 : index;
  }

  editExpenseField(index) {
    const data = this.dataForm.value;

    if (this.editableExpenseIndex === -1) {
      this.editableExpenseIndex = this.editableExpenseIndex === index ? -1 : index;
      return;
    } else if (data.matterEstimateExpenses[this.editableExpenseIndex].amount == null) {
      return;
    }
    this.editableExpenseIndex = this.editableExpenseIndex === index ? -1 : index;
  }

  removeField(index, fieldName): void {
    if (this.getFieldType(fieldName).value.length > 0) {
      this.getFieldType(fieldName).removeAt(index);
      if (fieldName === 'expense') {
        this.editableExpenseIndex -= 1;
      } else if (fieldName === 'work') {
        this.editableWorkIndex -= 1;
      }
    }
  }

  totalWorkCalculation() {
    let result = 0;

    for (const matterEstimateTimeEntry of this.dataForm.get('matterEstimateTimeEntries').value) {
      const duration = matterEstimateTimeEntry.duration + '';
      result += (this.formatTimeToSecond(duration) * matterEstimateTimeEntry.rate) / 3600;
    }
    return result;
  }

  formatTimeToSecond(time) {
    let all = 0;
    if (time && time.includes(':')) {
      all = time.split(':');
      return all[0] * 3600 + all[1] * 60;
    } else {
      all = time;
      return Math.floor(all * 3600);
    }
  }

  totalExpenseCalculation() {
    let result = 0;
    for (const matterEstimateExpense of this.dataForm.get('matterEstimateExpenses').value) {
      result += +matterEstimateExpense.amount;
    }
    return result;
  }

  checkSubmit() {
    this.submittedForm = true;
  }
  onSubmit(fromTempale?) {
    this.checkSubmit();
    if (this.matterEstimateTimeEntries.status === 'INVALID') {
      this.matterEstimateTimeEntries.updateValueAndValidity();
      return false;
    }
    if (this.matterEstimateExpenses.status === 'INVALID') {
      this.matterEstimateExpenses.updateValueAndValidity();
      this.matterEstimateExpenses.clearValidators();
      return false;
    }
    if (this.isEmpty) {
      if (this.dataForm.value.matterEstimateTimeEntries.length === 0) {
        this.dataForm.value.withoutTimeEntry = false;
      }
      if (this.dataForm.value.matterEstimateExpenses.length === 0) {
        this.dataForm.value.withoutExpense = false;
      }
      if (this.dataForm.valid && !this.checkClickSubmit) {
        this.checkClickSubmit = true;
        this.updateInfo(fromTempale);
      }
    } else {
      if (this.dataForm.valid && !this.checkClickSubmit) {
        this.checkClickSubmit = true;
        this.postInfo(fromTempale);
      }
    }
    // if (!fromTempale) {
    //   this.dialogRef.close();
    // }
  }

  servicesSwitch(event) {
    if (event.target.checked === false) {
      this.dataForm.value.matterEstimateTimeEntries = [];
    }
    this.servicesValue = event.target.checked;
  }
  expensesSwitch(event) {
    if (event.target.checked === false) {
      this.dataForm.value.matterEstimateExpenses = [];
    }
    this.expensesValue = event.target.checked;
  }

  public closeModal() {
    this.dialogRef.close();
  }

  confirmCloseModal(modalRef) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.panelClass = 'default-mat-dialog';
    confirmDialogConfig.data = {
      yesButtonTxt: this.translate.instant(('button.close')),
      noButtonTxt: this.translate.instant('button.cancel'),
      title: this.translate.instant('titleConfirmModal'),
      mainTxt: this.translate.instant('mainTxtConfirmModal')
    };
    const confirmDialogRef = this.dialog.open(ConfirmModalComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        modalRef.close();
        this.visibleTaskModal = false;
        confirmDialogRef.close();
      } else {
        confirmDialogRef.close();
      }
    });
  }

  fixDuration(event) {
    setTimeout(() => {
      let temp = event.target.value;
      const currentValue = temp.split(':');
      const countAfter = currentValue[currentValue.length - 1].length;
      if (temp.includes(':') && countAfter === 1) {
        if (currentValue[1] > 5) {
          currentValue[1] = 5;
          temp = currentValue[0] + ':' + currentValue[1];
        }
      }
      if (temp.includes(':') && countAfter > 2) {
        if (currentValue[1][0] > 5) {
          temp = currentValue[0] + ':' + '5' + currentValue[1][1];
        } else {
          temp = currentValue[0] + ':' + currentValue[1][0] + currentValue[1][1];
        }
      }
      this.matterEstimateTimeEntries.controls[event.target.id].get('duration').setValue(temp);
    }, 200);

    if ((event.which >= 48 && event.which <= 57) || event.which === 58) {
      return true;
    }
    return false;
  }

  private openSpinner() {
    this.showLoader = true;
  }

  private hideSpinner() {
    this.showLoader = false;
  }
  editTask(i) {
    const task = this.matterEstimatesControls[i].get('task').value;

    this.store.dispatch(new GetTask(task.id));
    const getTaskForUpdateSubscr = this.taskEffects.getTask$.pipe(take(1)).subscribe(resp => {
      if (typeof resp !== 'boolean' && !this.visibleTaskModal) {
        const result = resp as GetTaskSuccess;
        this.visibleTaskModal = true;

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = { task: result.payload, type: result.payload.type };
        dialogConfig.panelClass = ['default-mat-dialog', 'tasks-modal'];
        dialogConfig.disableClose = true;
        let dialogRef;
        dialogRef = this.dialog.open(TasksEventsModalComponent, dialogConfig);

        const dialogClosdedSubscription = dialogRef.afterClosed().subscribe(res => {
          if (res && res.close) {
            this.matterEstimatesControls[i].get('task').setValue(res.task);
          }
          this.visibleTaskModal = false;
          this.store.dispatch(new GetDocumentsByTaskSuccess([]));
        });
        this.subscriptions.add(dialogClosdedSubscription);
        dialogRef.backdropClick().subscribe(() => {
          this.confirmCloseModal(dialogRef);
        });
      }
    });
    this.subscriptions.add(getTaskForUpdateSubscr);
  }

  getColorTask(item) {
    if (item.status === 'done') {
      return '#148D34';
    }

    if (new Date() > new Date(item.endDate) && item.status !== 'done') {
      if (item.type === 'event') {
        return '#E03B4B';
      }
      if (item.type === 'task') {
        if (new Date().getDay() > new Date(item.endDate).getDay()) {
          return '#E03B4B';
        } else {
          return '#1252AF';
        }
      }
    } else {
      return '#1252AF';
    }
  }
  public filterExpenseTypes: EmitType<any> = (e: FilteringEventArgs) => {
    if (e.text === '') {
      e.updateData(this.expenseTypes as any);
    } else {
      const filteringData = [];
      this.expenseTypes.forEach(i => {
        if (i.name.toUpperCase().indexOf(e.text.toUpperCase()) !== -1) {
          filteringData.push(i);
        }
      });
      e.updateData(filteringData);
    }
  }
}
