<div class="main" [class.onboard]="onboardOpened">
  <app-chat-overlay></app-chat-overlay>
  <div class="wrapper" (keypress)="saveAndCheckActiveUser($event)" (click)="saveAndCheckActiveUser($event)">
    <div class="sidebar-block">
      <app-sidebar *ngIf="showSidebar"></app-sidebar>
    </div>
    <div class="content-block">
      <div class="onboarding" *ngIf="onboardOpened">
        <span>
          {{ 'header.onboarding.welcomeToJusnote' | translate }}
          <a [routerLink]="['settings/account-settings']">{{ 'header.onboarding.setupCompany' | translate }}</a>
          {{ 'header.onboarding.or' | translate }}
          <a [routerLink]="['team/activated']"
             [queryParams]="{invite: '1'}">{{ 'header.onboarding.inviteUser' | translate }}</a>.
        </span>
        <app-subscription-left></app-subscription-left>
      </div>
      <app-header *ngIf="showHeader"></app-header>
      <div class="app-routing-container" [@fadeAnimation]="prepareRoute(o)">
        <router-outlet #o="outlet"></router-outlet>
      </div>
      <!-- <app-footer *ngIf="showFooter"></app-footer> -->
    </div>
  </div>
</div>
