<div class="modal-template">
  <div class="modal-container">
    <div class="modal-header-template">
      <div class="title-text">
        {{ 'settings.document-templates.modalSaveTitle' | translate }}
      </div>
      <div class="close-modal" (click)="closeModal()">
        <span class="j-icon-cross"></span>
      </div>
    </div>
    <div class="modal-body-template">
      <form (ngSubmit)="onSubmit()" [formGroup]="templateForm">
        <div class="input-container" [ngClass]="{ 'invalid-validation': f.get(['name']).errors !== null && submittedForm }">
          <label>{{ 'table.name' | translate }}<span class="required-validation">*</span></label>
          <input formControlName="name" type="text" placeholder="{{ 'common.typeHere' | translate }}" />
        </div>
        <div class="input-container" [ngClass]="{ 'invalid-validation': f.get(['type']).errors !== null && submittedForm }">
          <label>{{ 'table.category' | translate }}<span class="required-validation">*</span></label>
          <!-- <input type="text" placeholder="{{ 'common.select' | translate }}" /> -->
          <ejs-dropdownlist
            [locale]="locale$ | async"
            [allowFiltering]="false"
            [dataSource]="templateTypes"
            formControlName="type"
            [fields]="{ text: 'name', value: 'value' }"
            [placeholder]="'common.select' | translate"
            [noRecordsTemplate]="'noRecord' | translate"
            [showClearButton]="false"
          >
          </ejs-dropdownlist>
        </div>
        <div class="button-container">
          <button class="btn-template btn-blue dropdown-button" >{{ 'button.save' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
