/* src/app/commonComponents/modals/default-modals/reports-custom-modal/reports-custom-modal.component.scss */
.content-container .text-container {
  margin-bottom: 66px;
  padding-right: 70px;
}
.content-container .text-container h6 {
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.content-container .text-container p {
  color: #637283;
  font-size: 13px;
  margin: 0;
}
.content-container .support-team-contacts {
  border: none;
  padding: 0;
  margin-bottom: 39px;
}
.content-container .support-team-contacts .contact-info {
  margin-bottom: 0;
}
.modal-template .modal-footer .button-container {
  justify-content: space-between;
}
.modal-template .modal-footer .button-container p {
  color: #637283;
  font-size: 13px;
  margin: 0;
}
.modal-template .modal-footer .button-container p a {
  text-decoration: underline;
}
textarea {
  height: 165px;
}
/*# sourceMappingURL=reports-custom-modal.component.css.map */
