<div class="modal-video-template">
  <div class="close-modal" (click)="closeModal()">
    <span class="j-icon-cross"></span>
  </div>
  <div class="video-container">
    <iframe
      [src]="data | sanitizeHtml: 'resourceUrl'"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>
