import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExpenseType, ExpenseTypeEnum } from '@app/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
    selector: 'app-library-checkbox-create-modal',
    templateUrl: './library-checkbox-create-modal.component.html',
    styleUrls: ['./library-checkbox-create-modal.component.scss'],
    standalone: false
})
export class LibraryCheckboxCreateModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LibraryCheckboxCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExpenseType,
  ) {
  }

  get f() {
    return this.libraryForm;
  }

  item: ExpenseType = {
    name: null,
    type: null,
  };

  libraryForm = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    clientExpense: new FormControl(),
    internalExpense: new FormControl(),
  });


  submittedForm = false;

  ngOnInit() {
    if (this.data) {
      this.item = this.data;
      this.initFormValues(this.item);
    }
  }

  public closeModal() {
    this.dialogRef.close();
  }


  onLibraryCreate() {
    this.submittedForm = true;
    this.libraryForm.updateValueAndValidity();
    this.setErrorsCheckbox();
    if (this.libraryForm.invalid) {
      return;
    }

    const type: ExpenseTypeEnum = this.libraryForm.value.clientExpense && this.libraryForm.value.internalExpense ?
      ExpenseTypeEnum.COMMON : this.libraryForm.value.clientExpense ?
        ExpenseTypeEnum.EXTERNAL : ExpenseTypeEnum.INTERNAL;


    this.item.name = this.libraryForm.controls.name.value;
    this.item.type = type;
    this.dialogRef.close(this.item);
  }


  private initFormValues(data: ExpenseType): void {
    this.libraryForm.setValue({
      name: data.name,
      clientExpense: data.type === 'common' || data.type === 'external',
      internalExpense: data.type === 'common' || data.type === 'internal',
    });
  }

  public setErrorsCheckbox() {
    if (!this.libraryForm.value.clientExpense && !this.libraryForm.value.internalExpense) {
      this.libraryForm.controls.internalExpense.setErrors({ selected: true });
      this.libraryForm.controls.clientExpense.setErrors({ selected: true });
    } else {
      this.libraryForm.controls.internalExpense.setErrors(null);
      this.libraryForm.controls.clientExpense.setErrors(null);
    }
  }

}
