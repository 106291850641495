/* src/app/commonComponents/modals/other-modals/transaction-modal/transaction-modal.component.scss */
.modal-transaction-template .modal-container {
  width: 540px;
  height: 100%;
  padding: 12px 18px 21px;
}
.modal-transaction-template .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.modal-transaction-template .modal-title h3 {
  color: #1252af;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.modal-transaction-template .modal-title .close-modal {
  cursor: pointer;
}
.modal-transaction-template .modal-title .close-modal span {
  font-size: 16px;
  color: #000;
}
.modal-transaction-template .modal-body {
  position: relative;
  border: 1px dashed #b1d1ff;
  background-color: #f7faff;
  padding: 21px 13px 33px;
}
.modal-transaction-template .modal-body .icon-container {
  position: absolute;
  left: calc(50% - 25px);
  top: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background-color: #f7faff;
  border: 1px dashed #b1d1ff;
  border-radius: 50%;
  border-bottom-color: #f7faff;
  border-right-color: #f7faff;
  transform: rotate(45deg);
}
.modal-transaction-template .modal-body .icon-container i {
  font-size: 38px;
  color: #b1d1ff;
  transform: rotate(-45deg);
}
.modal-transaction-template .modal-body h4 {
  color: #1252af;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin: 0px 0 18px;
}
.modal-transaction-template .modal-body .transaction-content {
  display: flex;
}
.modal-transaction-template .modal-body .transaction-content .transaction-input-col {
  width: 110px;
}
.modal-transaction-template .modal-body .transaction-content .transaction-col {
  display: flex;
  width: calc((100% - 110px) / 2);
}
.modal-transaction-template .modal-body .transaction-content .transaction-col:nth-child(1) {
  justify-content: flex-end;
}
.modal-transaction-template .modal-body .transaction-content .transaction-col:nth-child(2) {
  justify-content: flex-start;
}
.modal-transaction-template .modal-body .transaction-content .transaction-col .number label {
  display: block;
  color: #222222;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
}
.modal-transaction-template .modal-body .transaction-content .transaction-col .number p {
  margin: 0;
}
.modal-transaction-template .modal-body .transaction-content .transaction-col .sign-item {
  margin: 30px 20px 0;
}
.modal-transaction-template .modal-body hr {
  border: none;
  border-bottom: 1px solid #b1d1ff;
  margin: 29px auto 13px;
}
.modal-transaction-template .modal-body .sum-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 33px;
}
.modal-transaction-template .modal-body .sum-container .sum-item {
  max-width: 218px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.modal-transaction-template .modal-body .sum-container .sum-item p {
  margin: 0;
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 600;
}
.modal-transaction-template .input-container.date {
  margin-top: 13px;
}
.modal-transaction-template .button-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.modal-transaction-template .button-container p {
  color: #637283;
  font-size: 13px;
  margin: 0 0 16px;
}
.modal-transaction-template .button-container p a {
  text-decoration: underline;
  color: #1252af;
}
.modal-transaction-template .button-container button.btn-template {
  width: 160px;
  margin: 0;
}
/*# sourceMappingURL=transaction-modal.component.css.map */
